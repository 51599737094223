import { environment } from '@env/environment';

export class GuidedTourConstants {
    public static OVERALL_TOUR = 'overallTour';
    public static OVERALL_TOUR_V2 = 'overallTourv2';
    public static REPORTS_TOUR = 'reportsTour';
    public static TOOLS_TOUR = 'toolsTour';
    public static TOOLS_TOUR_V2 = 'toolsTourv2';
    public static GUIDED_TOUR = 'guidedTour';
    public static GUIDED_TOUR_DATA = ['title', 'description', 'imageCTA', 'videoCTA', 'pdfCTA'];
    public static IS_VIDEO_AVAILABALE = {
        overallTour: [false, false, false, false, true],
        overallTourv2: [false, false, false, false, true],
        reportsTour: [false, false, true, false],
        toolsTour: [false, true, true, true, false],
        toolsTourv2:[false,false,true, false,false]
    };
    public static TOUR_STEPS = {
        overallTour: ['Step1', 'Step2', 'Step3', 'Step4', 'Step5'],
        reportsTour: ['Step1', 'Step2', 'Step3', 'Step4', 'Step5'],
        toolsTour: ['Step1', 'Step2', 'Step3', 'Step4', 'Step5'],
        toolsTourv2: ['Step1', 'Step2', 'Step3', 'Step4', 'Step5']
    };
    public static START_ELEMENT_IDS = {
        overallTour: ['', 'dashboardpopup', 'reportspopup', 'toolspopup', 'helppopup'],
        reportsTour: ['', 'volumepopup', 'focusgrouppopup', 'downlinedashboardpopup', 'fourcoredashboardpopup'],
        toolsTour: ['', 'calltoactionpopup', 'landingpagepopup', 'mediasocialpopup', 'sociallinkspopup'],
        toolsTourv2:['trainingtoolspopup','shareablecontentpopup','favouritespopup','socialpostscheduler','contactspopup']
    };
    public static END_ELEMENT_IDS = {
        overallTour: [[], ['manageitemsarrow'], ['reportsarrow'], ['toolsarrow'], ['helparrow']],
        overallTourv2: [[], ['manageitemsarrow'], ['reportsarrow'], ['toolsarrow','trainingtoolsarrow'], ['helparrow']],
        reportsTour: [[], ['volume'],['focusGroup'], ['downline'], ['retention']],
        toolsTour: [[], ['call-to-action'], ['contacts'], ['medialibrary'], ['socialqueue']],
        toolsTourv2: [['trainingtoolsarrow'],['shareablecontentarrow'],['linkbuilder'], ['socialqueue'], ['contacts'] ],
    };
    public static HIGHLIGHT_SECTION_IDS = {
        retention: ['retention', 'recruitments', 'productivity', 'development','four-core-summary','contentHeader'],
        medialibrary:['contentstream','medialibrary','socialpages'],
        shareablecontentarrow:['shareablecontentarrow','contentstream','medialibrary','socialpages'],
        trainingtoolsarrow:['trainingtoolsarrow','businesstraining','producttraining']
        
    };
    public static MODAL_WIDTH = {
        helppopup: 673,
        downlinedashboardpopup: 673,
        calltoactionpopup: 673,
        mediasocialpopup: 673,
        landingpagepopup: 673
    };
    public static ARROWCONNECTOR_MIN_SCREEN_WIDTH = 1080;
    public static GUIDEDTOUR_MIN_SCREEN_WIDTH = 810;
    public static SCROLL_TO ={
        overallTour: 0,
        reportsTour: 30,
        toolsTour: 50
    }
}
export class StartElementIds {
    public static DASHBOARD_POPUP = 'dashboardpopup';
    public static REPORTS_POPUP = 'reportspopup';
    public static TOOLS_POPUP = 'toolspopup';
    public static HELP_POPUP = 'helppopup';
    public static CALL_TO_ACTION_POPUP = 'calltoactionpopup';
    public static VOLUME_POPUP = 'volumepopup';
    public static FOCUSGROUP_POPUP = 'focusgrouppopup';
    public static CORE_DASHBOARD_POPUP = 'fourcoredashboardpopup';
    public static DOWNLINE_DASHBOARD_POPUP = 'downlinedashboardpopup';
    public static RETENTION = 'retention';
    public static MEDIA_LIBRARY = 'medialibrary';
    public static SHAREABLE_CONTENT='shareablecontentarrow';
    public static MEDIA_SOCIAL_POPUP = 'mediasocialpopup';
    public static SOCIAL_LINKS_POPUP = 'sociallinkspopup';
    public static LANDING_PAGE_POPUP = 'landingpagepopup';
    public static TRAINING_TOOLS='trainingtoolsarrow';
}
