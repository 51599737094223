<div>
    <div class="col text-center header-logo-section p-3 border-bottom-1">
        <a class="" title="Home">
            <img src="{{cdnURL}}/{{imagePath}}/{{desktopLogo}}" height="65" alt="foreverliving" />
        </a>
    </div>
    <div class="body pt-5 pb-5">
        <div class="row">
            <div class="d-sm-none d-md-block col-md-3">
            </div>
            <div class="col-12 col-md-6">
                <div class="">
                    <div *ngIf="expiryCodeAvailable" class="alert alert-danger text-center">
                        <strong>{{'common.We are unable to validate your E-Mail address. Please contact Customer Support at' | translate }}
                            &nbsp;<a href="mailto:{{customerCareEmailId}}">{{customerCareEmailId}}</a></strong>
                    </div>
                    <div *ngIf="!expiryCodeAvailable" class="text-center">
                        <div *ngIf="isSuccess && validationMessage" class="alert alert-success alert-white rounded alertbox">
                            <strong>
                                <span class="icon">
                                    <i class="fa fa-check"></i>
                                </span>
                            </strong>&nbsp;&nbsp;&nbsp;&nbsp;{{validationMessage | translate}}
                                 <a (click)="redirectToLogin()" class="login"> {{'common.Click here' | translate }} </a> {{'common.to login' | translate}}.
                        </div>
                        <div *ngIf="!isSuccess && validationMessage" class="alert alert-danger text-center">
                            <strong>
                                <span class="icon">
                                    <i class="fa fa-close"></i>
                                </span>&nbsp;&nbsp;&nbsp;&nbsp;{{validationMessage | translate}}
                                 &nbsp;<a href="mailto:{{customerCareEmailId}}">{{customerCareEmailId}}</a></strong>
                        </div>
                    </div>


                </div>

            </div>
            <div class="d-sm-none d-md-block col-md-3">

            </div>
        </div>
    </div>
    <footer (click)="toggleSideNav()" [class.slide-component]="toggleStatus" class="slide-transition slide-component">
        <div class="seperator"></div>
        <div class="container">
            <div class="row footer align-items-end py-3">
                <div class="col-lg-4 col-md-4 col-sm-4  d-flex justify-content-start text-align-center">
                    <a aria-label="Forever Living" href="{{corporateURL}}">{{'footer.Forever Living.com'|translate}}</a>
                    <a aria-label="Learn more" target="_blank"
                        href="{{privacyLink}}">{{'footer.Privacy Policy'|translate}}</a>
                </div>
                <div class="footer-image col-lg-4 col-md-3 col-sm-4 py-2">
                    <img [imgSrc]=footerImage alt="forever_logo" width="100%" height="100%">
                </div>
                <div class="footer-copyright col-lg-4 col-md-5 col-sm-4 d-flex justify-content-center">
                    {{'footer.Copyright'|translate}} {{currentYear}} {{'footer.Forever Living.com, L.L.C. All rights reserved'|translate}}
                </div>
            </div>
        </div>
    </footer>
</div>