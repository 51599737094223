import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateKey } from '@core/pipes/translate-key.pipe';
import { SpinnerService } from '@core/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { SupportTicketConstants } from '../constants/support-ticket.constants';
import { MemberLevel, MemberType } from '../enums/member-type.enum';
import { CountryResponse } from '../interface/country-response.interface';
import { FileDetails, TicketDetailsResponse, TicketType } from '../interface/support-ticket-base-response';
import { SupportTicketRequest } from '../interface/support-ticket.interface';
import { UserModel } from '../interface/user-modal.interface';
import { SupportTicketService } from '../services/support-ticket.service';
import { EmailValidator, FboIdValidator } from '../utils/utils.validator';
import { LocalStorageService } from 'angular-2-local-storage';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { SsoService } from '@core/services/sso.service';
import { MessageService } from '@core/services/message-service.service';
import { environment } from '@env/environment';
import { MaskDistributorIdPipe } from '@core/pipes/mask-distributor-id.pipe';
import { RowStatus, TicketCreation } from 'app/layouts/constants/tooltip-training.constants';
import { ApiUrlConstants } from '@core/constants/api.constants';
import { HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-support-ticket-form',
    templateUrl: '../templates/template1/views/support-ticket-form.component.html',
    styleUrls: ['../templates/template1/themes/support-ticket-form.component.less']
})
export class SupportTicketFormComponent implements OnInit {
    supportRequestForm: FormGroup;
    uploader: FileUploader;
    userDetails: UserModel;
    sourceType: TicketDetailsResponse[];
    ticketPriorities: TicketDetailsResponse[];
    ticketStatus: TicketDetailsResponse[];
    ticketType: TicketType[];
    departments: TicketDetailsResponse[];
    count = 0;
    uploadResponse: FileDetails[] = [];
    ticketResponse: SupportTicketRequest;
    countriesList: CountryResponse[];
    operatingCountryId: number;
    attachmentsLength: number;
    fileUploadErrorMessages: string[];
    maxAllowableUpload: number;
    guestToken: string;
    globalErrorMsg: string;
    fboNotFound: boolean;
    fboName: string;
    email: string;
    distributorId: string;
    isNameFieldDisabled: boolean;
    isFboIdFieldDisabled: boolean;
    @Input() fromHelp: boolean;
    customerCareEmailId = environment.customerCareEmailId;
    enableCSPV2TicketCreation: boolean = environment && environment.enableCSPV2TicketCreation;
    operatingCountryName: string;
    subTypes: any;
    categoryId: number;
    constructor(private formBuilder: FormBuilder,
        private supportTicketService: SupportTicketService,
        private localStorageService: LocalStorageService,
        private ssoService: SsoService,
        private translatePipe: TranslateService,
        private spinnerService: SpinnerService,
        private translateKey: TranslateKey,
        private router: Router,
        private messageService: MessageService,
        private maskDistributorId: MaskDistributorIdPipe) {
    }

    ngOnInit() {
        this.buildSupportRequestForm();
        if (this.fromHelp) {
            this.getLoggedInUserData();
        }
        this.uploader = this.supportTicketService.getFileUploader();
        this.uploader.onErrorItem =
            (item: any, response: any, status: any, headers: any) => this.onErrorItem(item, response, status, headers);
        this.uploader.onSuccessItem =
            (item: any, response: any, status: any, headers: any) => this.onSuccessItem(item, response, status, headers);
    }

    /**
     * @description Construct Support Request Form
     * @date 2020-04-30
     * @memberof SupportRequestFormComponent
     */
    buildSupportRequestForm(): void {
        let maskedId = this.maskDistributorId.transform(this.distributorId);
        this.supportRequestForm = this.formBuilder.group({
            name: [this.fboName ? this.fboName : '', [Validators.required]],
            email: [this.email ? this.email : '', [Validators.required, EmailValidator]],
            fboId: [maskedId ? maskedId : '', [Validators.required, FboIdValidator]],
            message: ['', [Validators.required,
            Validators.minLength(SupportTicketConstants.MIN_CHARACTERS),
            Validators.maxLength(SupportTicketConstants.MAX_CHARACTERS)]],
            document: [[]]
        });
        this.maxAllowableUpload = SupportTicketConstants.MAX_UPLOADS;
    }

    /**
     * @description get User details by FBO ID
     * @date 2020-05-06
     * @memberof SupportTicketComponent
     */
    getUserdetailsByFBOId(): void {
        const fboId = !!this.distributorId ? this.distributorId : this.supportRequestForm.controls.fboId.value;
        if (fboId && this.supportRequestForm.controls.fboId.valid) {
            let userInfo  = JSON.parse(this.localStorageService.get(CacheKeyConstants.userInfo));
            let headers = new HttpHeaders();
            if(!userInfo) {
                headers = headers.append('guestTokenRequired','true');
            }
            this.supportTicketService.getUserdetailsByFBOId(fboId,headers).subscribe(userDetails => {
                if (userDetails && userDetails.userProfile
                    && userDetails.userProfile.foreverFBOId && userDetails.userProfile.defaultCountry) {
                    this.userDetails = userDetails;
                    this.fboNotFound = false;
                    if(this.enableCSPV2TicketCreation && environment && environment.cspv2LiveCountries.includes(userDetails.userProfile.defaultCountry)) {
                        this.getEntitiesList();
                        this.getTicketStatusDetails();
                        this.getSourceTypeDetails();
                        this.getTicketCategoryDetails();
                    } else {
                        this.getCountriesList();
                        this.getSourceType();
                        this.getTicketPriorities();
                        this.getTicketStatus();
                    }
                    
                } else {
                    this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
                    this.fboNotFound = true;
                }
            }, (error) => {
                this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
                this.fboNotFound = true;
            });
        }
    }
    /***
       * @description getLoggedInUserData by FBOID
       * @date 2021-08-09
       * @memberof SupportTicketComponent
     **/
    getLoggedInUserData() {
        this.userDetails = JSON.parse(this.localStorageService.get(CacheKeyConstants.userInfo));
        if (this.userDetails === undefined || this.userDetails === null) {
            const redirectedUrl = this.ssoService.getSSOLogoutUrl(
                window.location.origin
            );
            this.ssoService.ssoRedirect(redirectedUrl);
        }
        this.userDetails.distributorId = CacheKeyConstants.getDecodedDataForNumber(this.userDetails.distributorId);
        this.userDetails.email = CacheKeyConstants.getDecodedDataForString(this.userDetails.email);
        if (this.userDetails) { // If titan users
            this.fboName = (this.userDetails.firstName ? this.userDetails.firstName : ' ')
                + ' ' + (this.userDetails.lastName ? this.userDetails.lastName : ' ');
            this.distributorId = this.userDetails.distributorId ? this.userDetails.distributorId : ' ';
            this.email = this.userDetails.email ? this.userDetails.email : '';

        } else { // If non-titan users( user details not saved in local storage)
            this.fboName = ' ';
            this.distributorId = ' ';
        }
        if (this.fboName === ' ') {
            this.isNameFieldDisabled = false;
        } else {
            this.isNameFieldDisabled = true;
        }
        if (this.distributorId === ' ') {
            this.isFboIdFieldDisabled = false;
        } else {
            this.isFboIdFieldDisabled = true;
        }
        this.buildSupportRequestForm();

    }

    /**
     * @description get Ticket Source Type
     * @date 2020-05-05
     * @memberof SupportRequestFormComponent
     */
    getSourceType(): void {
        this.supportTicketService.getSourceType().subscribe(response => {
            this.sourceType = response;
        }, (error) => {
            this.errorMessage();
        });
    }

    /**
     * @description get Ticket Source Type
     * @date 2020-05-05
     * @memberof SupportRequestFormComponent
     */
    getTicketPriorities(): void {
        this.supportTicketService.getTicketPriorities().subscribe(response => {
            this.ticketPriorities = response;
        }, (error) => {
            this.errorMessage();
        });
    }

    /**
     * @description get Ticket Source Type
     * @date 2020-05-05
     * @memberof SupportRequestFormComponent
     */
    getTicketStatus(): void {
        this.supportTicketService.getTicketStatus().subscribe(response => {
            this.ticketStatus = response;
        }, (error) => {
            this.errorMessage();
        });
    }

    /**
     * @description get Ticket type and subtypes
     * @date 2020-05-05
     * @memberof SupportRequestFormComponent
     */
    getTicketTypesAndSubTypes(): void {
        this.supportTicketService.getTicketTypesAndSubTypes(this.operatingCountryId).subscribe(response => {
            this.ticketType = response;
            this.getDepartments();
        }, (error) => {
            this.errorMessage();
        });
    }

    /**
     * @description get departments list
     * @date 2020-05-06
     * @memberof SupportTicketComponent
     */
    getDepartments(): void {
        this.supportTicketService.getDepartments(this.operatingCountryId).subscribe(response => {
            this.departments = response;
            this.submitSupportRequest();
        }, (error) => {
            this.errorMessage();
        });
    }

    /**
     * @description create submit request
     * @date 2020-05-12
     * @memberof SupportTicketFormComponent
     */
    createSupportRequest(): void {
        this.globalErrorMsg = '';
        this.fileUploadErrorMessages = [];
        this.spinnerService.start(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
        this.getUserdetailsByFBOId();
    }

    /**
     * @description create ticket
     * @date 2020-05-07
     * @memberof SupportTicketFormComponent
     */
    submitSupportRequest(): void {
        this.fileUploadErrorMessages = [];
        let supportRequestObj;
        if (this.enableCSPV2TicketCreation && environment && environment.cspv2LiveCountries.includes(this.userDetails.userProfile.defaultCountry)) {
            this.getGuestTokenDetails();
            const type: TicketType = this.ticketType &&
                this.ticketType.filter(x => x.name.toLowerCase() === SupportTicketConstants.EmailToTicket.toLowerCase())[0];
            const subType = this.subTypes &&
                this.subTypes.filter(y => y.name.toLowerCase() === SupportTicketConstants.EmailToTicketReassign.toLowerCase())[0];
            const department: TicketDetailsResponse = this.departments
                && this.departments.filter(dept => dept.name === SupportTicketConstants.SmileTeam360)[0];
            const priority = TicketCreation.PRIORITY_ID;
            const sourceValue = this.sourceType && this.sourceType.filter(x => x.value === SupportTicketConstants.Email)[0].id;
            const ticketStatusId = this.ticketStatus.filter(t => t.name === SupportTicketConstants.New)[0].id;
            if (this.userDetails && this.userDetails.userProfile && this.userDetails.userProfile.foreverFBOId
                && type && subType && department && sourceValue && this.categoryId) {
                setTimeout(() => {
                    supportRequestObj = {
                        distributorId: this.distributorId,
                        distributorName: this.supportRequestForm.controls.name.value,
                        ticketLink: TicketCreation.TICKET_LINK,
                        entityId: this.operatingCountryId,
                        ticketTypeId: type.id,
                        ticketSubTypeId: subType.id,
                        departmentId: department.id,
                        sourceId: sourceValue,
                        priorityId: priority,
                        contactValue: this.supportRequestForm.controls.email.value,
                        reporterName: this.supportRequestForm.controls.name.value,
                        subject: SupportTicketConstants.FLP360FormSubmission,
                        description: this.supportRequestForm.controls.message.value,
                        statusId: ticketStatusId,
                        rowStatusId: RowStatus.ACTIVE,
                        categoryId: this.categoryId,
                        ticketAttachments: this.uploadResponse
                    }
                }, 1000);
            } else {
                this.errorMessage();
            }
        } else {
            const sourceValue = this.sourceType && this.sourceType.filter(x => x.value === SupportTicketConstants.Email)[0].id;
            const type: TicketType = this.ticketType &&
                this.ticketType.filter(x => x.value.toLowerCase() === SupportTicketConstants.EmailToTicket.toLowerCase())[0];
            const subType = type && type.subTypes &&
                type.subTypes.filter(y => y.name.toLowerCase() === SupportTicketConstants.EmailToTicketReassign.toLowerCase())[0];
            const department: TicketDetailsResponse = this.departments
                && this.departments.filter(dept => dept.value === SupportTicketConstants.SmileTeam360)[0];
            const priority = this.ticketPriorities.filter(p => p.value === SupportTicketConstants.Medium)[0].id;
            const ticketStatusId = this.ticketStatus.filter(t => t.value === SupportTicketConstants.New)[0].id;
            if (this.userDetails && this.userDetails.userProfile && this.userDetails.userProfile.foreverFBOId
                && type && subType && department && sourceValue) {
                supportRequestObj = {
                    sourceId: sourceValue,
                    callerName: this.supportRequestForm.controls.name.value,
                    operatingCountryId: this.operatingCountryId,
                    typeId: type.id,
                    subTypeId: subType.id,
                    description: this.supportRequestForm.controls.message.value,
                    departmentId: department.id,
                    priorityId: priority,
                    subject: SupportTicketConstants.FLP360FormSubmission,
                    memberId: this.distributorId,
                    userId: +(this.userDetails.userProfile.foreverFBOId.replace(/-/g, '')),
                    firstName: this.userDetails.userProfile.firstName,
                    lastName: this.userDetails.userProfile.lastName,
                    sourceIdentifierValue: this.supportRequestForm.controls.email.value,
                    statusId: ticketStatusId,
                    memberType: this.getMemberType()
                };
            } else {
                this.errorMessage();
            }
        }
        setTimeout(() => {
            if ((supportRequestObj && supportRequestObj.memberType && !this.enableCSPV2TicketCreation) || (supportRequestObj && this.enableCSPV2TicketCreation)) {
                this.supportTicketService.createSupportTicket(supportRequestObj, this.operatingCountryName).subscribe(response => {
                    if (response) {
                        this.ticketResponse = response;
                        if (this.uploader.queue.length > 0 && !(this.enableCSPV2TicketCreation && environment.cspv2LiveCountries.includes(this.userDetails.userProfile.defaultCountry))) {
                            this.getGuestToken(response.id);
                        } else {
                            this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
                            // If not from help, routing to support-ticket/summary 
                            if (!this.fromHelp) {
                                this.router.navigate(['/support-ticket/summary']);
                            }
                            // if from help, sending the boolean value through message service
                            else {
                                this.messageService.sendIsSupportFormFromHelp(true);
                            }
                        }
                    } else {
                        this.errorMessage();
                    }
                }, (error) => {
                    this.errorMessage();
                });
            } else {
                this.errorMessage();
            }
        }, 2000);

    }

    /**
     * @description
     * @date 2020-05-07
     * @returns {string}
     * @memberof SupportTicketFormComponent
     */
    getGuestToken(ticketId?: number): void {
        this.supportTicketService.getGuestToken().subscribe(response => {
            if (response) {
                this.guestToken = response;
                this.uploadFile(ticketId);
            } else {
                this.errorMessage();
            }

        }, (err) => {
            this.errorMessage();
        });
    }

    getGuestTokenDetails() {
        this.supportTicketService.getGuestTokenDetails().subscribe(response => {
            if (response) {
                this.guestToken = response;
                this.uploadFileDetails();
            } else {
                this.errorMessage();
            }

        }, (err) => {
            this.errorMessage();
        });
    }

    /**
     * @description get Error Message
     * @date 2020-05-07
     * @memberof SupportTicketFormComponent
     */
    errorMessage(): void {
        const errorMsg = this.translateKey.transform(this.translatePipe.instant
            ('supportTicket.There is some technical issue while submitting the request. So please contact us on '), false);
        this.globalErrorMsg = errorMsg;
        this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
    }

    /**
     * @description get Countries List
     * @date 2020-05-06
     * @memberof SupportTicketComponent
     */
    getCountriesList(): void {
        this.supportTicketService.getCountriesList().subscribe(response => {
            if (response && response.length > 0) {
                this.countriesList = response;
                this.getOperatingCountryId(this.userDetails.userProfile.defaultCountry)
            } else {
                this.errorMessage();
            }
        }, (error) => {
            this.errorMessage();
        });
    }

    getEntitiesList() {
        this.supportTicketService.getEntitiesList().subscribe(response => {
            if (response && response.length > 0) {
                this.countriesList = response;
                this.getOperatingCountryIdDetails(this.userDetails.userProfile.defaultCountry)
            } else {
                this.errorMessage();
            }
        }, (error) => {
            this.errorMessage();
        });
    }

    getOperatingCountryIdDetails(countryCode: string) {
        const operatingCountry = this.countriesList.filter(x => x.code === countryCode);
            if (operatingCountry && operatingCountry.length > 0) {
                this.operatingCountryId = operatingCountry[0].id;
                this.operatingCountryName = operatingCountry[0].code;
                this.getTicketTypeDetails();
            } else {
                const errorMsg = this.translateKey.transform(this.translatePipe.instant
                    ('supportTicket.FBO home Country not found'), false);
                this.globalErrorMsg = errorMsg;
                this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
            }
    }

    getTicketTypeDetails() {
        this.supportTicketService.getTicketTypeDetails(this.operatingCountryId).subscribe(response => {
            if(response && response && response.ticketTypeModels && response.ticketTypeModels.length > 0) {
                this.ticketType = response.ticketTypeModels;
                const ticketTypeDetails = response.ticketTypeModels.filter(x => x.name.toLowerCase() === SupportTicketConstants.EmailToTicket.toLowerCase())[0];
                const typeId = ticketTypeDetails && ticketTypeDetails.id;
                if(typeId) {
                    this.supportTicketService.getSubTicketTypeDetails(typeId).subscribe(subTicket => {
                        if(subTicket && subTicket.ticketSubTypeModel && subTicket.ticketSubTypeModel.length > 0) {
                        this.subTypes = subTicket.ticketSubTypeModel;
                        this.getDepartmentDetails();
                        }
                    }, (error) => {
                        this.errorMessage();
                    });
                }
            } 
        }, (error) => {
            this.errorMessage();
        });
    }

    getDepartmentDetails() {
        this.supportTicketService.getDepartmentDetails().subscribe(response => {
            if(response && response.departmentModels && response.departmentModels.length > 0) {
                this.departments = response.departmentModels;
                this.submitSupportRequest();
            }
        }, (error) => {
            this.errorMessage();
        });
    }

    getTicketStatusDetails() {
        this.supportTicketService.getTicketStatusDetails().subscribe(response => {
            this.ticketStatus = response && response.ticketStatuses;
        }, (error) => {
            this.errorMessage();
        });
    }

    getSourceTypeDetails() {
        this.supportTicketService.getSourceTypeDetails().subscribe(response => {
            if(response) {
                this.sourceType = response;
            }
        }, error => {
          this.errorMessage();
        });
      }

      getTicketCategoryDetails() {
        this.supportTicketService.getTicketCategoryDetails().subscribe(response => {
          this.categoryId = response && response.filter(x => x.value === TicketCreation.TICKET_CATEGORY)[0].id;
        }, error => {
          this.errorMessage();
        });
      }

      uploadFileDetails(): void {
        this.uploader.queue.map(s => s.url = ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'file/upload');
          if (this.uploader && this.uploader.queue.length > 0) {
              this.uploader.onBuildItemForm = (item: any, form: any) => {
                  item.withCredentials = false;
                  const timestamp = (new Date).getTime();
                  const path = 'tickets/attachments/' + timestamp + '/';
                  const _this = this;
                //   item.uploader.authToken = this.supportTicketService.getAuthorizationHeader(_this.guestToken,this.ssoService.getNonce());
                  item.uploader.authToken = this.supportTicketService.getAuthorizationToken(_this.guestToken);
                  item.uploader.authTokenHeader = 'Authentication';
                  const currentDate = new Date();
                  let date = currentDate.getDate();
                  let month = currentDate.getMonth() + 1;
                  let year = currentDate.getFullYear();
                  let hours = currentDate.getHours();
                  let minutes = currentDate.getMinutes();
                  let fileName = item.file.name.split('.');
                  item.file.name = fileName[0] + '_' + (date < 10 ? ('0' + date) : date) + (month < 10 ? ('0' + month) : month) + year + (hours < 10 ? ('0' + hours) : hours) + (minutes < 10 ? ('0' + minutes) : minutes) + '.' + fileName[1];
                  form.append('fileName', item.file.name);
                  form.append('path', path);
              };
              for (let i = 0; i < this.uploader.queue.length; i++) {
                  this.uploader.queue[i].upload();
              }
          }
    }

    /**
     * getOperatingCountryId
     * @param countryCode
     * @param isOperatingCountry 
     */
    getOperatingCountryId(countryCode: string, isOperatingCountry?: boolean): void {
        const operatingCountry = this.countriesList.filter(x => x.value === countryCode);
        if (operatingCountry && operatingCountry.length > 0) {
            this.operatingCountryId = operatingCountry[0].id;
            this.operatingCountryName = operatingCountry[0].value;
            this.getTicketTypesAndSubTypes();
        } else if (!isOperatingCountry) {
            this.getOperatingCountryInfoByISOCode(countryCode);
        } else {
            const errorMsg = this.translateKey.transform(this.translatePipe.instant
                ('supportTicket.FBO home Country not found'), false);
            this.globalErrorMsg = errorMsg;
            this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
        }
    }

    /**
     * getOperatingCountryInfoByISOCode()
     * @param countryCode
     */
    getOperatingCountryInfoByISOCode(countryCode: string): void {
        this.supportTicketService.getOperatingCountryByCountryCode(countryCode).subscribe(response => {
            if (response && response.operatingCountryCode) {
                this.getOperatingCountryId(response.operatingCountryCode, true);
            } else {
                this.errorMessage();
            }
        }, error => {
            this.errorMessage();
        });
    }

    /**
     * @description get Member Type
     * @date 2020-05-06
     * @returns {string}
     * @memberof SupportTicketComponent
     */
    getMemberType(): string {
        let memberType: string;
        if (this.userDetails && this.userDetails.userProfile && this.userDetails.userProfile.fboLevel) {
            if (this.userDetails.userProfile.fboLevel === MemberLevel.RETAIL || this.userDetails.userProfile.fboLevel === 'R'
                || this.userDetails.userProfile.fboLevel.toUpperCase() === MemberLevel.RETAILCUSTOMER.toUpperCase()) {
                memberType = MemberType.RETAIL;
            } else if (this.userDetails.userProfile.fboLevel === MemberType.GUEST || this.userDetails.userProfile.fboLevel === 'G') {
                memberType = MemberType.GUEST;
            } else if (this.userDetails.userProfile.fboLevel === MemberLevel.FPC
                || this.userDetails.userProfile.fboLevel === MemberLevel.PreferredCustomer) {
                memberType = MemberType.FPC;
            } else {
                memberType = MemberType.FBO;
            }
        }
        return memberType;
    }

    /**
     * @description Item upload event
     * @date 2020-05-05
     * @param {FileItem} item
     * @param {string} response
     * @param {number} status
     * @param {ParsedResponseHeaders} headers
     * @memberof SupportRequestFormComponent
     */
    onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
        if (response && !(this.enableCSPV2TicketCreation && environment.cspv2LiveCountries.includes(this.userDetails.userProfile.defaultCountry))) {
            const res = JSON.parse(response);
            const fileDetails: FileDetails = {
                userId: this.supportRequestForm.controls.fboId.value,
                fileName: this.uploader.queue[this.count].file.name.substr(0, 95),
                url: res.url,
                eTag: res.eTag,
                key: res.key
            };
            this.uploadResponse.push(fileDetails);
            this.count++;
            if (this.uploader.queue.length === this.count) {
                // Save Attachment
                this.saveAttachments(this.uploadResponse);
            }

        } else 
        if (response && this.enableCSPV2TicketCreation)  {
            const res = JSON.parse(response);
            const fileDetails: FileDetails = {
                fileName: this.uploader.queue[this.count].file.name.substr(0, 95),
                url: res.resourceUrl,
            };
            this.uploadResponse.push(fileDetails);
        } else {
            this.fileUploadErrorMessages = [];
            this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
            const error = this.translateKey.transform(this.translatePipe.instant
                ('supportTicket.File Not uploaded'), false);
            this.fileUploadErrorMessages.push(error);
        }
    }

    /**
     * @description save attachments
     * @date 2020-05-06
     * @param {FileDetails[]} fileUploadRequest
     * @memberof SupportTicketComponent
     */
    saveAttachments(fileUploadRequest: FileDetails[]): void {
        this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
        this.supportTicketService.saveAttachments(fileUploadRequest, this.ticketResponse.id).subscribe(response => {
            //If not from help, routing to support-ticket summary component
            if (!this.fromHelp) {
                this.router.navigate(['/support-ticket/summary']);
            }
            //If from help, sending the boolean value through message service
            else {
                this.messageService.sendIsSupportFormFromHelp(true);
            }
        }, (errMsg) => {
            this.errorMessage();
        });
    }

    /**
     * @description Error while uplaoding the document
     * @date 2020-05-05
     * @param {FileItem} item
     * @param {string} response
     * @param {number} status
     * @param {ParsedResponseHeaders} headers
     * @memberof SupportRequestFormComponent
     */
    onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
        this.fileUploadErrorMessages = [];
        this.spinnerService.stop(SupportTicketConstants.SUPPORT_TICKET_SPINNER);
        const error = this.translateKey.transform(this.translatePipe.instant
            ('supportTicket.Failed to upload document.Please try after sometime'), false);
        this.fileUploadErrorMessages.push(error);
    }

    /**
     * @description upload document
     * @date 2020-05-07
     * @param {SupportTicketRequest} ticketResponse
     * @memberof SupportTicketFormComponent
     */
    uploadFile(ticketId: number): void {
        if (this.uploader && this.uploader.queue.length > 0) {
            let filelength = 0;
            const ticketNumber = ticketId;
            this.uploader.onBuildItemForm = (item: any, form: any) => {
                filelength++;
                item.withCredentials = false;
                const _this = this;
                item.uploader.authToken = this.supportTicketService.getAuthorizationToken(_this.guestToken);
                item.uploader.authTokenHeader = 'Authentication';
                const fileNameArr = item.file.name.split('.');
                item.file.name = fileNameArr[0] + '_' + ticketNumber + '_' + filelength
                    + (fileNameArr.length > 1 ? '.' + fileNameArr[fileNameArr.length - 1] : '');
                form.append('fileName', decodeURIComponent(encodeURIComponent(item.file.name)));
                form.append('path', 'content/customer-service/' + this.operatingCountryId + '/');
            };
            for (let i = 0; i < this.uploader.queue.length; i++) {
                this.uploader.queue[i].upload();
            }
        }
    }

    /**
     * @description check for the max attachments uploaded
     * @date 2020-05-06
     * @memberof SupportTicketComponent
     */
    fileChangeEvent(): void {
        if (this.uploader.queue) {
            this.attachmentsLength = this.uploader.queue.length;
            this.fileUploadErrorMessages = [];
            if (this.uploader.queue.length > 5) {
                this.uploader.queue[5].remove();
                const error = this.translateKey.transform(this.translatePipe.instant
                    ('supportTicket.Max 5 attachments allowed'), false);
                this.fileUploadErrorMessages.push(error);
            } else {
                for (let i = 0; i < this.attachmentsLength; i++) {
                    const typeFlag = this.checkFileType(this.uploader.queue[i]._file);
                    const sizeFlag = this.checkFileSize(this.uploader.queue[i]._file);
                    if (!typeFlag) {
                        const error =
                            this.translateKey.transform(this.translatePipe.instant
                                ('supportTicket.Not a valid file type Please upload JPG or PNG file'), false);
                        this.fileUploadErrorMessages.push(error);
                        this.uploader.queue[i].remove();
                    }
                    if (!sizeFlag) {
                        const error =
                            this.translateKey.transform(this.translatePipe
                                .instant('supportTicket.Max file size exceeded'), false);
                        this.fileUploadErrorMessages.push(error);
                        this.uploader.queue[i].remove();
                    }
                }
            }
        }
    }

    /**
     * @description Check File Size
     * @date 2020-05-06
     * @param {*} file
     * @returns {boolean}
     * @memberof SupportTicketComponent
     */
    checkFileSize(file: any): boolean {
        if (file.size > SupportTicketConstants.MAX_FILE_SIZE) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * @description Check File Type
     * @date 2020-05-06
     * @param {*} file
     * @returns {boolean}
     * @memberof SupportTicketComponent
     */
    checkFileType(file: any): boolean {
        const fileExt = file.name.split('.');
        const extension = fileExt[fileExt.length - 1].toLowerCase();
        let validExt = false;
        SupportTicketConstants.ACCEPTED_FILE_TYPES.forEach(type => {
            if (type.toLocaleLowerCase() === extension) {
                validExt = true;
            }
        });
        return validExt;
    }
}

