import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../core/services/message-service.service';

@Component({
    selector: 'app-layout',
    templateUrl: '../templates/template1/views/app-layout.component.html'

})
export class LayoutComponent implements OnInit {
    toggleStatus: boolean;
    ngOnInit() {
        this.toggleStatus = true;
    }

    constructor(private messageService: MessageService) {

    }

    toggleFunction() {
        this.toggleStatus = !this.toggleStatus;
        this.messageService.sendToggleStatus(this.toggleStatus);
    }
}
