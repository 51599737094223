import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger.service';
import { SsoService } from '@core/services/sso.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService, private ssoService: SsoService) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
     // retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500 && error.error
           && error.url && error.url.includes('/chargebee/create/subscription')
            && error.error.errorMessage && error.error.errorMessage.includes('declined')) {
              let errorMessage = '';
              errorMessage = error.error.errorMessage.indexOf('-') > -1 ?
                            error.error.errorMessage.substring(
                              error.error.errorMessage.indexOf('-') + 1 , error.error.errorMessage.length)
                              : error.error.errorMessage;
              this.loggerService.logError(error);
              return throwError(errorMessage);
        } else if (error.status === 401) {
          this.ssoService.logOutSettings();
          const redirectedUrl = this.ssoService.getSSOLogoutUrl(
            window.location.origin
          );
          this.ssoService.ssoRedirect(redirectedUrl);
          // refresh token
        } else {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          this.loggerService.logError(error);
          return throwError(errorMessage);
        }
      })
    );
  }
}
