import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from '@core/services/message-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppTranslationModule } from './app-translations.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { FooterComponent } from './layouts/components/footer.component';
import { HeaderComponent } from './layouts/components/header.component';
import { LayoutComponent } from './layouts/components/layout.component';
import { LoginLayoutComponent } from './layouts/components/login-layout.component';
import { NavbarComponent } from './layouts/components/navbar.component';
import { AppLoaderService } from '@core/services/app-loader.service';
import { BackgroundComponent } from './layouts/components/background.component';
import { SharedModule } from '@shared/shared.module';
import { SpinnerService } from '@core/services/spinner.service';
import { VerifyEmailModule } from '@modules/verify-email/verify-email.module';
import { SupportTicketModule } from '@modules/support-ticket/support-ticket.module';
import { TrainingTooltipService } from './layouts/services/training-tooltip.service';
@NgModule({
  declarations: [
    HeaderComponent,
    AppComponent,
    FooterComponent,
    LayoutComponent,
    LoginLayoutComponent,
    NavbarComponent,
    BackgroundComponent
  ],
  imports: [
    BrowserModule,
    CollapseModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CoreModule,
    TranslateModule,
    AppTranslationModule,
    HttpClientModule,
    SharedModule,
    VerifyEmailModule,
    SupportTicketModule,
    BsDropdownModule.forRoot(),
  ],
  providers: [Title, CookieService, MessageService, AppLoaderService , SpinnerService, TrainingTooltipService],
  bootstrap: [AppComponent]
})
export class AppModule { }
