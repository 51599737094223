export class SupportTicketConstants {
    public static FLP360FormSubmission = 'FLP360 Form Submission';
    public static Email = 'Email';
    public static EmailToTicket = 'Email To Ticket';
    public static EmailToTicketReassign = 'Email to Ticket Reassign';
    public static SmileTeam360 = 'Smile Team 360';
    public static Medium = 'Medium';
    public static New = 'New';
    public static MAX_FILE_SIZE = 1000000;
    public static PNG = 'png';
    public static JPEG = 'jpeg';
    public static JPG = 'jpg';
    public static ACCEPTED_FILE_TYPES = [SupportTicketConstants.PNG, SupportTicketConstants.JPEG, SupportTicketConstants.JPG];
    public static MAX_UPLOADS = 5;
    public static MAX_LIMIT_REACHED = 'maxLimitReached';
    public static MAX_CHARACTERS = 250;
    public static MIN_CHARACTERS = 3;
    public static VALUE = 'value';
    public static PERSONAL_INFO = 'personalinfo';
    public static REPORTS = 'reports';
    public static TOOLS = 'tools';
    public static BASE_URL = 'enrollment';
    public static PERSONAL_INFO_SUMMARY = 'summary';
    public static SUPPORT_TICKET_SPINNER = 'supportTicketSpinner';
}
