import { Component ,OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { ImageConstants } from '@core/constants/images.constant';
import { RoutingConstants } from '@core/constants/routing.constants';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../core/services/message-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: '../templates/template1/views/app-footer.component.html',
  styleUrls: [
    '../templates/template1/themes/app-footer.component.less'
  ]
})
export class FooterComponent implements OnDestroy {
  cdnURL = environment.cdnURL;
  baseUrl = environment.uiTemplateBaseUrl;
  footerImage = ImageConstants.FOOTER_IMAGE;
  privacyLink = environment.cdnURL + ImageConstants.PRIVACY_LINK;
  toggleStatus: any;
  isRTL: boolean;
  toggleStatusSubscription: Subscription;
  languageOrientationSubscription: Subscription;
  currentYear = new Date().getFullYear();

  constructor(
    private messageService: MessageService,
    private router: Router
  ) {
    this.toggleStatusSubscription = this.messageService.getToggleStatus().subscribe(message => {
      this.toggleStatus = message.text;
    });
    this.languageOrientationSubscription = this.messageService.getLanguageOrientation().subscribe(message => {
      this.isRTL = message.text;
    });
  }
  getAccessibilityLink() {
    this.router.navigate([RoutingConstants.ACCESSIBILITY_URL]);
  }
  toggleSideNav() {
    this.toggleStatus = false;
    this.messageService.sendToggleStatus(this.toggleStatus);
  }

  ngOnDestroy() {
    this.toggleStatusSubscription?.unsubscribe();
    this.languageOrientationSubscription?.unsubscribe();
  }
}
