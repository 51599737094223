
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { CultureCodeConstants } from '@core/constants/culture-code.constants';
import { ImageConstants } from '@core/constants/images.constant';
import { RoutingConstants } from '@core/constants/routing.constants';
import { Constants } from '@core/constants/sso.constants';
import { TranslateKey } from '@core/pipes/translate-key.pipe';
import { MessageService } from '@core/services/message-service.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subscription } from 'rxjs';
import { CookieService } from 'utils/cookie.service';
import { SupportTicketConstants } from '../constants/support-ticket.constants';
import SSO = Constants.SSO;

@Component({
  selector: 'app-header',
  templateUrl: '../templates/template1/views/app-header.component.html',
  styleUrls: [
    '../templates/template1/themes/app-header.less'
  ],
  providers: [TranslateKey],
  encapsulation: ViewEncapsulation.None
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  enableSignup = false;
  enableReport = false;
  enableInSummary = false;
  navigateSignup: string;
  eventSubscription: any;
  contentHideMenu: string = '';
  menuStatus: number;
  enrollmentUrl = environment.baseUrl + '/flp360/peronal-info?HideMenu' + this.contentHideMenu;
  cdnURL = environment.cdnURL;
  imagePath = ImageConstants.IMAGE_PATH;
  desktopLogo = ImageConstants.DESKTOP_HEADER_LOGO;
  logoPath = ImageConstants.LOGO_PATH;
  foreverLivingLogo = ImageConstants.FOREVER_LIVING_LOGO;
  languageList = [];
  isLanguageListVisible = false;
  selectedLanguage = 'en-US';
  browserName: string;
  selectedLanguageString: string;
  hideMenuStatusSubscription: Subscription;
  fromPWAApp = false;
    constructor(private router: Router,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private translate: TranslateService,
                private meta: Meta, private route: ActivatedRoute
    ) {
      this.eventSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (window.location.pathname.split('/').indexOf(SupportTicketConstants.REPORTS) > -1) {
          this.meta.updateTag({name: 'title', content: environment.reportsTitle});
          this.meta.updateTag({
            name: 'description',
            content: environment.reportsDescription
          });
          this.enableReport = true;
          this.enableSignup = false;
          this.enableInSummary = false;
        } else if (window.location.pathname.split('/').indexOf(SupportTicketConstants.TOOLS) > -1) {
          this.meta.updateTag({name: 'title', content: environment.toolsTitle});
          this.meta.updateTag({
            name: 'description',
            content: environment.toolsDescription
          });
          this.enableReport = false;
          this.enableSignup = false;
          this.enableInSummary = false;
        } else if (window.location.pathname.split('/').indexOf(SupportTicketConstants.PERSONAL_INFO) > -1) {
          this.enableSignup = true; this.enableReport = false;
        } else if (window.location.pathname.split('/').indexOf(SupportTicketConstants.PERSONAL_INFO_SUMMARY) > -1) {
          this.enableInSummary = true;
          this.enableSignup = true;
          this.enableReport = false;
        } else if (window.location.pathname.split('/').indexOf(SupportTicketConstants.BASE_URL) > -1) {
          this.meta.updateTag({name: 'title', content: environment.enrollmentTitle});
          this.meta.updateTag({
            name: 'description',
            content: environment.enrollmentDescription
          });
          this.enableReport = false;
          this.enableSignup = false;
          this.enableInSummary = false;
        }

      }
    });
      this.hideMenuStatusSubscription = this.messageService.getHideMenuStatus().subscribe(message => {
      this.menuStatus = message.text;
    });
      this.getLanguages();
  }

  ngOnInit() {
    this.browserName = this.localStorageService.get(CacheKeyConstants.BROWSER_NAME);
    this.selectedLanguage = this.localStorageService.get(CacheKeyConstants.selectedLanguage)
     ? this.localStorageService.get(CacheKeyConstants.selectedLanguage) : 'en-US';
    this.selectedLanguageString = this.languageList.filter( language => this.selectedLanguage === language.cultureCode)[0].language;

  }
  getLanguages(): void {
    this.languageList = CultureCodeConstants.UPDATED_LANGUAGE_LIST;
    this.languageList.sort((a, b) => a.language.localeCompare(b.language));
  }
  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.selectedLanguageString = this.languageList.filter(language => this.selectedLanguage === language.cultureCode)[0].language;
    this.showLanguageList();
    this.translate.use(lang);
    this.messageService.sendSelectedLanguage(lang);
    if ( this.localStorageService.get(CacheKeyConstants.selectedLanguage) != null ) {
    this.localStorageService.set(CacheKeyConstants.selectedLanguage, lang);
    }
  }
  showLanguageList() {
    this.isLanguageListVisible = !this.isLanguageListVisible;
  }
  /**
   * @description Navigate to the corporate portal
   * @date 2019-11-29
   * @memberof AppHeaderComponent
   */
  navigateToCorporate(): void {
    window.location.href = environment.corporateURL;
  }

  /**
   * @description Navigate to enrollment page
   * @date 2019-12-03
   * @memberof AppHeaderComponent
   */
  navigatetoFLP360(): void {
    this.enableSignup = false;
    this.router.navigateByUrl(RoutingConstants.ENROLLMENT);
  }

  /**
   * @description navigate to enrollment form
   * @date 2019-12-03
   * @memberof AppHeaderComponent
   */
  navigateToEnrollmentForm(): void {
    if (!environment.enableEnrollment) {
      this.router.navigateByUrl(RoutingConstants.ENROLLMENT_MAINTENANCE);
    } else {
      this.router.navigateByUrl(RoutingConstants.PERSONAL_INFO);
    }
  }



  /**
   * @description login to system
   * @date 2019-12-03
   * @memberof AppHeaderComponent
   */
  login(): void {
    if (localStorage.getItem('appflp360.userInfo')) {
      this.router.navigateByUrl(RoutingConstants.DASHBOARD_URL);
    } else {
      localStorage.removeItem('appflp360.state');
      const state = SSO.getNonce();
      const idNonce = SSO.getNonce();
      CookieService.setCookie(SSO.NONCE, idNonce, null, environment.cookiePath, environment.cookieDomain);
      localStorage.setItem('appflp360.state', JSON.stringify(state));
      window.location.href = decodeURIComponent(encodeURIComponent(environment.ssoApiURL + '/auth?client_id=' + environment.ssoClientID +
        '&redirect_uri=' +Constants.SSO.CURRENT_ORGIN+ '/login&scope=openid&response_type=code&state=' + state + '&nonce='
        + idNonce));
    }
  }

  ngOnDestroy() {
    this.hideMenuStatusSubscription?.unsubscribe();
    this.eventSubscription?.unsubscribe();
  }
}
