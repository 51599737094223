import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { DrawArrowDirective } from '@core/directives/draw-arrow.directive';
import { MessageService } from '@core/services/message-service.service';
import { environment } from '@env/environment';
import { UserViewSettings } from '@modules/enrollment/interfaces/user-view-settings.interface';
import { EnrollmentService } from '@modules/enrollment/services/enrollment.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GuidedTourComponentSettings } from '../component-settings';
import { GuidedTourConstants, StartElementIds } from '../constants/guided-tour.constants';
import { GuidedTourInfo } from '../interfaces/guided-tour-info.interface';

@Component({
    selector: 'app-guided-tour',
    templateUrl: '../templates/template1/views/app-guided-tour.component.html',
    styleUrls: ['../templates/template1/themes/app-guided-tour.component.less'],
    providers: [DrawArrowDirective]
})
export class AppGuidedTourComponent implements OnInit {

    gTComponentSettings: GuidedTourComponentSettings = new GuidedTourComponentSettings();
    @ViewChild('guidedTourPopupModal') guidedTourPopupModal: ModalDirective;
    @Input() selectedTour: string;
    @Output() closeGuidedTourPopup = new EventEmitter<any>();
    @Output() closeTrainingTour = new EventEmitter<any>();
    @Output() openTrainingTour = new EventEmitter<any>();
    scrollingPoint = 0;
    @Input() userViewSettings: UserViewSettings;
    @Input() isUpdateUserViewSettings: boolean;
    constructor(
        private translateService: TranslateService,
        private localStorageService: LocalStorageService,
        private sanitizer: DomSanitizer,
        private messageService: MessageService,
        private drawArrow: DrawArrowDirective,
        private cdRef: ChangeDetectorRef,
        private enrollmentService: EnrollmentService) { }

    ngOnInit(): void {
        this.gTComponentSettings.selectedLanguage = this.localStorageService.get(CacheKeyConstants.selectedLanguage) ?
            this.localStorageService.get(CacheKeyConstants.selectedLanguage) : 'en-US';
        this.loadDefaultSettings();
        this.getGuidedOverallTourData();
    }

    /**
     * @date 04-11-2020
     * @author spalanivelu
     * loadDefaultSettings()
     */
    loadDefaultSettings(): void {
        this.gTComponentSettings.scrollToBottom = GuidedTourConstants.SCROLL_TO[this.selectedTour];
        window.scrollTo(0, this.gTComponentSettings.scrollToBottom);
        this.messageService.setSideNavOpen(true);
        this.messageService.sendCardDropStatus(false);
        this.gTComponentSettings.deviceWidth = window.innerWidth;
    }

    /**
     * @description This mehod used to get overall guided tour from translations
     * @date 04-11-2020
     * @author spalanivelu
     * getGuidedOverallTourData()
     */
    getGuidedOverallTourData(): void {
        const translations = this.translateService.store.translations[this.gTComponentSettings.selectedLanguage];
        this.gTComponentSettings.guidedTourInfo = [];
        if (translations) {
            const guidedTourData = translations[GuidedTourConstants.GUIDED_TOUR];
            if (guidedTourData) {
                if(this.selectedTour==GuidedTourConstants.TOOLS_TOUR){
                    this.selectedTour=GuidedTourConstants.TOOLS_TOUR_V2;
                }                  
                const steps = GuidedTourConstants.TOUR_STEPS[this.selectedTour];
                const tourData = GuidedTourConstants.GUIDED_TOUR_DATA;
                steps.forEach((item, i) => {
                    const data: GuidedTourInfo = Object.assign({});
                    tourData.forEach((innerItem, j) => {
                        data[innerItem] = translations[GuidedTourConstants.GUIDED_TOUR][this.selectedTour
                            + '-' + item + '-' + innerItem];
                    });
                    data.startElementId = GuidedTourConstants.START_ELEMENT_IDS[this.selectedTour][i];
                    if(this.selectedTour==GuidedTourConstants.OVERALL_TOUR){
                        data.endElementId = GuidedTourConstants.END_ELEMENT_IDS[GuidedTourConstants.OVERALL_TOUR_V2][i];
                    }  
                    else{
                        data.endElementId = GuidedTourConstants.END_ELEMENT_IDS[this.selectedTour][i];   
                    }                   
                    data.isVideoAvailable = GuidedTourConstants.IS_VIDEO_AVAILABALE[this.selectedTour][i];                  
                    this.gTComponentSettings.guidedTourInfo.push(data);
                });
               
            }
        }
        if (this.gTComponentSettings.guidedTourInfo && this.gTComponentSettings.guidedTourInfo.length) {
            this.gTComponentSettings.activeIndex = 0;
            this.gTComponentSettings.selectedTourInfo
                = this.gTComponentSettings.guidedTourInfo[this.gTComponentSettings.activeIndex];
                
                this.gTComponentSettings.modalWidth = GuidedTourConstants.MODAL_WIDTH[this.gTComponentSettings.selectedTourInfo.startElementId] ?
                GuidedTourConstants.MODAL_WIDTH[this.gTComponentSettings.selectedTourInfo.startElementId] : 500;
                this.highlightSection(this.gTComponentSettings.selectedTourInfo.endElementId);
                this.getSafeVideoURL();
                setTimeout(() => {
                    this.drawConnector();
                }, 200);
        }
    }

    /**
     * @description to get safe video url
     * @date 04-11-2020
     * @author spalanivelu
     * getSafeVideoURL()
     */
    getSafeVideoURL(): void {
        if (this.gTComponentSettings.selectedTourInfo.videoCTA) {
            this.gTComponentSettings.selectedTourInfo.safeVideoUrl
                = this.sanitizer.bypassSecurityTrustResourceUrl(this.gTComponentSettings.selectedTourInfo.videoCTA);
        }
    }

    /**
     * @description when user click on Next button from popup, Need to map data
     * @date 04-11-2020
     * @author spalanivelu
     * getNextTourInfo()
     */
    getNextTourInfo(): void {
        this.gTComponentSettings.activeIndex += 1;
        if (this.gTComponentSettings.activeIndex < this.gTComponentSettings.guidedTourInfo.length) {
            this.gTComponentSettings.selectedTourInfo = this.gTComponentSettings.guidedTourInfo[this.gTComponentSettings.activeIndex];
            this.gTComponentSettings.modalWidth = GuidedTourConstants.MODAL_WIDTH[this.gTComponentSettings.selectedTourInfo.startElementId] ?
            GuidedTourConstants.MODAL_WIDTH[this.gTComponentSettings.selectedTourInfo.startElementId] : 500;
            this.highlightSection(this.gTComponentSettings.selectedTourInfo.endElementId);
            this.getSafeVideoURL();
            setTimeout(() => {
                this.drawConnector();
            }, 0);
        } else if (this.gTComponentSettings.guidedTourInfo.length === this.gTComponentSettings.activeIndex) {
            this.closeTourPopup();
        } 
        if(this.gTComponentSettings.activeIndex < this.gTComponentSettings.guidedTourInfo.length &&this.selectedTour==GuidedTourConstants.TOOLS_TOUR_V2)  {
            this.closeTrainingTour.emit(); 
        }     
        
    }

    /**
     * @description to hightlight the div which is pointed from popup to particular element
     * @date 04-11-2020
     * @author spalanivelu
     * highlightSection()
     * @param id
     */
    highlightSection(ids: string[]): void {
        this.removeHighlightSection();
        if (this.gTComponentSettings.deviceWidth >= GuidedTourConstants.ARROWCONNECTOR_MIN_SCREEN_WIDTH) {
            if (ids.length && ids.length === 1 && StartElementIds.RETENTION === ids[0]) {
                ids = GuidedTourConstants.HIGHLIGHT_SECTION_IDS[ids[0]];
            }
            if(!ids.length && ids.length === 1 && StartElementIds.MEDIA_LIBRARY === ids[0]){
                ids = GuidedTourConstants.HIGHLIGHT_SECTION_IDS[ids[0]];
            }
            if(ids.length && ids.length === 1 && StartElementIds.SHAREABLE_CONTENT === ids[0]){
                ids = GuidedTourConstants.HIGHLIGHT_SECTION_IDS[ids[0]];
            }
            if(ids.length && ids.length ===1 && StartElementIds.TRAINING_TOOLS==ids[0]){
                ids = GuidedTourConstants.HIGHLIGHT_SECTION_IDS[ids[0]];
            }
            ids.forEach((id, i) => {
                const element = document.getElementById(id);
                if (element) {
                    element.classList.add('highlight__section');
                }
            });
        }
    }

    /**
     * @description To remove the higlighted section when popup is closed
     * @date 04-11-2020
     * @author spalanivelu
     * removeHighlightSection()
     */
    removeHighlightSection(): void {
        const element = document.getElementsByClassName('highlight__section');
        for (let i = element.length - 1 ; i >= 0; i--) {
            if (element && element.length > 0) {
                element[i].classList.remove('highlight__section');
            }
        }
        this.removeCurvedArrow();
    }

    /**
     * @description Remove the curved arrow part
     * @date 17-11-2020
     * @memberof AppGuidedTourComponent
     * removeCurvedArrow()
     */
    removeCurvedArrow() {
        const canvasElementExist: any = document.querySelector('.curved_arrow');
        if (canvasElementExist) {
            canvasElementExist.parentNode.removeChild(canvasElementExist);
            this.removeCurvedArrow();
        } else {
            return;
        }
    }

    /**
     * @description map previuos data when button clicked
     * @date 04-11-2020
     * @author spalanivelu
     * getPreviousTourInfo()
     */
    getPreviousTourInfo(): void {
        this.gTComponentSettings.activeIndex -= 1;
        this.gTComponentSettings.selectedTourInfo = this.gTComponentSettings.guidedTourInfo[this.gTComponentSettings.activeIndex];
        this.gTComponentSettings.modalWidth = GuidedTourConstants.MODAL_WIDTH[this.gTComponentSettings.selectedTourInfo.startElementId] ?
        GuidedTourConstants.MODAL_WIDTH[this.gTComponentSettings.selectedTourInfo.startElementId] : 500;
        this.highlightSection(this.gTComponentSettings.selectedTourInfo.endElementId);
        this.getSafeVideoURL();
        setTimeout(() => {
            this.drawConnector();
        }, 0);
        if(this.selectedTour==GuidedTourConstants.TOOLS_TOUR_V2 && this.gTComponentSettings.activeIndex==0){
            this.openTrainingTour.emit();
        }
    }

    /**
     * @description This methd used to close tour popup
     * @date 04-11-2020
     * @author spalanivelu
     * closeTourPopup()
     */
    closeTourPopup(): void {
        this.removeHighlightSection();
        this.guidedTourPopupModal.hide();
        this.closeGuidedTourPopup.emit();
        this.messageService.sendRoutedPath(true);
        this.updadeUserViewSettings();
    }

    @HostListener('window:resize', ['$event'])
    onScreenResize($event: Event): void {
        this.gTComponentSettings.deviceWidth = window.innerWidth;
        this.cdRef.detectChanges();
        setTimeout(() => {
            this.drawConnector();
        }, 0);
    }

    /**
     * @description This method used to draw curved arrow from one element to another using canvas
     * @date 04-11-2020
     * @author spalanivelu
     * drawConnector()
     */
    drawConnector(): void {
        const canvasElementExist: any = document.querySelector('.curved_arrow');
        if (canvasElementExist) {
            canvasElementExist.parentNode.removeChild(canvasElementExist);
        }
        const screenWidth = window.innerWidth;
        if (screenWidth >= GuidedTourConstants.ARROWCONNECTOR_MIN_SCREEN_WIDTH && this.gTComponentSettings.selectedTourInfo.startElementId
            && this.gTComponentSettings.selectedTourInfo.endElementId
            && this.gTComponentSettings.selectedTourInfo.endElementId.length > 0) {
            const endElementIds = this.gTComponentSettings.selectedTourInfo.endElementId;
            endElementIds.forEach((endElementId, i) => {
                const link = document.getElementById(this.gTComponentSettings.selectedTourInfo.startElementId);
                const secondlink = document.getElementById(endElementId);
                const firstElementPos = link?.getBoundingClientRect();
                const secondElementPos = secondlink?.getBoundingClientRect();
                const firstx = firstElementPos.left;
                const firsty = firstElementPos.top + (firstElementPos.height / 2) ;
                const secondx = secondElementPos.left + (this.gTComponentSettings.selectedTourInfo.startElementId
                    === StartElementIds.DASHBOARD_POPUP ? 0 : secondElementPos.width);
                const secondy = secondElementPos.top + (secondElementPos.height / 2);
                this.drawArrow.curvedArrow({
                    p0x: firstx + 20,
                    p0y: firsty,
                    p1x: this.getArrowX(this.gTComponentSettings.selectedTourInfo.startElementId),
                    p1y: this.getArrowY(this.gTComponentSettings.selectedTourInfo.startElementId),
                    p2x: secondx,
                    p2y: secondy + this.gTComponentSettings.scrollToBottom,
                    secondElement: secondElementPos,
                    firsElement: firstElementPos
                });
            });
        }
    }

    /**
     * getArrowX()
     */
    getArrowX(startElementId: string): number {
        let arrowX = 300;
        if (this.selectedTour === GuidedTourConstants.OVERALL_TOUR) {
            arrowX = this.getOverallTourArrowX(startElementId);
        } else if (this.selectedTour === GuidedTourConstants.REPORTS_TOUR) {
            arrowX = this.getReportsTourArrowX(startElementId);
        } else if (this.selectedTour === GuidedTourConstants.TOOLS_TOUR) {
            arrowX = this.getToolsTourArrowX(startElementId);
        }
        return arrowX;
    }

    /**
     * getOverallTourArrowX()
     */
    getOverallTourArrowX(startElemntId: string): number {
        let arrowX = 300;
        const width = window.innerWidth;
        switch (startElemntId) {
            case StartElementIds.DASHBOARD_POPUP:
                if (width <= 1080) {
                    arrowX = 800;
                } else if (width < 1200) {
                    arrowX = 900;
                } else if (width < 1400) {
                    arrowX = 1000;
                } else if (width < 1600) {
                    arrowX = 1100;
                } else if (width < 1800) {
                    arrowX = 1200;
                } else if (width < 2000) {
                    arrowX = 1400;
                }
                break;
            case StartElementIds.REPORTS_POPUP:
                case StartElementIds.LANDING_PAGE_POPUP:
                if (width < 1400) {
                    arrowX = 300;
                } else if (width < 1600) {
                    arrowX = 1100;
                } else if (width < 1800) {
                    arrowX = 1200;
                } else if (width < 2000) {
                    arrowX = 400;
                }
                break;
            case StartElementIds.TOOLS_POPUP:
                case StartElementIds.HELP_POPUP :
                if (width < 2000) {
                    arrowX = 400;
                }
                break;
            default:
                arrowX = 300;
                break;
        }
        return arrowX;
    }

    /**
     * getReportsTourArrowX()
     */
    getReportsTourArrowX(startElemntId: string): number {
        let arrowX = 300;
        const width = window.innerWidth;
        switch (startElemntId) {
            case StartElementIds.VOLUME_POPUP:
                    if (width < 1400) {
                        arrowX = 300;
                    } else if (width < 2000) {
                        arrowX = 450;
                    }
                    break;
                    case StartElementIds.FOCUSGROUP_POPUP:
                        if (width < 1400) {
                            arrowX = 300;
                        } else if (width < 2000) {
                            arrowX = 450;
                        }
                        break;
            case StartElementIds.DOWNLINE_DASHBOARD_POPUP:
                if (width < 1400) {
                    arrowX = 300;
                } else if (width < 2000) {
                    arrowX = 400;
                }
                break;
            case StartElementIds.CORE_DASHBOARD_POPUP:
                arrowX = 400;
                break;
            default:
                arrowX = 300;
                break;
        }
        return arrowX;
    }


    /**
     * @description Getting X co-ordinate for Tools arrows
     * @date 19-11-2020
     * @param {string} startElementId
     * @returns {number}
     * @memberof AppGuidedTourComponent
     */
    getToolsTourArrowX(startElementId: string): number {
        let arrowX = 300;
        const width = window.innerWidth;
        switch (startElementId) {
            case StartElementIds.CALL_TO_ACTION_POPUP:
                if (width < 1400) {
                    arrowX = 400;
                }
                break;
            case StartElementIds.MEDIA_SOCIAL_POPUP:
                if (width < 1400) {
                        arrowX = 400;
                }
                else if (width < 2000) {
                    arrowX = 500;
                }
                break;
            case StartElementIds.SOCIAL_LINKS_POPUP:
                arrowX = 400;
                break;
            default:
                arrowX = 300;
                break;
        }
        return arrowX;
    }

    /**
     * getArrowY()
     */
    getArrowY(startElementId: string): number {
        let arrowY = 250;
        if (this.selectedTour === GuidedTourConstants.OVERALL_TOUR) {
            arrowY = this.getOverallTourArrowY(startElementId);
        } else if (this.selectedTour === GuidedTourConstants.REPORTS_TOUR) {
            arrowY = this.getReportsTourArrowY(startElementId);
        } else if (this.selectedTour === GuidedTourConstants.TOOLS_TOUR) {
            arrowY = this.getToolsTourArrowY(startElementId);
        }
        return arrowY;
    }

    /**
     * getOverallTourArrowY()
     */
    getOverallTourArrowY(startElementId: string): number {
        let arrowY = 250;
        const width = window.innerWidth;
        switch (startElementId) {
            case StartElementIds.DASHBOARD_POPUP:
                if (width < 1200) {
                    arrowY = 400;
                } else if (width < 1400) {
                    arrowY = 250;
                } else if (width < 1600) {
                    arrowY = 600;
                } else if (width < 1800) {
                    arrowY = 280;
                } else if (width < 2000) {
                    arrowY = 300;
                }
                break;
            case StartElementIds.REPORTS_POPUP:
                case StartElementIds.LANDING_PAGE_POPUP:
                if (width < 1400) {
                    arrowY = 200;
                } else if (width < 1600) {
                    arrowY = 600;
                } else if (width < 1800) {
                    arrowY = 280;
                } else if (width < 2000) {
                    arrowY = 550;
                }
                break;
            case StartElementIds.TOOLS_POPUP:
                case StartElementIds.HELP_POPUP:
                if (width < 2000) {
                    arrowY = 250;
                }
                break;
            default:
                arrowY = 250;
                break;
        }
        return arrowY;
    }

    /**
     * getReportsTourArrowY()
     */
    getReportsTourArrowY(startElementId: string): number {
        let arrowY = 250;
        const width = window.innerWidth;
        switch (startElementId) {
            case StartElementIds.VOLUME_POPUP:
                case StartElementIds.DOWNLINE_DASHBOARD_POPUP:
                if (width < 1400) {
                    arrowY = 250;
                } else if (width < 2000) {
                    arrowY = 450;
                }
                break;
                case StartElementIds.FOCUSGROUP_POPUP:
                    if (width < 1400) {
                        arrowY = 250;
                    } else if (width < 2000) {
                        arrowY = 450;
                    }
                    break;
            case StartElementIds.CORE_DASHBOARD_POPUP:
                    arrowY = 500;
                    break;
            default:
                arrowY = 250;
                break;
        }
        return arrowY;
    }


    /**
     * @description Getting Y co-ordinate for Tools arrows
     * @date 19-11-2020
     * @param {string} startElementId
     * @returns {number}
     * @memberof AppGuidedTourComponent
     */
    getToolsTourArrowY(startElementId: string): number {
        let arrowY = 250;
        const width = window.innerWidth;
        switch (startElementId) {
            case StartElementIds.CALL_TO_ACTION_POPUP:
                if (width < 1400 ) {
                    arrowY = 510;
                } else if (width < 2000) {
                    arrowY = 550;
                }
                break;
                case StartElementIds.MEDIA_SOCIAL_POPUP:
                    if (width < 1400) {
                        arrowY = 550;
                    } else if (width < 2000) {
                        arrowY = 500;
                    }
                    break;
                case StartElementIds.SOCIAL_LINKS_POPUP:
                    if (width < 1400 ) {
                        arrowY = 500;
                    } else if (width < 2000) {
                        arrowY = 600;
                    }
                    break;
                case StartElementIds.LANDING_PAGE_POPUP:
                   arrowY = 300;
                   break;
                default:
                    arrowY = 250;
                    break;
        }
        return arrowY;
    }

    /**
     * update user view settings by fbo id
     * updadeUserViewSettings()
     */
    updadeUserViewSettings(): void {
        const isImpersonation = this.localStorageService.get(CacheKeyConstants.IMPERSONATED_USER);
        if (this.isUpdateUserViewSettings && !isImpersonation) {
            const data = {
                overallTourView: false,
                reportsTourView: false,
                toolsTourView: false,
                announcementView: false
            };
            this.enrollmentService.updateUserViewSettings(this.userViewSettings.fboId, data).subscribe(response => {
            });
        }
    }
    removeArrows(){
        this.removeCurvedArrow();
        this.closeTourPopup();
    }
}
