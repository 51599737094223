import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { CacheService } from '@core/services/cache.service';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { FLP360Status } from '@core/Enums/flp360-status.enum';

@Injectable()
export class ActiveUserGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private cacheService: CacheService) {

    }
    canActivate(): boolean {
        if (this.authService.isUserLoggedIn()) {
            const flp360StatusId = this.cacheService.get(CacheKeyConstants.FLP360FBOID);
            if (flp360StatusId && ((flp360StatusId === FLP360Status.Restricted) || (flp360StatusId === FLP360Status.Suspended))) {
                this.router.navigate(['dashboard/home']);
            } else {
                return true;
            }
        }
    }
}
