import { Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

    constructor(private elementRef: ElementRef) {

    }
    @Output()
    public appClickOutside: EventEmitter<boolean> = new EventEmitter<boolean>();


    @HostListener('document:click', ['$event', '$event.target'])

    public onClick(event: MouseEvent, targetElement: HTMLElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.appClickOutside.emit(null);
        }
    }

}
