import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { TranslateLoader } from '@ngx-translate/core';
import { OnInit, Injectable } from '@angular/core';
import { CookieService } from 'utils/cookie.service';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/internal/operators';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { Observable } from 'rxjs';
import { CultureCodeConstants } from '@core/constants/culture-code.constants';
import { CulturecodeModel } from '@modules/account/models/culturecode';
@Injectable({ providedIn: 'root' })
export class AppTranslateService implements TranslateLoader, OnInit {
  country: any;
  defaultLanguage: any;
  globalLanguage: any;
  availableLanguages: CulturecodeModel[] = [];
  requestedLanguage: CulturecodeModel[] = [];
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
  }

  /**
   * To get the translations of selected language file
   * @param  {any} language
   * @returns Observable
   */
  getTranslation(language: any): Observable<any> {
    this.availableLanguages = CultureCodeConstants.UPDATED_LANGUAGE_LIST;
    language = language || 'en-US';
    this.requestedLanguage = this.availableLanguages.filter(x => x.cultureCode === language);
    if (!this.requestedLanguage || this.requestedLanguage.length <= 0) {
      language = 'en-US';
    }
    try {
      language = JSON.parse(language);
    } catch (e) { }
    const homeCountryCode =
      this.localStorage.get(CacheKeyConstants.homeCountryCode) || 'usa';
    this.country =
      CookieService.getCookieValue(CacheKeyConstants.countryCode) || 'usa';
    let languageUrl: string;
    if (environment.cdnURL) {
      languageUrl = environment.cdnURL + '/portals/flp360/template1/json/' + language + '.json';
    } else {
      languageUrl = environment.baseHref + 'assets/json/' + language + '.json';
    }
    return this.http
      .get(languageUrl)
      .pipe(catchError(this.handleErrorObservable));
  }

  /**
   * To handle the obervable error response
   * @param  {Response|any} error
   */
  private handleErrorObservable(error: Response | any) {
    return Observable.throw(error.message || error);
  }
}
