import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageConstants } from '@core/constants/images.constant';
import { MessageService } from '@core/services/message-service.service';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: '../templates/template1/views/app-footer.component.html',
  styleUrls: [
    '../templates/template1/themes/app-footer.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppFooterComponent implements OnDestroy{
  cdnURL = environment.cdnURL;
  baseUrl = environment.uiTemplateBaseUrl;
  footerImage = ImageConstants.FOOTER_IMAGE;
  privacyLink = environment.cdnURL + ImageConstants.PRIVACY_LINK;
  corporateURL = environment.corporateURL;
  adaLink = environment.corporateURL + ImageConstants.ADA_LINK;
  toggleStatus: boolean;
  toggleStatusSubscription: Subscription;
  fromPWAApp = false;
  currentYear = new Date().getFullYear();

  constructor(private messageService: MessageService, private route: ActivatedRoute) {
    this.toggleStatusSubscription = this.messageService.getToggleStatus().subscribe(message => {
      this.toggleStatus = message.text;
    });
  }
  toggleSideNav() {
    this.toggleStatus = false;
    this.messageService.sendToggleStatus(this.toggleStatus);
  }
  ngOnDestroy() {
    this.toggleStatusSubscription?.unsubscribe();
  }
}
