import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Constants } from '@core/constants/sso.constants';
import { environment } from '@env/environment';
import { CookieService } from 'utils/cookie.service';
import { AppModule } from './app/app.module';
import { IdTokenService } from './utils/idtoken.service';
import SSO = Constants.SSO;


declare let apiConfig: any;

if (environment.production) {
  enableProdMode();
}

function redirectToSSO() {
  if (window.location.pathname.includes('/enrollment')) {
    bootstrapApplication();
  } else if (window.location.pathname.includes('/login')) {
    localStorage.removeItem('appflp360.state');
    const state = SSO.getNonce();
    const idNonce = SSO.getNonce();
    CookieService.setCookie(SSO.NONCE, idNonce, null, environment.cookiePath, environment.cookieDomain);
    localStorage.setItem('appflp360.state', JSON.stringify(state));
    window.location.href =environment.ssoApiURL + '/auth?client_id=' + environment.ssoClientID +
      '&redirect_uri=' + Constants.SSO.CURRENT_ORGIN+ '/login&scope=openid&response_type=code&state=' + state + '&nonce='
      + idNonce;
  } else if (window.location.pathname.includes('/verifyemail')) {
    bootstrapApplication();
  } else if (window.location.pathname.includes('/support-ticket')) {
    bootstrapApplication();
  } else if (window.location.pathname.includes('/external-reports')) {
    bootstrapApplication();
  } else if ((window.location.pathname.includes(''))) {
    window.location.href = '/enrollment';
  }
}
function updateClientAssertion() {
  let baseUrl = window.location.origin;
  if (!environment.production) {
    baseUrl = environment.baseUrl;
  }
  const configUrl = baseUrl + '/api/Configuration?redirect=' + SSO.getNonce();
  fetch(configUrl).then(
    function(response: any) {
      if (response) {
        response.json().then(function(data) {
          apiConfig.ssoClientAssertion = data.apiConfiguration.ssoClientAssertion;
          initializeAppState();
        })
          .catch(function(err: any) {
          });
      }
    })
    .catch(function(err: any) {
    });
}

function checkClientAssertionValidity() {
  const clientAssertion = atob(apiConfig.ssoClientAssertion);
  return IdTokenService.validateJwtExp(clientAssertion);
}

function userSessionExists(): boolean {
  const code: string = getParamValueFromUrl('code', window.location.href);
  if (window.location.pathname.includes('/login') && code) {
    return true;
  }
  const memberSessionId =  CookieService.getCookieValue(SSO.MEMBER_SESSIONID);
  return newFunction();

  function newFunction(): boolean {
    if (!memberSessionId) {
      localStorage.removeItem('appflp360.userInfo');
      localStorage.removeItem('appflp360.countryList');
      localStorage.removeItem('appflp360.Retention Count');
      localStorage.removeItem('appflp360.Recruitment Count');
      localStorage.removeItem('appflp360.flp360StatusId');
      localStorage.removeItem('appflp360.homeCountryName');
      localStorage.removeItem('appflp360.Development Count');
      localStorage.removeItem('appflp360.Productivity Count');
      localStorage.removeItem('appflp360.items');
      localStorage.removeItem('appflp360.browser');
      localStorage.removeItem('appflp360.eagleManagerCountryDisplayName');
      localStorage.removeItem('appflp360.cbCountryList');
      localStorage.removeItem('appflp360.monthList');
      localStorage.removeItem('appflp360.eagleManagerCountryList');
      localStorage.removeItem('appflp360.isFlp360Eligible');
      localStorage.removeItem('appflp360.memberLevel');
    }
    return memberSessionId ? true : false;
  }
}

// TODO have to keep in common
function getParamValueFromUrl(searchParam: string, urlString: string) {
  urlString=decodeURIComponent(encodeURIComponent(urlString));
  const match = RegExp('[?&]' + searchParam + '=([^&]*)').exec(urlString);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function initializeAppState() {

  if (apiConfig) {
    if (window.location.href.includes('isImpersonation')) {
      localStorage.setItem('appflp360.impersonation', 'true');
      localStorage.removeItem('appflp360.countryList');
      localStorage.removeItem('appflp360.Retention Count');
      localStorage.removeItem('appflp360.Recruitment Count');
      localStorage.removeItem('appflp360.flp360StatusId');
      localStorage.removeItem('appflp360.homeCountryName');
      localStorage.removeItem('appflp360.Development Count');
      localStorage.removeItem('appflp360.Productivity Count');
      localStorage.removeItem('appflp360.items');
      localStorage.removeItem('appflp360.browser');
      localStorage.removeItem('appflp360.monthList');
      localStorage.removeItem('appflp360.isFlp360Eligible');
      localStorage.removeItem('appflp360.memberLevel');
      sessionStorage.removeItem('openPreferredCustomerModel');
      localStorage.setItem('appflp360.impersonatedUser', 'true');
    }
    if (checkClientAssertionValidity()) {
      if (userSessionExists()) {
        bootstrapApplication();
      } else {
        redirectToSSO();
      }
    } else {
      updateClientAssertion();
    }
  } else {
    setTimeout(function() {
      initializeAppState();
    }, 2000);
  }
}

initializeAppState();

function bootstrapApplication() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}
