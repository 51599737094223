import { Directive } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[drawArrow]',
})
export class DrawArrowDirective {
    /**
     * @description This method used to draw curved arrow from one element to another element
     * 04-11-2020
     * @author spalanivelu
     * curvedArrow()
     * @param options
     */
    curvedArrow(options?: any): void {
        const settings = {
            p0x: options.p0x || 50,
            p0y: options.p0y || 50,
            p1x: options.p1x || 70,
            p1y: options.p1y || 10,
            p2x: options.p2x || 100,
            p2y: options.p2y || 100,
            size: options.size || 30,
            lineWidth: options.lineWidth || 4,
            strokeStyle: '#FFC602',
            secondElement: options.secondElement,
            firsElement: options.firsElement
        };
        const canvas = document.createElement('canvas');
        const xminmax = this.quadraticCurveMinMax(settings.p0x, settings.p1x, settings.p2x);
        const yminmax = this.quadraticCurveMinMax(settings.p0y, settings.p1y, settings.p2y);
        const padding = settings.size - settings.lineWidth;

        const xmin = xminmax[0] - padding;
        const xmax = xminmax[1] + padding;
        const ymin = yminmax[0] - padding;
        const ymax = yminmax[1] + padding;

        const p0x = settings.p0x - xmin;
        const p0y = settings.p0y - ymin;
        const p1x = settings.p1x - xmin;
        const p1y = settings.p1y - ymin;
        const p2x = settings.p2x - xmin;
        const p2y = settings.p2y - ymin;

        canvas.style.position = 'absolute';
        canvas.style.top = ymin + 'px';
        canvas.style.zIndex = '1041';
        canvas.style.left = xmin + 'px';
        canvas.width = xmax - xmin;
        canvas.height = ymax - ymin;
        canvas.classList.add('curved_arrow');
        const ctx = canvas.getContext('2d');
        // Styling
        ctx.strokeStyle = settings.strokeStyle;
        ctx.lineWidth = settings.lineWidth;
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';
        // Arrow body
        ctx.beginPath();
        ctx.moveTo(p0x, p0y);
        ctx.quadraticCurveTo(p1x, p1y, p2x, p2y);
        ctx.stroke();
        // Arrow head
        const angle = Math.atan2(p2y - p1y, p2x - p1x);
        ctx.translate(p2x, p2y);
        // Right side
        ctx.rotate(angle + 1);
        ctx.beginPath();
        ctx.moveTo(0, settings.size);
        ctx.lineTo(0, 0);
        ctx.stroke();
        // Left side
        ctx.rotate(-2);
        ctx.lineTo(0, -settings.size);
        ctx.stroke();
        // Restore context
        ctx.rotate(1 - angle);
        ctx.translate(-p2x, -p2y);
        document.body.appendChild(canvas);
    }

    /**
     * @date 04-11-2020
     * @author spalanivelu
     * quadraticCurveMinMax()
     * @param p0
     * @param p1
     * @param p2
     */
    quadraticCurveMinMax(p0, p1, p2): any {
        let min = p0;
        let max = p2;
        const tstep = 0.0001;
        for (let t = tstep; t <= 1; t += tstep) {
            const f = (1 - t) * (1 - t) * p0 + 2 * (1 - t) * t * p1 + (t * t * p2);
            if (f < min) {
                min = f;
            }
            if (f > max) {
                max = f;
            }
        }
        return [Math.round(min), Math.round(max)];
    }
}
