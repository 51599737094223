import { environment } from '@env/environment';

export class AppsConstants {

  public static REPORTS_PLAYSTORE_URL = environment.flp360ReportsPlayStoreLink;
  public static TOOLS_APPSTORE_URL = environment.flp360ToolsAppStoreLink;
  public static TOOLS_PLAYSTORE_URL = environment.flp360ToolsPlayStoreLink;
  public static PWA_APP_URL = environment.pwaUrl;
  public static TOOLS_APP_IMG_URL = environment.cdnURL + '/portals/flp360/template1/images/FLP360_App_Icons_Tools.png';
  public static REPORTS_APP_IMG_URL = environment.cdnURL + '/portals/flp360/template1/images/FLP360_App_Icons_Reports.png';
  public static APPSTORE_IMG_URL = environment.cdnURL + '/portals/flp360/template1/images/appstore.png';
  public static PLAYSTORE_IMG_URL = environment.cdnURL + '/portals/flp360/template1/images/googlepay.png';
  public static DEFAULT_PROFILE_IMAGE = environment.cdnURL + '/portals/flp360/template1/images/nobody_m.original.jpg';
  public static UNKNOWN = 'unknown';
  public static ANDRIOD = 'Andriod';
  public static IOS = 'iOS';
  public static WINDOWS = 'Windows';
  public static FIRSTNAME = 'firstName';
  public static LASTNAME = 'lastName';
  public static EMAIL = 'email';
  public static HOME_COUNTRY = 'homeCountry';
  public static FBOLEVEL = 'fboLevel';
  public static APPS_APPLE = 'apps.apple.com';
  public static ITUNES_APPLE = 'itunes.apple.com';
  public static TITLE = 'FLP360';
  public static PROD_ENV = 'PROD';
}
