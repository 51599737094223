import { Component, Input, OnInit } from '@angular/core';
import { ImageConstants } from '@core/constants/images.constant';
import { environment } from '@env/environment';

@Component({
    selector: 'app-background',
    templateUrl: '../templates/template1/views/app-background.component.html',
    styleUrls: [
        '../templates/template1/themes/app-background.component.less'
    ],
    providers: []
})

export class BackgroundComponent implements OnInit {
    baseUrl = environment.uiTemplateBaseUrl;
    backgroundImage = ImageConstants.BACKGROUND_IMAGE;
    @Input() backgroundColor;
    constructor() { }
    ngOnInit(): void {
    }
}
