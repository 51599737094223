export class RoutingConstants {
  public static DASHBOARD_URL = '/dashboard';
  public static DASHBOARD = 'dashboard';

  /** Social Sales Constants Start */
  public static SOCIAL_SALES_URL = '/tools/';
  public static HELP = '/help/'
  public static ACCOUNT_SOCIAL_SALES_URL = '/account/';
  public static ACTIVITY_FEED_URL = 'activityfeed';
  public static CONTACTS_URL = 'contacts';
  public static MEDIA_LIBRARY_URL = 'medialibrary';
  public static LINK_BUILDER_URL = 'linkbuilder';
  public static SOCIAL_PAGES_URL = 'socialpages';
  public static CALL_TO_ACTION_URL = 'call-to-action';
  public static ONLINE_TRACKING_URL = 'online-tracking-settings';

  public static CONTENT_CHANNEL_URL = 'content-channel';
  public static CONTENT_CHANNEL_LINK = 'myaccountsubscriptions';

  public static CONTENT_STREAM_URL = 'contentstream';
  public static SOCIALSALES_CONTENT_STREAM_LINK = 'Social/content/stream';

  public static SOCIALSALES_ACCOUNT_WEBSITE_URL = 'my-website-settings';
  public static SOCIALSALES_ACCOUNT_WEBSITE_LINK = 'myaccountwebsite';

  public static SOCIAL_QUEUE_URL = 'socialqueue';
  public static SOCIAL_QUEUE_LINK = 'Social/scheduler/socialqueue'
  /** Social Sales Constants End */

  public static VOLUME_URL = '/reports/volume';
  public static ORDERS_URL = '/reports/orders';
  public static EARNINGS_URL = '/reports/earnings';
  public static INCENTIVES_URL = '/reports/incentives';
  public static FOCUS_GROUP_URL = '/reports/focus-group';
  public static RETENTION_URL = '/reports/retention';
  public static RECRUITMENT_URL = '/reports/recruitment';
  public static PRODUCTIVITY_URL = '/reports/productivity';
  public static DEVELOPMENT_URL = '/reports/development';
  public static RETAIL_CUSTOMER_URL = '/reports/retail-customers';
  public static EAGLE_MANAGER_URL = '/reports/eagle-manager';
  public static DOWNLINE_COUNT_BY_LEVEL_URL = '/reports/downline-count-by-level';
  public static ADVANCED_REPORTS_URL = '/reports/advanced-reports';
  public static DOWNLINE_URL = '/reports/downline';
  public static DOWNLINE_MANAGERS_URL = '/reports/downline-managers';
  public static ADVANCED_REPORTS = 'advanced-reports';
  public static REPORTERIZER_WORKFLOW = 'reporterizer-workflow';
  public static REPORTERIZER_WORKFLOW_URL = '/reports/reporterizer-workflow';
  public static CHAIRMAN_BONUS_CC_SUMMARY_BY_MONTH = '/reports/chairman-bonus-cc-summary-by-month';
  public static EARNINGS_CC_SUMMARY_URL = '/reports/cc-summary';
  public static EARNINGS_25CC_MANAGERS_URL = '/reports/earnings-recap-25cc-active-managers';
  public static ORDER = 'order';
  public static VOLUME = 'volume';
  public static EARNINGS = 'earnings';
  public static INCENTIVES = 'incentives';
  public static FOCUS_GROUP = 'focusGroup';
  public static RETENTION = 'retention';
  public static RECURITMENTS = 'recruitments';
  public static PRODUCTIVITY = 'productivity';
  public static DEVELOPMENT = 'development';
  public static RETAIL_CUSTOMERS = 'retailCustomer';
  public static DOWNLINE = 'downline';
  public static DOWNLINE_MANAGERS = 'downlineManagers';
  public static SOCIAL_QUEUE = 'socialQueue';
  public static MEDIA_LIBRARY = 'mediaLibrary';
  public static CONTENT_CURATOR = 'contentCurator';
  public static CALL_ACTION = 'callAction';
  public static LANDING_PAGE = 'landingPage';
  public static DIGITAL_MARKETING = 'digitalMarketing';
  public static DIGITAL_MARKETING_LINK = 'files/FLP_GuidetoDigitalMarketing 2019_SinglePage.pdf';
  public static CC_BREAKDOWN = 'CC Breakdown';
  public static CC_ACTIVE = '4CC Active';
  public static ORDERS = 'Orders';
  public static EARNING_TREND = 'Earning Trend';
  public static EAGLE_MANAGER = 'Eagle Manager';
  public static GLOBAL_RALLY = 'Global Rally';
  public static SOCIAL_SALES_RETURN_URL = '&ReturnUrl=/app/';
  public static CONTACTS = 'contacts';
  public static ACTIVITY_FEED = 'activityfeed';
  public static MEDIA_LIBRARYS = 'medialibrary';
  public static LINK_BUILDER = 'linkbuilder';
  public static SOCIAL_PAGES = 'socialpages';
  public static CALL_TO_ACTION = 'call-to-action';
  public static SOCIAL_QUEUES = 'socialqueue';
  public static VOLUME_LINK = 'Volume';
  public static ORDERS_LINK = 'Orders';
  public static RETAILCUSTOMERS_LINK = 'Retail Customers';
  public static SOCIALSALES_ACTIVITYFEED = 'Activity feed from SS';
  public static SUBSCRIPTION_AND_BILLING = 'subscriptionAndBilling';
  public static PERSONAL_INFORMATION = 'personalInformation';
  public static LOCATIONS_AND_LANGUAGES = 'locationsAndLanguages';
  public static SUBSCRIPTION_AND_BILLING_URL = 'account/subscription-and-billing';
  public static PERSONAL_INFORMATION_URL = 'account/personal-information';
  public static LOCATIONS_AND_LANGUAGES_URL = 'account/location-and-language';
  public static TRACKING_SETTINGS_URL = 'account/online-tracking-settings';
  public static PDF_GUIDES = 'pdfGuides';
  public static VIDEO_TUTORIALS = 'videoTutorials';
  public static RESOURCES = 'resources'
  public static FAQ = 'faq';
  public static CONTACT_US_URL = 'help/support-request'
  public static PDF_GUIDES_URL = 'help/pdf-guides';
  public static VIDEO_TUTORIALS_URL = 'help/resources';
  public static FAQ_URL = 'help/faq';
  public static ENROLLMENT = '/enrollment';
  public static ENROLLMENT_REPORT = '/enrollment/reports';
  public static ENROLLMENT_TOOLS = '/enrollment/tools';
  public static COMING_SOON = '/coming-soon/';
  public static PERSONAL_INFO = '/enrollment/personalinfo';
  public static ENROLLMENT_MAINTENANCE = '/enrollment/maintenance';
  public static FOUR_CORE_SUMMARY_URL = '/reports/four-core-summary';
  public static FOUR_CORE_LEVEL_MOVE_UP_URL = '/reports/four-core-level-move-up';
  public static FOUR_CORE_PRODUCTIVITY_URL = '/reports/four-core-productivity';
  public static FOUR_CORE_RECRUITMENT_URL = '/reports/four-core-recruitment';
  public static FOUR_CORE_RETENTION_URL = '/reports/four-core-retention';
  public static RESOURCE_LIBRARY_URL='social/content/medialibrarycategorized';
  public static MARKETING_TOOLS_URL='social/content/medialibrary/category';
  public static BUSINESS_TRAINING_URL='businesstraining';
  public static PRODUCT_TRAINING_URL='producttraining'
  /*REPORTS ROUTING CONSTANTS - SIDE NAV */
  public static VOLUME_NAV = 'volume';
  public static ORDERS_NAV = 'orders';
  public static EARNINGS_NAV = 'earnings';
  public static INCENTIVES_NAV = 'incentives';
  public static FOCUS_GROUP_NAV = 'focus-group';
  public static DOWNLINE_NAV = 'downline';
  public static RETAIL_CUSTOMERS_NAV = 'retail-customers';
  public static RECURITMENT_NAV = 'recruitment';
  public static RETENTION_NAV = 'retention';
  public static PRODUCTIVITY_NAV = 'productivity';
  public static DEVELOPMENT_NAV = 'development';

  /*ACCOUNTS ROUTING CONSTANTS - SIDE NAV */

  public static SUBSCRIPTION_BILLING_NAV = 'subscription-and-billing';
  public static PERSONAL_INFO_NAV = 'personal-information';
  public static LOCATION_AND_LANGUAGES_NAV = 'location-and-language';
  public static APPS_PAGE_URL = '/apps/FLP360';

  /*HELP ROUTING CONSTANTS - SIDE NAV */

  //public static SUPPORT_REQUEST_NAV = 'support-request';
  public static PDF_GUIDES_NAV = 'pdf-guides';
  public static CONTACT_US_NAV = 'support-request';
  public static VIDEO_TUTORIALS_NAV = 'video-tutorials';
  public static FAQ_NAV = 'faq';
  public static CONTACT_US = 'contactUs';

  /* FOUR CORE ROUTING CONSTANTS*/
  public static FOUR_CORE_SUMMARY = 'four-core-summary';
  public static FOUR_CORE_RECRUITMENT = 'four-core-recruitment';
  public static FOUR_CORE_RETENTION = 'four-core-retention';
  public static FOUR_CORE_PRODUCTIVITY = 'four-core-productivity';
  public static FOUR_CORE_DEVELOPMENT= 'four-core-level-move-up';

  /*ADVANCED REPORTS ROUTING CONSTANTS*/

  public static REPORTS = 'reports';
  public static TOP_MANAGERS = 'top-managers';
  public static FOURCC_CONSECUTIVE_MONTH = 'four-cc-consecutive-month';
  public static HISTORICAL_CC_BY_COUNTRY = 'historical-cc-by-country';
  public static REPORTERIZER = 'reporterizer';
  public static TOP_BUSINESS_BUILDERS = 'top-business-builders';
  public static TOP_NETWORKER = 'top-networker';
  public static TOP_OPEN = 'top-open';
  public static TOP_PERSONAL_CC = 'top-personal-cc';
  public static EAGLE_MANAGER_NEWSUPERVISORS = 'new-supervisors';
  public static CHAIRMAN_BONUS_MANAGER_LINES = 'chairman-bonus-lines';
  public static CHAIRMAN_BONUS_CC_SUMMARY_BY_COUNTRY = 'chairman-bonus-cc-summary-by-country';
  public static CHAIRMAN_BONUS_600CC_MANAGER_LINES_REPORT = 'chairman-bonus-600cc-manager-lines';
  public static CHAIRMAN_BONUS_QUALIFIERS_REPORT = 'chairman-bonus-qualifiers';
  public static CB_DOWNLINE_MANAGER_STATUS_REPORT = 'chairman-bonus-downline-manager-status';
  public static ACCESSIBILITY_URL = 'accessibility/accessibility-statement';
  public static ADDITIONAL_REPORT = 'additional-report';
  public static ADDITIONAL_REPORT_URL = '/reports/downline-count-by-level';
  public static ADDITIONAL_REPORT_PATH = 'downline-count-by-level';
  public static EARNINGS_RECAP_25CC_MANAGERS = 'earnings-recap-25cc-active-managers';
  public static CCSummary = 'cc-summary';
  public static EM_DOWNLINE_STATUS_REPORT = 'eagle-manager-downline-manager-status';
  public static EARNINGS_CC_SUMMARY =  'cc-summary';
  public static EARNINGS_25CC_MANAGERS = 'earnings-recap-25cc-active-managers';
  public static EARNINGS_1ST_GEN_MANAGERS = 'earnings-1st-gen-managers';
  public static EARNINGS_DOWNLINE_FBO = 'earnings-downline-fbo';
  public static EARNINGS_BONUS_TYPE = 'earnings-by-bonus-type';
  public static EARNINGS_DETAILS = 'earnings-details';
  public static CC_RECEIVED_DETAILS = 'cc-received-details';
  public static TOP_PERFORMERS = 'top-performers';
  public static ALL_ADVANCED_REPORTS = [RoutingConstants.COMING_SOON, RoutingConstants.TOP_MANAGERS,RoutingConstants.FOURCC_CONSECUTIVE_MONTH,
    RoutingConstants.HISTORICAL_CC_BY_COUNTRY, RoutingConstants.REPORTERIZER, RoutingConstants.TOP_BUSINESS_BUILDERS, 
    RoutingConstants.TOP_NETWORKER, RoutingConstants.TOP_OPEN, RoutingConstants.TOP_PERSONAL_CC, RoutingConstants.EAGLE_MANAGER_URL, 
    RoutingConstants.DOWNLINE_COUNT_BY_LEVEL_URL, RoutingConstants.EAGLE_MANAGER_NEWSUPERVISORS, RoutingConstants.CHAIRMAN_BONUS_MANAGER_LINES,
    RoutingConstants.CHAIRMAN_BONUS_CC_SUMMARY_BY_MONTH, RoutingConstants.CHAIRMAN_BONUS_CC_SUMMARY_BY_COUNTRY,
    RoutingConstants.CHAIRMAN_BONUS_600CC_MANAGER_LINES_REPORT, RoutingConstants.CHAIRMAN_BONUS_QUALIFIERS_REPORT, RoutingConstants.REPORTERIZER_WORKFLOW, 
    RoutingConstants.CB_DOWNLINE_MANAGER_STATUS_REPORT, RoutingConstants.EARNINGS_RECAP_25CC_MANAGERS,
    RoutingConstants.CHAIRMAN_BONUS_600CC_MANAGER_LINES_REPORT, RoutingConstants.CHAIRMAN_BONUS_QUALIFIERS_REPORT, RoutingConstants.REPORTERIZER_WORKFLOW, RoutingConstants.CB_DOWNLINE_MANAGER_STATUS_REPORT,
    RoutingConstants.CCSummary, RoutingConstants.EARNINGS_25CC_MANAGERS, RoutingConstants.EARNINGS_1ST_GEN_MANAGERS,RoutingConstants.EARNINGS_DOWNLINE_FBO, RoutingConstants.EARNINGS_BONUS_TYPE,RoutingConstants.EARNINGS_DETAILS,
    RoutingConstants.FOUR_CORE_PRODUCTIVITY_URL, RoutingConstants.FOUR_CORE_RECRUITMENT_URL, RoutingConstants.FOUR_CORE_RETENTION_URL,RoutingConstants.CC_RECEIVED_DETAILS,RoutingConstants.TOP_PERFORMERS];
    public static NO_BACKGROUND_REPORTS = [RoutingConstants.EARNINGS_RECAP_25CC_MANAGERS, RoutingConstants.EARNINGS_1ST_GEN_MANAGERS,
    RoutingConstants.EARNINGS_DOWNLINE_FBO, RoutingConstants.EARNINGS, RoutingConstants.EARNINGS_BONUS_TYPE, RoutingConstants.EARNINGS_DETAILS];
  
    /** Subscription Cancellation Constants */
    public static PUT_MY_ACCOUNT_ON_HOLD = 'putonHold';
    public static KEEP_FLP360 = 'keepAccount';
    public static REACTIVATE_ACCOUNT='reactivateAccount';
    public static CANCELLED= 'Cancelled';
    public static SUSPENDED='Suspended';
    public static PAUSED='paused';
    public static ACTIVE='Active';
    public static REACTIVATED = 'Reactivated'
    public static REACTIVATE ='Reactivate';
    public static RESUME= 'Resume';
    public static NO='no';
    public static COMPLICATED="COMPLICATED";
    public static TOOLS_NOT_USEFUL="TOOLS_NOT_USEFUL";
    public static REPORTS_NOT_USEFUL="REPORTS_NOT_USEFUL";
    public static NOVALUE="NOVALUE";
    public static NOT_WORKING_CORRECTLY="NOT_WORKING_CORRECTLY";
    public static NO_REASON=225;
    public static IN_TRIAL="IN_TRIAL";

}
