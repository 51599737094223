export class ImageConstants {
  public static BACKGROUND_IMAGE = 'background-image-reports.png';
  public static FOOTER_IMAGE = 'FLP360-footer-logo-new.png';
  public static DESKTOP_HEADER_IMAGE = 'FLP360_header.jpg';
  public static DESKTOP_HEADER_LOGO = 'FLP360_logo.png';
  public static MOBILE_HEADER_IMAGE = 'FLP_Header_Mobile.jpg';
  public static MOBILE_HEADER_LOGO = 'FLP360_logo.png';
  public static TOOLS_ICON = 'FLP360_Tools_Icon.png';
  public static NEW_TOOLS_ICON = 'FLP360_New_Tools_Icon.png';
  public static APP_STORE_ICON = 'appstore.png';
  public static PLAY_STORE_ICON = 'googlepay.png';
  public static REPORTS_ICON = 'FLP360_Reports_Icon.png';
  public static SPINNER_IMAGE = 'spinner_image.gif';
  public static ADA_LINK = '/usa/en-us/accessibility-statement';
  public static PRIVACY_LINK = '/content/MarketingConfigurableContent/GBL/Privacy Notices/2023-01-01 - Privacy Notice - FNL (1).pdf';
  public static IMAGE_PATH = 'portals/flp360/template1/images';
  public static LOGO_PATH = 'flpi/logo';
  public static FOREVER_LIVING_LOGO = 'foreverliving-logo.svg';
  public static ENROLLMENT_LOGO = 'logo-enrollment.png';
  public static INSIGHT_ENROLLMENT_LOGO = 'insight-enrollment.png';
  public static STRATEGY_ENROLLMENT_LOGO = 'strategy-enrollment.png';
  public static GROWTH_ENROLLMENT_LOGO = 'growth-enrollment.png';
  public static EASTOREAD_ENROLLMENT_LOGO = 'easytoread-enrollment.png';
  public static REPORTS_ENROLLMENT = 'reports-enrollment.png';
  public static SOCIALMEDIA_ENROLLMENT = 'socialmedia-enrollment.png';
  public static OWNLANDING_ENROLLMENT = 'ownlandingpage-enrollment.png';
  public static MONTHLY_ENROLLMENT = '$20.00';
  public static YEARLY_ENROLLMENT = '$18.30';
  public static FLP_CARD1 = 'flp-card-1.png';
  public static FLP_CARD2 = 'flp-card-2.png';
  public static FLP_CARD3 = 'flp-card-3.png';
  public static FLP_CARD4 = 'flp-card-4.png';
  public static FLP_CARD5 = 'flp-card-5.png';
  public static FLP_CARD6 = 'flp-card-6.png';
  public static NEW_FLP_CARD1 = 'new-flp-card-4.png';
  public static NEW_FLP_CARD2 = 'new-flp-card-2.png';
  public static NEW_FLP_CARD3 = 'new-flp-card-3.png';
  public static EXCEL_IMAGE = 'excel-import-icon.png';
  public static PDF_IMAGE = 'pdf-download-icon.png';
  public static LIST_IMAGE = 'legend-icon.png';
  public static UPLOAD_IMAGE = 'upload-icon.png';
  public static RIGHT_ARROW = 'arrow-right.png';
  public static DOWN_ARROW = 'arrow-down.png';
  public static DASHBOARD_IMAGE = 'my-dashboard.png';
  public static REPORTS_IMAGE = 'reports.png';
  public static TOOLS_IMAGE = 'tools.png';
  public static APPS_IMAGE = 'apps.png';
  public static ACCOUNT_IMAGE = 'my-account.png';
  public static HELP_IMAGE = 'help.png';
  public static LOGOUT_IMAGE = 'logout.png';
  public static INFORMATION_ICON_IMAGE = 'info_header_icon.png';
  public static INFO_ICON_IMAGE = 'info_icon.png';
  public static PWA_ICON = 'pwa_icon.png';
  public static TRAINING_TOOLS='graduation-cap.png';
}
