export class TooltipTrainingConstants {
    public static TOOLTIP_INFO = 'tooltipInfo';
    public static TOOLTIP_TITLE = 'title';
    public static TOOLTIP_DETAIL = 'detail';
    public static TOOLTIP_PURPOSE = 'purpose';
    public static TOOLTIP_PURPOSE_DESCRIPTION = 'purpose-description';
    public static TOOLTIP_GRAPH_DESCRIPTION = 'graph-description'
    public static TOOLTIP_PROCEDURE = 'procedure';
    public static TOOLTIP_VIDEOURL = 'videoUrl';
    public static TOOLTIP_BENEFICIARY = 'beneficiary';
    public static DOWNLINE_GRID = 'downline-grid';
    public static DOWNLINE_EXPLORER = 'downline-explorer';
    public static VOLUME_YEARLYCC = 'volume-yearlycc';
    public static DEVELOPMENT = 'development';
    public static DEVELOPMENT_FOUR_CORE_HEALTH_CHECK = 'development-four-core-health-check';
    public static RECRUITMENT_FOUR_CORE_HEALTH_CHECK = 'recruitment-four-core-health-check';
    public static RETENTION_FOUR_CORE_HEALTH_CHECK = 'retention-four-core-health-check';
    public static RETENTION_TOTAL_PURCHASING_FBOS = 'retention-total-purchasing-fbos';
    public static RETENTION ='retention';
    public static ORDERS = 'order';
    public static PRODUCTIVITY_FOUR_CORE_HEALTH_CHECK = 'productivity-four-core-health-check';
    public static TOOLTIP_GRAPH = 'graph';
    public static TOOLTIP_RULES = 'rule';
    public static LINK_EXISTS = {
        volumeyearlycc : {
            purpose : [false, false, true]
        },
        incentiveglobalrally : {
            rule : [false, true]
        },
        incentiveeaglemanager : {
            rule : [false, true]
        },
        incentiveleadership : {
            rule : [true]
        },
        incentiveforever : {
            rule : [false, true]
        },
        incentivechairman : {
            rule : [false, true] 
        },
        incentivegem : {
            rule : [false, true] 
        }
    };
    public static REPORT_HAVING_LINK = ['volume-yearlycc', 'incentive-globalrally', 'incentive-eaglemanager',
         'incentive-leadership', 'incentive-forever', 'incentive-chairman', 'incentive-gem'];
    public static RECRUITMENT_NEW_FBOs = 'recruitment-new-fbos';
    public static RECRUITMENT_GENERATION = 'recruitment-generation';
    public static GLOBAL_RALLY = 'incentive-globalrally';
    public static EAGLE_MANAGER = 'incentive-eaglemanager';
    public static LEADERSHIP_BONUS = 'incentive-leadership';
    public static FOREVER_DRIVE = 'incentive-forever';
    public static GEM_BONUS = 'incentive-gem';
    public static PRODUCTIVITY = 'productivity';
    public static CHAIRMAN_BONUS = 'incentive-chairman';
    public static EARNINGS = 'earnings-report';
    public static RETAIL_CUSTOMERS = 'retail-customers';
    public static FOCUS_GROUP = 'focus-group';
}
export class TicketCreation {
    public static PRIORITY_VALUE = 'Medium';
    public static PRIORITY_ID = 22;
    public static SOURCE_VALUE = 'Online Form';
    public static TICKET_STATUS = 'New';
    public static TICKET_SOURCE = 'Online Form';
    // public static DEPARTMENT_NAME = 'United States - Smile Team 360'; // dev environment
    public static DEPARTMENT_NAME = 'Smile Team 360';
    public static TICKET_TYPE = 'FLP360 Reports In-screen Feedback';
    public static TICKET_TYPES= 'FLP360 Feedback';
    public static ASSIGN_TO_ID = 3735644;
    public static ASSIGN_TO_EMAIL = 'grawlins@sharklasers.com';
    public static ASSIGN_TO_ID_STAGE = 3735644;
    public static ASSIGN_TO_EMAIL_PROD = 'grawlins@foreverliving.com';
    public static OPERATING_COUNTRY = 'USA';
    public static STAGE = 'stage';
    public static TICKET_LINK = '/csp/ticket';
    public static TICKET_CATEGORY = 'Work';
}
export class TicketStorage {
    public static TICKET_STATUS_ID = 'tooltipTicketStatusId';
    public static TICKET_PRIPRITY_ID = 'tooltipTicketPriorityId';
    public static TICKET_SOURCE_ID = 'tooltipTicketSourceId';
    public static OPERATING_COUNTRY_ID = 'tooltipOperatingCountryId';
    public static DEPARTMENT_ID = 'tooltipDepartmentId';
    public static TICKET_TYPE_ID = 'tooltipTicketTypeId';
    public static TICKET_TYPES = 'ticketTypes';
    public static TICKET_SUB_TYPES = 'ticketSubTypes';
    public static TICKET_CATEGORY_ID = 'ticketCategory';
}
export class TicketSubTypes {
    public static DOWNLINE_GRID = 'Downline List-Report';
    public static RECRUITMENT_NEW_FBOs = 'Recruitment–New FBOs';
    public static RECRUITMENT_GENERATION = 'Recruitment–By Generation';
    public static DEVELOPMENT = 'Development';
    public static DEVELOPMENT_FOUR_CORE = 'Development–4Core';
    public static RECRUITMENT_FOUR_CORE = 'Recruitment–4Core';
    public static DOWNLINE_EXPLORER = 'Downline Explorer-Report';
    public static VOLUME_YEARLYCC = 'Volume–Yearly CC';
    public static GLOBAL_RALLY = 'Incentives–Global Rally';
    public static CHAIRMAN_BONUS = 'Incentives–Chairman’s Bonus';
    public static GEM_BONUS = 'Incentives–Gem Bonus';
    public static EAGLE_MANAGER = 'Incentives–Eagle Manager';
    public static LEADERSHIP_BONUS = 'Incentives–Leadership Bonus';
    public static FOREVER_DRIVE = 'Incentives–Forever2Drive';
    public static EARNINGS = 'Reports–Earnings';
    public static ORDERS_MYORDERS = 'Reports–Orders';
    public static PRODUTIVITY_FOUR_CORE = 'Productivity–4Core';
    public static PRODUCTIVITY = 'Productivity';
    public static RETENTION_FOUR_CORE = 'Retention–4Core';
    public static RETENTION_TOTAL_PURCHASING_FBOS = 'Retention–Total Purchasing FBOs';
    public static RETAIL_CUSTOMERS = 'Reports–Retail Customers';
    public static FLP360_FEEDBACK= 'Other Suggestion';
    public static TICKET_SUB_TYPE= 'Subscriptions';
    public static FOCUS_GROUP = 'Reports - Focus Group'
    
}

export class RowStatus {
    public static ACTIVE = 1;
    public static INACTIVE = 2;
}
