import { GuidedTourInfo } from '../interfaces/guided-tour-info.interface';

export class GuidedTourComponentSettings {
    selectedLanguage: string;
    guidedTourInfo: GuidedTourInfo[];
    selectedTourInfo: GuidedTourInfo;
    activeIndex: number;
    modalWidth = 500;
    deviceWidth: number;
    scrollToBottom = 0;
}
