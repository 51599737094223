import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layouts/components/layout.component';
import { AuthGuard, NoAuthGuard, IFrameAuthGuard } from './core';
import { ActiveUserGuard } from '@core/guards/active-user.guard';

const routes: Routes = [
  {
    path: 'verifyemail',
    loadChildren: () => import('@modules/verify-email/verify-email.module').then(m => m.VerifyEmailModule)
  },
  {
    path: 'support-ticket',
    loadChildren: () => import('@modules/support-ticket/support-ticket.module').then(m => m.SupportTicketModule)
  },
  {
    path: 'enrollment',
    loadChildren: () => import('@modules/enrollment/enrollment.module').then(m => m.EnrollmentModule)
  },
  {
    path: 'external-reports',
    canActivate: [IFrameAuthGuard],
    loadChildren: () => import('@modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'tools/:service',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('@modules/socialsales/socialsales.module').then(m => m.SocialsalesModule)
      },
      {
        path: 'reports',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('@modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'account',
        loadChildren: () => import('@modules/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'help',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('@modules/help/help.module').then(m => m.HelpModule)
      },
      {
        path: 'coming-soon/:title',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('@modules/dummy-pages/dummy-pages.module').then(m => m.DummyPagesModule)
      },
      {
        path: 'access-denied',
        loadChildren: () => import('@modules/dummy-pages/dummy-pages.module').then(m => m.DummyPagesModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('@modules/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'accessibility',
        loadChildren: () => import('@modules/dummy-pages/dummy-pages.module').then(m => m.DummyPagesModule)
      },
    ]
  },
  // Fallback when no prior routes are matched
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
