import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TDMUserDetails } from '../interfaces/tdm-user-details.interface';
import { Observable } from 'rxjs';
import { ApiUrlConstants } from '@core/constants/api.constants';
import { catchError } from 'rxjs/operators';
import { KeyCloakUserDetails } from '../interfaces/keycloak-user-details.interface';
import { ChargeBeePlanResponse } from '../interfaces/chargebee-plan-response.interface';
import { ChargebeeSubscriptionRequest } from '../interfaces/chargebee-subscription-request.interface';
import { EnrollmentConstants } from '../constants/enrollment.constants';
import { environment } from '@env/environment';
import { SocialSalesSubscriptionResponse } from '../interfaces/social-sales-subscription-response.interface';
import { CustomerDetails } from '../interfaces/customer-details.interface';
import * as crypto from 'crypto-js';
import { UserViewSettings } from '../interfaces/user-view-settings.interface';

@Injectable({
    providedIn: 'root'
  })
export class EnrollmentService {
    constructor(private httpClient: HttpClient) { }

    /**
     * returns user whether flp360 eligible or not
     *
     * @param {string} firstName
     * @param {string} lastName
     * @param {number} fboId
     * @returns
     * @memberof SignUpService
     */
    getFlp360Eligible(firstName: string, lastName: string, fboId: string, headers?: HttpHeaders): Observable<TDMUserDetails> {
        return this.httpClient.get<TDMUserDetails>(ApiUrlConstants.REPORTS_BASE + 'validateFLP360Eligible?' + 'fboId=' + fboId,{headers})
            .pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description get KeyCloak User details
     * @date 2019-11-29
     * @param {number} fboId
     * @returns {Observable<UserKeyCloakData>
     * @memberof EnrollmentService
     */
    getFlp360EnableByFboId(fboId: number): Observable<KeyCloakUserDetails> {
        return this.httpClient.get<KeyCloakUserDetails>(ApiUrlConstants.FLP360_ENABLED_TITAN + fboId + '/info');
    }

    /**
     * @description get Subscription plans
     * @date 2019-12-02
     * @param {string} country
     * @returns {Observable<any>}
     * @memberof EnrollmentService
     */
    getSubscriptionPlanByCountry(country: string): Observable<ChargeBeePlanResponse[]> {
        const headers = this.getPaymentHeaders();
        return this.httpClient.get<ChargeBeePlanResponse[]>(ApiUrlConstants.CHARGEBEE_PLANS_LIST + '/' + country, {headers})
                .pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description Enroll to Flp360
     * @date 2019-12-03
     * @param {KeyCloakUserDetails} enrollUser
     * @returns {Observable<any>}
     * @memberof EnrollmentService
     */
    enrollFLP360User(enrollUser: KeyCloakUserDetails): Observable<any> {
        return this.httpClient.post(ApiUrlConstants.FLP360_ENABLE_KEYCLOAK, enrollUser).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description Create Chargebee subscription
     * @date 2019-12-03
     * @param {ChargebeeSubscriptionRequest} chargebeeSubscriptionRequest
     * @returns {Observable<any>}
     * @memberof EnrollmentService
     */
    createChargebeeSubscription(chargebeeSubscriptionRequest: ChargebeeSubscriptionRequest): Observable<any> {
        const headers = this.getPaymentHeaders();
        return this.httpClient.post(ApiUrlConstants.CHARGEBEE_CREATE_SUBSCRIPTION, chargebeeSubscriptionRequest, { headers });
    }

    /**
     * @description
     * @date 2019-12-03
     * @param {CustomerDetails} customerDetails
     * @param {string} socialSalesAccessToken
     * @returns {Observable<SocialSalesSubscriptionResponse>}
     * @memberof EnrollmentService
     */
    createSocialSalesAccount(customerDetails: CustomerDetails)
        : Observable<SocialSalesSubscriptionResponse> {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            return this.httpClient.post<SocialSalesSubscriptionResponse>(ApiUrlConstants.SOCIAL_SALES_CREATE_SUBSCRIPTION, {
            businessKey: customerDetails.fboId,
            resellerId: environment.socialsalesProviderID,
            email: customerDetails.email,
            status: EnrollmentConstants.ACTIVE,
            startDate: new Date(),
            userInfo: {
                name: customerDetails.firstName + ' ' + customerDetails.lastName,
                given_name: customerDetails.firstName,
                family_name: customerDetails.lastName,
                locale: customerDetails.locale,
                countryCode: customerDetails.countryISOCodeThree,
                zoneinfo: timezone,
                email_verified: EnrollmentConstants.DEFAULT_EMAIL_VERIFIED_VALUE
            },
            metadata: {
                title: customerDetails.fboLevel
            }
        });
    }

    createOrUpdateFlp360MemberSubscription(customerDetails: CustomerDetails) {
        return this.httpClient.post(ApiUrlConstants.FLP360_MEMBER_SUBSCRIPTION_CREATION, {
            fboId: customerDetails.fboId,
            status: 'ACTIVE'}
            );
    }

    /**
     * createUserViewSettings()
     * @param fboId
     * @param data 
     */
    createUserViewSettings(fboId: string, data: UserViewSettings): Observable<any> {
        const postUrl = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/user-view-settings';
        return this.httpClient.post(postUrl, data).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * createUserViewSettings()
     * @param fboId
     * @param data 
     */
    updateUserViewSettings(fboId: string, data: UserViewSettings): Observable<any> {
        const putUrl = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/user-view-settings';
        return this.httpClient.put(putUrl, data).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * getUserViewSettings()
     * @param fboId
     */
    getUserViewSettings(fboId: string): Observable<UserViewSettings> {
        const getUrl = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/user-view-settings';
        return this.httpClient.get<UserViewSettings>(getUrl).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description update FLP keycloak Status
     * @date 2019-12-03
     * @param {CustomerDetails} customerDetails
     * @returns {Observable<Object>}
     * @memberof EnrollmentService
     */
    updateFlp360KeycloakStatus(customerDetails: CustomerDetails): Observable<Object> {
        return this.httpClient.patch(ApiUrlConstants.UPDATE_FLP360_KEYCLOAK_STATUS, {
          fboId: customerDetails.fboId,
          email: customerDetails.email,
          status: EnrollmentConstants.TERMINATED
        });
    }

    /**
     * To handle the obervable error response
     * @param  {Response|any} error
     */
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error.message || error);
    }

    getHashedValue(): string {
        const shavalue = crypto.HmacSHA1(atob(environment.paymentSecText), EnrollmentConstants.PAYMENT_SERVICE_KEY);
        return crypto.enc.Base64.stringify(shavalue);
    }


    getPaymentHeaders(): HttpHeaders {
        const authHeader = 'PAY ' + atob(environment.paymentGatewayToken) + ':' + this.getHashedValue();
        const headers = new HttpHeaders().set('authorization', authHeader).set('Content-Type', 'application/json');
        return headers;
    }
}
