import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SupportTicketComponent } from './components/support-ticket.component';
import { SupportTicketSuccessComponent } from './components/support-ticket-success.component';
import { SupportTicketFormComponent } from './components/support-ticket-form.component';

export const routes: Routes = [
    {
        path: '',
        component: SupportTicketComponent,
        children: [
            {
                path: '',
                component: SupportTicketFormComponent
            },
            {
              path: 'summary',
              component: SupportTicketSuccessComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SupportTicketRoutingModule { }
