import { SupportTicketComponentConstants } from './support-ticket.module.constants';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { SupportTicketComponent } from './components/support-ticket.component';
import { SupportTicketService } from './services/support-ticket.service';
import { SupportTicketRoutingModule } from './support-ticket.routing.module';

@NgModule({
    declarations: [SupportTicketComponentConstants.MODULE_COMPONENTS],
    imports: [
        SupportTicketRoutingModule,
        SharedModule
    ],
    exports: [],
    providers: [SupportTicketService],
    entryComponents: [SupportTicketComponent]
})
export class SupportTicketModule { }
