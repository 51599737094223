import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VerifyEmailResponse } from '../interface/verify-email-response.interface';
import { ApiUrlConstants } from '@core/constants/api.constants';


@Injectable()
export class AccountService {
    constructor(private httpClient: HttpClient) { }

    /**
     * @description email
     * @date 2020-01-02
     * @param {string} fboId
     * @param {string} expiryCode
     * @returns {Observable<VerifyEmailResponse>}
     * @memberof AccountService
     */
    validateUserEmailExpiryCode(fboId: string, expiryCode: string): Observable<VerifyEmailResponse> {
      // Create the base URL
    const url = new URL(ApiUrlConstants.ACCOUNT_BASE);

    // Append path segments step by step
    url.pathname += 'flp360-users/';
    url.pathname += 'verify-email/';
    url.pathname += `${encodeURIComponent(fboId)}`;

    // Create URLSearchParams and append parameters
    const params = new URLSearchParams();
    params.append('expiryCode', expiryCode);

    // Append the search parameters to the URL
    url.search = params.toString();
         return this.httpClient.get<VerifyEmailResponse>(decodeURIComponent(encodeURIComponent(url.toString())));
    }
    

    updateSocialsalesEmailId(fboId: string) {
        const url = ApiUrlConstants.ACCOUNT_BASE + 'flp360/user/' + fboId + '/update';
        return this.httpClient.patch(url, {isEmailVerified : true});
    }
}