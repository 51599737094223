<footer  (click)="toggleSideNav()" [class.slide-component]="toggleStatus" class="slide-transition slide-component">
  <div class="seperator"></div>
  <div class="container">
    <div class="row footer align-items-end py-3">
      <div class="col-lg-4 col-md-4 col-sm-4  d-flex justify-content-start text-align-center">
        <a aria-label="Forever Living" href="{{corporateURL}}">{{'footer.Forever Living.com'|translate}}</a>
        <a aria-label="Accessibility" target="_blank" href="{{adaLink}}"
          class="mx-1">{{'footer.Accessibility'|translate}}</a>
        <a aria-label="Learn more" target="_blank" href="{{privacyLink}}">{{'footer.Privacy Policy'|translate}}</a>
      </div>
      <div class="footer-image col-lg-4 col-md-4 col-sm-4 py-2">
        <img [imgSrc]=footerImage [takeFromLocal]="true" alt="forever_logo" width="100%" height="100%">
      </div>
      <div class="footer-copyright col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center">
        {{'footer.Copyright'|translate}} {{currentYear}} {{'footer.Forever Living.com, L.L.C. All rights reserved'|translate}}
      </div>
    </div>

  </div>

</footer>
