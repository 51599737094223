import { VerifyEmailComponent } from './components/verify-email.component';
import { AccountService } from './services/account.service';
import { VerifyEmailComponentConstants } from './verify-email-module.constants';
import { VerifyEmailRoutingModule } from './verify-email.routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: VerifyEmailComponentConstants.MODULE_COMPONENTS,
    imports: [
        VerifyEmailRoutingModule,
        SharedModule
    ],
    exports: [],
    providers: [AccountService],
    entryComponents: [VerifyEmailComponent]
})
export class VerifyEmailModule { }