export class CookieService {

  public static deleteCookie(cname: string) {
    const d = new Date();
    d.setTime(d.getTime() + (0 * 60 * 1000));
    const expires = d.toUTCString();
    let domainName = window.location.hostname;
    domainName = domainName.substring(domainName.indexOf('.') + 1);
    document.cookie = cname + '=' + ';domain=' + domainName + ';path=/;expires=' + expires;
  }

  public static getCookieValue(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  public static setCookie(name, value, timeInDays, path, domain) {
    let expires = '';
    if (!path) {
      path = '/';
    }
    if (!domain) {
      domain = window.location.hostname;
    }
    if (timeInDays) {
      const date = new Date();
      date.setTime(date.getTime() + (timeInDays * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + ';domain=' + domain + ';path=' + path;
  }
}
