<div class="modal fade top-25" bsModal #guidedTourPopupModal="bs-modal" tabindex="-1" role="dialog" (keyup)="removeArrows()"
  aria-labelledby="dialog-static-name" [config]="{ show: true, backdrop: 'static' }"
  *ngIf="gTComponentSettings?.guidedTourInfo?.length">
  <div class="modal-dialog modal-md" [ngClass] = "gTComponentSettings.selectedTourInfo.isVideoAvailable  ? 'adjustedpopup': ''" [style.width.px]="gTComponentSettings.modalWidth">
    <div class="modal-content" [id]="gTComponentSettings.selectedTourInfo?.startElementId" [style.width.px]="gTComponentSettings.modalWidth">
      <div class="modal-body">
        <!-- <div class="pb-3">
          <div class="tour-modal__title">{{gTComponentSettings.selectedTourInfo?.title}}</div>
          <button type="button" class="close pull-right p-0 m-0" aria-label="Close" (click)="closeTourPopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="gTComponentSettings.selectedTourInfo?.description">
          <pre class="tour-modal__description">{{gTComponentSettings.selectedTourInfo?.description}}</pre>
        </div> -->

        <div class="pb-3">
          <div class="tour-modal__title">{{gTComponentSettings.selectedTourInfo?.title}}</div>
          <button type="button" class="close pull-right p-0 m-0" aria-label="Close" (click)="closeTourPopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="gTComponentSettings.selectedTourInfo?.description">
          <pre class="tour-modal__description">{{gTComponentSettings.selectedTourInfo?.description}}</pre>
        </div>

        <!-- Video Section -->
        <div *ngIf="gTComponentSettings.selectedTourInfo && gTComponentSettings.selectedTourInfo.safeVideoUrl"
          class="video-section px-3 pb-2 pt-0">
          <div class="container-width text-center">
            <iframe width="100%" height="200px" [src]="gTComponentSettings.selectedTourInfo.safeVideoUrl"
              frameBorder="0" allow="autoplay; fullscreen" allowfullscreen title=""></iframe>
          </div>
        </div>

        <!-- <div *ngIf="gTComponentSettings.selectedTourInfo.imageCTA" class="video-section px-3 pb-2 pt-0 text-center ">
            <img class="pointer" alt="" [imgSrc]=gTComponentSettings.selectedTourInfo.imageCTA width="400" height="200" (click)="openPDFLink()" />
          </div> -->
        <div class="col-md-12 px-0 py-2 m-0 row">
          <div class="col-md-3 px-0 tour-step__count">{{'common.Step'|translate | translateKey}}
            {{gTComponentSettings.activeIndex+1}} {{'common.of'|translate | translateKey}}
            {{gTComponentSettings.guidedTourInfo?.length}}</div>
          <div class="col-md-9 px-0 text-right">
            <button type="button" class="btn btn-default mr-3" *ngIf="gTComponentSettings.activeIndex !== 0"
              (click)="getPreviousTourInfo()">{{'common.Previous'|translate | translateKey | uppercase}}</button>
            <button type="button" class="btn btn-primary" (click)="getNextTourInfo()">
              <span
                *ngIf="gTComponentSettings.guidedTourInfo?.length !== gTComponentSettings.activeIndex+1">{{'common.Next'|translate | translateKey | uppercase}}</span>
              <span
                *ngIf="gTComponentSettings.guidedTourInfo?.length === gTComponentSettings.activeIndex+1 && selectedTour === 'overallTour'">{{'common.Ready to Get Started?'|translate | translateKey | uppercase}}</span>
              <span
                *ngIf="gTComponentSettings.guidedTourInfo?.length === gTComponentSettings.activeIndex+1 && selectedTour !== 'overallTour'">{{"common.Let's Get Started!"|translate | translateKey | uppercase}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>