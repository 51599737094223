import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable()
export class MessageService {
  private subject = new Subject<any>();
  private introspectStatusSubject = new Subject<any>();
  private selectedDownlineDetail = new Subject<any>();
  private nonTitanUserTitleSubject = new Subject<any>();
  private hideMenuStatusSubject = new Subject<any>();
  private closeReportsMenuStatusSubject = new Subject<any>();
  private closeToolsMenuStatusSubject = new Subject<any>();
  private homeCountryCode = new Subject<any>();
  private routedSocialsalesUrl = new Subject<any>();
  private downlineRequestStatus = new Subject<any>();
  private languageOrientaionSubject = new Subject<any>();
  private selectedLanguage = new Subject<any>();
  private downlineId = new Subject<any>();
  private navbarToggleStatus = new Subject<any>();
  private isDownlineAvailable = new Subject<any>();
  private uplineData = new Subject<any>();
  private showUplineTable = new Subject<any>();
  private uplineDetail = new Subject<any>();
  private mobileUplineResponse = new Subject<any>();
  private cardDropStatus  = new Subject<boolean>();
  private navbarSubmenuStatus = new Subject<boolean>();
  private sideNavOpen = new Subject<boolean>();
  private routedPath = new Subject<boolean>();
  private isSupportFormFromHelp = new Subject<boolean>();
  private isBackgroundImageShown = new Subject<boolean>();
  private isIfContentLoaded = new Subject<string>();
  private isshowReportsSubMenu = new Subject<boolean>();
  private isshowAccountSubMenu = new Subject<boolean>();
  private iscloseAllmenu= new Subject<boolean>();
  private isMemberSuspended= new Subject<boolean>();
  private isTicketCreated= new Subject<boolean>();
  constructor() { }

  setIsIfContentLoaded(message: string) {
    this.isIfContentLoaded.next(message);
   }
 
   getIsIfContentLoaded() {
     return this.isIfContentLoaded.asObservable();
    }

  sendIsBackgroundImageShown(message: boolean) {
    this.isBackgroundImageShown.next(message);
  }
  getIsBackgroundImageShown(): Observable<boolean> {
    return this.isBackgroundImageShown.asObservable();
  }

  sendIsSupportFormFromHelp(message: boolean) {
    this.isSupportFormFromHelp.next(message);
  }
  getIsSupportFormFromHelp(): Observable<boolean> {
    return this.isSupportFormFromHelp.asObservable();
  }
  sendRoutedPath(message: boolean) {
    this.routedPath.next(message);
  }
  getRoutedPath(): Observable<boolean> {
    return this.routedPath.asObservable();
  }
  sendNavbarSubmenuStatus(message: boolean) {
    this.navbarSubmenuStatus.next(message);
  }

  getNavbarSubmenuStatus(): Observable<boolean> {
    return this.navbarSubmenuStatus.asObservable();
  }
  sendCardDropStatus(message: boolean) {
    this.cardDropStatus.next(message);
  }

  getCardDropStatus(): Observable<boolean> {
    return this.cardDropStatus.asObservable();
  }
  sendMobileUplineResponse(uplineResponse: any) {
    this.mobileUplineResponse.next(uplineResponse);
  }
  getMobileUplineResponse(): Observable<any> {
  return this.mobileUplineResponse.asObservable();
  }
  sendUplineDetails(uplineDetail: any) {
    this.uplineDetail.next(uplineDetail);
  }
  clearUplineDetails(){
    this.uplineDetail.next();
  }
  getUplineDetails(): Observable<any> {
    return this.uplineDetail.asObservable();
  }
  sendUplineTableStatus(showTable: boolean) {
    this.showUplineTable.next(showTable);
  }

  getUplineTableStatus(): Observable<any> {
    return this.showUplineTable.asObservable();
  }
  sendUplineData(uplineData: any) {
    this.uplineData.next(uplineData);
  }
  getUplineData(): Observable<any> {
    return this.uplineData.asObservable();
  }
  sendDownlineAvailableStatus(message: boolean) {
    this.isDownlineAvailable.next(message);
  }
  getDownlineAvailableStatus(): Observable<boolean> {
    return this.isDownlineAvailable.asObservable();
  }
  sendNavbarToggleStatus(message: boolean) {
    this.navbarToggleStatus.next(message);
  }
  getNavbarToggleStatus(): Observable<boolean> {
    return this.navbarToggleStatus.asObservable();
  }
  sendDownlineData(message: any) {
    this.downlineId.next(message);
  }
  getDownlineData(): Observable<any> {
    return this.downlineId.asObservable();
  }
  sendToggleStatus(message: any) {
    this.subject.next({ text: message });
  }

  getToggleStatus(): Observable<any> {
    return this.subject.asObservable();
  }

  sendDownlineDetail(message: any) {
    this.selectedDownlineDetail.next(message);
  }

  getDownlineDetail(): Observable<any> {
    return this.selectedDownlineDetail.asObservable();
  }
  sendHomeCountrycode(message: any) {
    this.homeCountryCode.next(message);
  }

  getHomeCountryCode(): Observable<any> {
    return this.homeCountryCode.asObservable();
  }

  sendIntrospectStatus(introspectStatus: boolean) {
    this.introspectStatusSubject.next({ boolean: introspectStatus });
  }

  clearMessage() {
    this.subject.next();
  }

  getIntrospectStatus(): Observable<any> {
    return this.introspectStatusSubject.asObservable();
  }

  sendNonTitanUserTitle(title: string) {
    this.nonTitanUserTitleSubject.next({ text: title });
  }

  getNonTitanUserTitle(): Observable<any> {
    return this.nonTitanUserTitleSubject.asObservable();
  }

  getHideMenuStatus(): Observable<any> {
    return this.hideMenuStatusSubject.asObservable();
  }
  sendHideMenuStatus(status: number) {
    return this.hideMenuStatusSubject.next({ text: status });
  }

  getCloseReportsMenuStatus(): Observable<any> {
    return this.closeReportsMenuStatusSubject.asObservable();
  }
  sendCloseReportsMenuStatus(status: boolean) {
    return this.closeReportsMenuStatusSubject.next({ text: status });
  }

  getCloseToolsMenuStatus(): Observable<any> {
    return this.closeToolsMenuStatusSubject.asObservable();
  }
  sendCloseToolsMenuStatus(status: boolean) {
    return this.closeToolsMenuStatusSubject.next({ text: status });
  }
  getSocialSalesRoutingPage(): Observable<any> {
    return this.routedSocialsalesUrl.asObservable();
  }
  sendSocialSalesRoutingPage(routedUrl: string) {
    return this.routedSocialsalesUrl.next({ text: routedUrl });
  }
  getLanguageOrientation(): Observable<any> {
    return this.languageOrientaionSubject.asObservable();
  }
  sendLanguageOrientation(isRTL: boolean) {
    return this.languageOrientaionSubject.next({ text: isRTL });
  }
  getSelectedLanguage(): Observable<any> {
    return this.selectedLanguage.asObservable();
  }
  sendSelectedLanguage(lang: string) {
    return this.selectedLanguage.next({ text: lang });
  }
  sendDownlineServerStatus(downlineRequestStatus: boolean) {
    return this.downlineRequestStatus.next({ text: downlineRequestStatus});
  }
  getDownlineServerStatus(): Observable<any> {
    return this.downlineRequestStatus.asObservable();
  }

  /**
   * set the flag When tour link clicked, side nav will open
   * @param sideNavOpen
   */
  setSideNavOpen(sideNavOpen: boolean) {
    this.sideNavOpen.next(sideNavOpen);
  }

  /**
   * get side nav response
   * @param sideNavOpen
   */
  getSideNavOpen(): Observable<boolean> {
    return this.sideNavOpen.asObservable();
  }

  setIsshowReportsSubMenu(message: boolean) {
    this.isshowReportsSubMenu.next(message);
  }
  getIsshowReportsSubMenu(): Observable<boolean> {
    return this.isshowReportsSubMenu.asObservable();
  }
  
  setIsshowAccountSubMenu(message: boolean) {
    this.isshowAccountSubMenu.next(message);
  }
  getIsshowAccountSubMenu(): Observable<boolean> {
    return this.isshowAccountSubMenu.asObservable();
  }
  setIscloseAllmenu(message: boolean) {
    this.iscloseAllmenu.next(message);
  }
  getIscloseAllmenu(): Observable<boolean> {
    return this.iscloseAllmenu.asObservable();
  }
  
  setIsMemberSuspended(message: boolean) {
    this.isMemberSuspended.next(message);
  }
  getIsMemberSuspended(): Observable<boolean> {
    return this.isMemberSuspended.asObservable();
  }
  
  setIsTicketCreated(message: boolean) {
    this.isTicketCreated.next(message);
  }
  getIsTicketCreated(): Observable<boolean> {
    return this.isTicketCreated.asObservable();
  }
}
