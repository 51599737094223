import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Constants } from '@core/constants/sso.constants';
import { EncryptionService } from '@core/services/encryption.service';
import { SsoService } from '@core/services/sso.service';
import { Enums } from '@core/Enums/realmtype.enum';
import { LocalStorageService } from 'angular-2-local-storage';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private ssoService: SsoService, private localStorageService: LocalStorageService) { }
      intercept(req: HttpRequest<any>, next: HttpHandler) {
//         /*
//         * The verbose way:
//         // Clone the request and replace the original headers with
//         // cloned headers, updated with the authorization.
//         */
//         // Clone the request and set the new header in one step.
        const authReq = this.requestOptions(req);
        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
    
    private requestOptions(req: HttpRequest<any>) {
        if (req.url.startsWith(environment.apiUrl) && !req.url.startsWith(environment.apiUrl + '/payment/')
         && !req.url.startsWith(environment.ssoApiURL)) {
            let fboId = sessionStorage.getItem('fboID');  
            if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
                fboId = CacheKeyConstants.getDecodedDataForNumber(fboId);
              if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
                window.location.reload();
              }
            }
            const uuid = this.ssoService.getNonce();
            let inlcudeAuthorizationToken = true;
            let token = '';
            let ssoToken = JSON.parse(localStorage.getItem(Constants.SSO.ACCESS_TOKEN));
            let realmType = Enums.RealmType.FLP360;
            if (!ssoToken || (ssoToken && req.headers.has('guesttokenrequired'))) {
                ssoToken = JSON.parse(localStorage.getItem('appflp360.guestToken'));
                realmType = Enums.RealmType.TITAN_GUEST;
            }
            if (typeof ssoToken !== 'undefined' && ssoToken) {
                const header = 'Bearer ' + ssoToken + '&&' + realmType;
                inlcudeAuthorizationToken = true;
                token = this.getAuthorizationHeader(header, uuid);
            }
            let headers = new HttpHeaders({ Authorization: token, UUID: uuid });
            if((req.headers.has('guesttokenrequired') || realmType == Enums.RealmType.TITAN_GUEST ) && (environment.isEnableOfflineTokenFlow)){
                headers = headers.set('isOfflineFlow','true');
            }
            if (!req.headers.has('no-content-type')) {
              headers = headers.set('Content-Type', 'application/json');
            } else {
                req.headers.delete('no-content-type');
            }
            if (req.url.includes(environment.reportApi)) {
                headers = headers.set('cache-control', 'no-cache' );
            }
            if (inlcudeAuthorizationToken) {
                const authReq = req.clone({ headers });
                return authReq;
            }
        }
        return req;
    }

    private getAuthorizationHeader(token: string, uuid: string): string {
        const data = uuid + '||' + token;
        return EncryptionService.encryptText(data);
    }
}