<div>
    <app-header></app-header>
    <div class="site-content content-part">
        <section class="body body-content profile">
            <div class="" tabindex="-1" role="main" aria-label="main content" id="mainContent">
                <router-outlet></router-outlet>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>