import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { EnrollmentConstants } from '@modules/enrollment/constants/enrollment.constants';
@Component({
    selector: 'app-spinner',
    templateUrl: '../templates/template1/views/spinner.component.html',
    styleUrls: [
        '../templates/template1/themes/spinner.component.less'
    ],
})
export class SpinnerComponent implements OnInit, OnDestroy {
    @Input() spinnerName: string;
    showSpinner = false;
    showSignupWarningmessage = false;
    constructor(private spinnerService: SpinnerService) {
    }
    ngOnInit(): void {
        this.registerSpinner();
    }
    registerSpinner() {
        this.spinnerService.listenloader(this.spinnerName).subscribe((show: boolean) => {
        this.showSpinner = show;
        if(this.spinnerName === EnrollmentConstants.SIGNUP_SPINNER){
            this.showSignupWarningmessage =true;
        }
        });
    }

    ngOnDestroy(): void {
        this.spinnerService.unsubscribeListener(this.spinnerName);
        this.showSignupWarningmessage=false;
    }
}
