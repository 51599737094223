import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { CookieService } from 'ngx-cookie-service';
import { CacheKeyConstants } from '../app/core/constants/cachekey.constant';
import { AppTranslateService } from './core/services/app-translate.service';
import { CacheService } from './core/services/cache.service';

export function createTranslateLoader(
  http: HttpClient,
  localStorage: LocalStorageService,
  cookieService: CookieService
) {
  return new AppTranslateService(http, localStorage, cookieService);
}

const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient, LocalStorageService, CookieService]
  }
};

@NgModule({
  imports: [TranslateModule.forRoot(translationOptions)],
  exports: [TranslateModule],
  providers: [TranslateService]
})
export class AppTranslationModule {
  language: any;
  defaultlang: any;
  constructor(
    private translate: TranslateService,
    private cacheService: CacheService
  ) {
    this.defaultlang = 'en-US';
    this.language = this.cacheService.get(CacheKeyConstants.selectedLanguage);
    if (this.language) {
      this.translate.use(this.language);
    } else if (this.defaultlang) {
      this.translate.use(this.defaultlang);
    }
  }
}
