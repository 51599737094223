import * as crypto from 'crypto-browserify';
import { Constants } from '@core/constants/sso.constants';

export class EncryptionService {

  public static encryptText(plainText: string) {
    let encryptedText = '';
    const encryptionKey = Constants.SSO.ENCRYPTION_KEY;
    try {
      // random initialization vector
      const iv = crypto.randomBytes(12);

      // random salt
      const salt = crypto.randomBytes(64);

      // derive key: 32 byte key length - in assumption the masterkey is a cryptographic and NOT a password there is no need for
      // a large number of iterations. It may can replaced by HKDF
      const key = crypto.pbkdf2Sync(encryptionKey, salt, 2145, 32, 'sha512');

      // AES 256 GCM Mode
      const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);

      // encrypt the given text
      const encrypted = Buffer.concat([cipher.update(plainText, 'utf8'), cipher.final()]);

      // extract the auth tag
      const tag = cipher.getAuthTag();

      // generate output
      // let enc = Buffer.concat([salt, iv, tag, encrypted]).toString('base64')
      encryptedText = Buffer.concat([salt, iv, encrypted, tag]).toString('base64');
    } catch (exception) {
      console.log(exception);
    } finally {
      return encryptedText;
    }
  }

  public static decryptText(encryptedText: string) {
    let decryptedText = '';
    const encryptionKey = Constants.SSO.ENCRYPTION_KEY;
    try {
      // base64 decoding
      const bData = Buffer.from(encryptedText, 'base64');

      const salt = bData.slice(0, 64);
      const iv = bData.slice(64, 76);
      const index = bData.length - 16;
      const tag = bData.slice(index, bData.length);
      const text = bData.slice(76, index);

      // derive key using; 32 byte key length
      const key = crypto.pbkdf2Sync(encryptionKey, salt, 2145, 32, 'sha512');
      // AES 256 GCM Mode
      const decipher = crypto.createDecipheriv('aes-256-gcm', key, iv);
      decipher.setAuthTag(tag);

      // decrypt the given text
      decryptedText = decipher.update(text, 'binary', 'utf8') + decipher.final('utf8');
    } catch (exception) {
      console.log(exception);
    } finally {
      return decryptedText;
    }
  }
}
