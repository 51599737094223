import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileDetails, TicketDetailsResponse, TicketType } from '../interface/support-ticket-base-response';
import { ApiUrlConstants } from '@core/constants/api.constants';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';
import { RealmType } from '../enums/realm-type.enum';
import { EncryptionService } from '@core/services/encryption.service';
import { UserModel } from '../interface/user-modal.interface';
import { CountryResponse } from '../interface/country-response.interface';
import { SupportTicketRequest } from '../interface/support-ticket.interface';
import { environment } from '@env/environment.prod';


@Injectable()
export class SupportTicketService {

    constructor(private httpClient: HttpClient) { }
    headers = new HttpHeaders({ guestTokenRequired: 'true' });
    /**
     * @description save support ticket attachments
     * @date 2020-05-05
     * @param {string[]} attachments
     * @param {number} ticketId
     * @returns
     * @memberof supportTicketService
     */
    saveAttachments(attachments: FileDetails[], ticketId: number) {
        const url = ApiUrlConstants.CUSTOMER_SERVICE_API + 'tickets/' + ticketId + '/attachments';
        return this.httpClient.put(url, attachments, {'headers' : this.headers}).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description Create Support Ticket
     * @date 2020-05-05
     * @param {*} createTicketRequest
     * @returns
     * @memberof supportTicketService
     */
    createSupportTicket(createTicketRequest: any, operatingCounrty?: string): Observable<any> {
        let url;
        if(environment && environment.enableCSPV2TicketCreation && environment.cspv2LiveCountries && environment.cspv2LiveCountries.includes(operatingCounrty)) {
            url = ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'tickets';
        } else {
            url = ApiUrlConstants.CUSTOMER_SERVICE_API + 'tickets';
        }
        return this.httpClient.post<any>(url, createTicketRequest, {'headers' : this.headers}).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description get user details
     * @date 2020-05-05
     * @param {string} fboId
     * @returns
     * @memberof supportTicketService
     */
    getUserdetailsByFBOId(fboId: string,headers?: HttpHeaders): Observable<UserModel> {
        return this.httpClient.get<UserModel>(
          ApiUrlConstants.REPORTS_BASE + 'user-profile/distributorId/' + fboId,{headers}
        );
    }

    /**
     * @description get Source Type
     * @date 2020-05-05
     * @returns {Observable<SourceType[]>}
     * @memberof supportTicketService
     */
    getSourceType(): Observable<TicketDetailsResponse[]> {
        return this.httpClient.get<TicketDetailsResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API + 'source-types', {'headers' : this.headers});
    }

    /**
     * @description get Ticket Priorites
     * @date 2020-05-05
     * @returns {Observable<TicketPriorities[]>}
     * @memberof supportTicketService
     */
    getTicketPriorities(): Observable<TicketDetailsResponse[]> {
        return this.httpClient.get<TicketDetailsResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API + 'ticket-priorities', {'headers' : this.headers});
    }

    /**
     * @description get Ticket Status
     * @date 2020-05-05
     * @returns {Observable<TicketDetailsMappingResponse[]>}
     * @memberof supportTicketService
     */
    getTicketStatus(): Observable<TicketDetailsResponse[]> {
        return this.httpClient.get<TicketDetailsResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API + 'ticket-status', {'headers' : this.headers});
    }

    /**
     * @description get Ticket Types and subtypes
     * @date 2020-05-05
     * @returns {Observable<TicketType[]>}
     * @memberof supportTicketService
     */
    getTicketTypesAndSubTypes(operatingCountryId: number): Observable<TicketType[]> {
        return this.httpClient.get<TicketType[]>(ApiUrlConstants.CUSTOMER_SERVICE_API
             + 'types/sub-types?operatingCountryId=' + operatingCountryId , {'headers' : this.headers});
    }

    /**
     * @description get Dapartments
     * @date 2020-05-05
     * @returns {Observable<TicketDetailsMappingResponse[]>}
     * @memberof supportTicketService
     */
    getDepartments(operatingCountryId: number): Observable<TicketDetailsResponse[]> {
        return this.httpClient.get<TicketDetailsResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API
             + 'departments/lookup?operatingCountryId=' + operatingCountryId , {'headers' : this.headers});
    }

    /**
     * @description get File uploader url
     * @date 2020-05-05
     * @returns {FileUploader}
     * @memberof supportTicketService
     */
    getFileUploader(): FileUploader {
      let fileUploader: FileUploader;
      if (environment.enableFileUploadToPrivate) {
        fileUploader = new FileUploader({
          url: ApiUrlConstants.CMS_API_URL + 'upload-media' + '?isPrivate=true'
        });
      } else {
        fileUploader = new FileUploader({
          url: ApiUrlConstants.CMS_API_URL + 'upload-media'
        });
      }
      return fileUploader;
    }

    /**
     * @description get Authorization Token
     * @date 2020-05-05
     * @returns {string}
     * @memberof supportTicketService
     */
    getAuthorizationToken(token: any): string {
        const accessToken = token.guestToken;
        if (accessToken) {
            const header = 'Bearer ' + accessToken + '&&' + RealmType.TITAN_GUEST;
        // return this.getAuthorizationHeader(header, uuid);
            return header;
        }
    }

    /**
     * @description get Authorization header
     * @date 2020-05-05
     * @private
     * @param {string} token
     * @param {string} uuid
     * @returns {string}
     * @memberof supportTicketService
     */
    getAuthorizationHeader(token: any, uuid: string): string {
        const data = uuid + '||' + token.guestToken;
        return EncryptionService.encryptText(data);
    }

    /**
     * @description get Countries List
     * @date 2020-05-06
     * @returns {Observable<CountryResponse>}
     * @memberof SupportTicketService
     */
    getCountriesList(): Observable<CountryResponse[]> {
        return this.httpClient.get<CountryResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API + 'operating-countries/lookup', {'headers' : this.headers});
    }

    /**
     * @description get Guest token for file upload
     * @date 2020-05-07
     * @returns {Observable<string>}
     * @memberof SupportTicketService
     */
    getGuestToken(): Observable<string> {
        return this.httpClient.get<string>(ApiUrlConstants.CMS_API_URL + 'guest-token');
    }

    getGuestTokenDetails(): Observable<string> {
        return this.httpClient.get<string>(ApiUrlConstants.ECOMMERCE_URL + 'guest-token');
    }

    /**
     * @description get operating Country details
     * @date 2021-02-25
     * @returns {Observable<any>}
     * @memberof SupportTicketService
     */
    getOperatingCountryByCountryCode(countryCode: string): Observable<any> {
        return this.httpClient.get<any>(ApiUrlConstants.CONFIGURATION_API + 'countries/' + countryCode + '/operating-countries', {'headers' : this.headers});
    }

    /**
     * To handle the obervable error response
     * @param  {Response|any} error
     */
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error.message || error);
    }

    
    getEntitiesList(): Observable<any> {
        return this.httpClient.get<any>(ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'entities',{'headers' : this.headers});
    }


    getDepartmentDetails(): Observable<any> {
        return this.httpClient.get<any>(ApiUrlConstants.CUSTOMER_SERVICE_V2_API
             + 'departments' , {'headers' : this.headers});
    }

    getTicketTypeDetails(entityId: number): Observable<any> {
        return this.httpClient.get<any>(ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'ticket-types?rowStatusId=1&entityIds=' + entityId + '&pageNumber=1&pageSize=0', {'headers' : this.headers});
    }

    getSubTicketTypeDetails(typeId: number): Observable<any> {
        return this.httpClient.get<any>(ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'sub-ticket-types?rowStatusId=1&ticketTypeId=' + typeId + '&pageNumber=1&pageSize=0', {'headers' : this.headers});
    }

    getTicketStatusDetails(): Observable<any> {
        return this.httpClient.get<any>(ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'ticket-status?rowStatusId=1&elements=name&sort=ASC&pageSize=0&pageNumber=1', {'headers' : this.headers});
    }

    getSourceTypeDetails(): Observable<any[]> {
        return this.httpClient.get<any[]>(ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'lookup-configurations/Source', {'headers' : this.headers});
    }

    getTicketCategoryDetails(): Observable<any[]> {
        return this.httpClient.get<any[]>(ApiUrlConstants.CUSTOMER_SERVICE_V2_API + 'lookup-configurations/TicketCategory', {'headers' : this.headers});
    }

}
