<main class="pb-150" [ngClass]="fromHelp ? 'main-help' : ''">
  <div class="container">
    <div class="row">
      <div class="col">
        <app-spinner [spinnerName]="'supportTicketSpinner'"></app-spinner>
        <div class="content-center support-form w-70 pt-3">
          <div class="mb-3 support-form-title">{{'supportTicket.Submit a Support Request'|translate}}
          </div>
          <form role="form" [formGroup]="supportRequestForm" onSubmit="return false" class="support-form-content">
            <div class=" mt-4 support-request-form-field px-10">
              <div class="form-row text-left">
                <div class="form-group col-md-4">
                  <label class="control-label">{{"supportTicket.Your Name" | translate}}</label>
                  <input type="text" [attr.readonly]= "fromHelp" class="form-control" formControlName="name"
                    [value]="supportRequestForm.controls.name.value" maxlength="30" pattern="^[^%~#^]+$" />
                  <div *ngIf="supportRequestForm.controls['name'].dirty || supportRequestForm.controls['name'].touched">
                    <div *ngIf="supportRequestForm.controls['name'].hasError('required')" class="text-danger">
                      {{ "supportTicket.Please enter your full name" | translate }}
                    </div>
                    <div *ngIf="supportRequestForm.controls['name'].hasError('pattern')" class="text-danger">
                      {{"supportTicket.Please enter valid Name" | translate}}
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label class="control-label">{{"supportTicket.Email" | translate}}</label>
                  <input type="email" [attr.readonly]= "fromHelp" class="form-control" formControlName="email" />
                  <div *ngIf="supportRequestForm.controls['email'].errors">
                    <div *ngIf="supportRequestForm.controls['email'].errors.validEmail ==false" class="text-danger">
                      {{"supportTicket.Please enter a valid email address" | translate }}
                    </div>
                    <div
                      *ngIf="supportRequestForm.controls['email'].hasError('required') && supportRequestForm.controls['email'].touched"
                      class="text-danger">
                      {{ "supportTicket.Email is Required" | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label class="control-label">{{ "supportTicket.Your FBO ID Number" | translate }}*</label>
                  <input [attr.readonly]= "fromHelp" type="text" class="form-control" formControlName="fboId"
                   (keyup)="fboNotFound=false"
                    />
                  <div *ngIf="supportRequestForm.controls['fboId'].errors">
                    <div
                      *ngIf="supportRequestForm.controls['fboId'].hasError('required') && supportRequestForm.controls['fboId'].touched"
                      class="text-danger">
                      {{ "supportTicket.FBO ID is Required" | translate }}
                    </div>
                    <div
                      *ngIf="supportRequestForm.controls['fboId'].errors.validFboId ==false ||supportRequestForm.controls['fboId'].hasError('minlength')"
                      class="text-danger">
                      {{ "supportTicket.FBO ID must have 12 digits" | translate }}
                    </div>
                  </div>
                  <div *ngIf="fboNotFound && !supportRequestForm.controls['fboId'].errors" class="text-danger">
                    {{ "supportTicket.FBO details not found" | translate }}
                  </div>
                </div>
              </div>
              <div class="form-row text-left">
                <div class="form-group col-md-12">
                  <label class="control-label">{{"supportTicket.Your Message" | translate}}</label>
                  <textarea rows="4" cols="50" class="form-control" formControlName="message"
                    maxlength="250"></textarea>
                  <div class="float-right font-10">
                    {{'supportTicket.250 characters (maximum 250 characters)' | translate}}
                  </div>
                  <div
                    *ngIf="supportRequestForm.controls['message'].dirty || supportRequestForm.controls['message'].touched">
                    <div *ngIf="supportRequestForm.controls['message'].hasError('required')" class="text-danger">
                      {{ "supportTicket.Required" | translate }}
                    </div>
                    <div
                      *ngIf="supportRequestForm.controls['message'].touched && supportRequestForm.controls['message'].errors?.maxlength"
                      class="text-danger">
                      {{'supportTicket.Exceeded Max Length Allowed' | translate}}.</div>
                  </div>
                </div>
              </div>
              <div class="form-row text-left">
                <div class="form-group col-md-12">
                  <label class="control-label">{{"supportTicket.Attachment Upload" | translate}}</label>
                  <div>

                    <table class=" col-12 table-border">
                      <tr class="text-center p-2 bg-color">
                        <label tabindex="0" for="attachFile" class="p-1 upload-btn"
                          title="{{'supportTicket.select file to upload'|translate}}">
                          <span
                            aria-hidden="true">{{'supportTicket.select file to upload'|translate | uppercase}}</span>
                          <input formControlName="document" maxFileSize="1000000" accept=".png,.jpeg,.jpg" type="file"
                            id="attachFile" (click)="myInputFile.value=null" (change)="fileChangeEvent()" ng2FileSelect [uploader]="uploader"
                            [disabled]="attachmentsLength===5" role="file" size="{{maxAllowableUpload}}"
                            aria-label="browse" style="display: none;" multiple #myInputFile>
                        </label>
                      </tr>
                      <tr class="text-center border-top" *ngIf="uploader.queue.length === 0">
                        {{'supportTicket.No File Selected'|translate}} </tr>
                      <tr class="p-2 border-top row mx-0" *ngFor="let item of uploader.queue">
                        <span class="col-5 text-center word-break">
                          {{item._file.name}}
                        </span>
                        <span class="col-4 text-center">
                          {{item._file.size}} KB
                        </span>
                        <span class="text-center pointer col-3">
                          <i (click)="item.remove()" class="fa fa-trash"></i>
                        </span>
                      </tr>
                    </table>
                    <div class="text-danger" *ngIf="fileUploadErrorMessages && fileUploadErrorMessages.length > 0">
                      <div *ngFor="let error of fileUploadErrorMessages">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-error-message text-center p-2" *ngIf="globalErrorMsg">
              <span>{{globalErrorMsg}}</span>
              <span class="support-form-error">{{customerCareEmailId}}</span>
            </div>
            <div class="text-center save-btn">
              <button type="submit" (click)="createSupportRequest()"
                class="btn btn-primary  btn-color btn-lg text-uppercase px-4 py-1"
                [disabled]="(!supportRequestForm.valid) || supportRequestForm.pristine">
                {{'supportTicket.submit' | translate | uppercase}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
