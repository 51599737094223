import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiUrlConstants } from '@core/constants/api.constants';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TicketDetailsResponse, TicketType } from '@modules/support-ticket/interface/support-ticket-base-response';
import { RealmType } from '@modules/support-ticket/enums/realm-type.enum';
import { SupportTicketRequest } from '@modules/support-ticket/interface/support-ticket.interface';
import { EncryptionService } from '@core/services/encryption.service';
import { CountryResponse } from '@modules/support-ticket/interface/country-response.interface';
import { UserModel } from '@modules/support-ticket/interface/user-modal.interface';
import { TooltipResponse } from '../interfaces/tooltip-response.interface';


@Injectable()
export class TrainingTooltipService {
    constructor(private httpClient: HttpClient) { }



    /**
     * @description Create Support Ticket
     * @date 2020-05-05
     * @param {*} createTicketRequest
     * @returns
     * @memberof supportTicketService
     */
    createSupportTicket(createTicketRequest: SupportTicketRequest): Observable<SupportTicketRequest> {
        const url = ApiUrlConstants.CUSTOMER_SERVICE_API + 'tickets';
        return this.httpClient.post<SupportTicketRequest>(url, createTicketRequest).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description get user details
     * @date 2020-05-05
     * @param {string} fboId
     * @returns
     * @memberof supportTicketService
     */
    getUserdetailsByFBOId(fboId: string): Observable<UserModel> {
        return this.httpClient.get<UserModel>(
          ApiUrlConstants.REPORTS_BASE + 'user-profile/distributorId/' + fboId
        );
    }

    /**
     * @description get Source Type
     * @date 2020-05-05
     * @returns {Observable<SourceType[]>}
     * @memberof supportTicketService
     */
    getSourceType(token: string): Observable<TicketDetailsResponse[]> {
        return this.httpClient.get<TicketDetailsResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API + 'source-types', {headers: this.getHeaders(token)});
    }

    /**
     * @description get Ticket Priorites
     * @date 2020-05-05
     * @returns {Observable<TicketPriorities[]>}
     * @memberof supportTicketService
     */
    getTicketPriorities(token: string): Observable<TicketDetailsResponse[]> {
        return this.httpClient.get<TicketDetailsResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API + 'ticket-priorities' , {headers: this.getHeaders(token)});
    }

    /**
     * @description get Ticket Status
     * @date 2020-05-05
     * @returns {Observable<TicketDetailsMappingResponse[]>}
     * @memberof supportTicketService
     */
    getTicketStatus(token: string): Observable<TicketDetailsResponse[]> {
        let headers = new HttpHeaders();
        headers = headers.set("Authentication", token);
        const url = ApiUrlConstants.CUSTOMER_SERVICE_API + 'ticket-status';
        return this.httpClient.get<TicketDetailsResponse[]>(url, {'headers': headers});
    }

    /**
     * @description get Ticket Types and subtypes
     * @date 2020-05-05
     * @returns {Observable<TicketType[]>}
     * @memberof supportTicketService
     */
    getTicketTypesAndSubTypes(operatingCountryId: number): Observable<TicketType[]> {
        return this.httpClient.get<TicketType[]>(ApiUrlConstants.CUSTOMER_SERVICE_API
             + 'types/sub-types?operatingCountryId=' + operatingCountryId);
    }

    /**
     * @description get Dapartments
     * @date 2020-05-05
     * @returns {Observable<TicketDetailsMappingResponse[]>}
     * @memberof supportTicketService
     */
    getDepartments(operatingCountryId: number, token: string): Observable<TicketDetailsResponse[]> {
        return this.httpClient.get<TicketDetailsResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API
             + 'departments/lookup?operatingCountryId=' + operatingCountryId , {headers: this.getHeaders(token)});
    }


    /**
     * @description get Authorization Token
     * @date 2020-05-05
     * @returns {string}
     * @memberof supportTicketService
     */
    getAuthorizationToken(token: any): string {
        const accessToken = token.guestToken;
        if (accessToken) {
            const header = 'Bearer ' + accessToken + '&&' + RealmType.TITAN_GUEST;
           // return this.getAuthorizationHeader(header, uuid);
            return header;
        }
    }

    getHeaders(token: string): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('Authentication', token);
        return headers;
    }
    /**
     * @description get Authorization header
     * @date 2020-05-05
     * @private
     * @param {string} token
     * @param {string} uuid
     * @returns {string}
     * @memberof supportTicketService
     */
    private getAuthorizationHeader(token: string, uuid: string): string {
        const data = uuid + '||' + token;
        return EncryptionService.encryptText(data);
    }

    /**
     * @description get Countries List
     * @date 2020-05-06
     * @returns {Observable<CountryResponse>}
     * @memberof SupportTicketService
     */
    getCountriesList(token: string): Observable<CountryResponse[]> {
        return this.httpClient.get<CountryResponse[]>(ApiUrlConstants.CUSTOMER_SERVICE_API + 'operating-countries/lookup', {headers: this.getHeaders(token)});
    }

    /**
     * @description get Guest token for file upload
     * @date 2020-05-07
     * @returns {Observable<string>}
     * @memberof SupportTicketService
     */
    getGuestToken(): Observable<string> {
        return this.httpClient.get<string>(ApiUrlConstants.CMS_API_URL + 'guest-token');
    }

    /**
     * To handle the obervable error response
     * @param  {Response|any} error
     */
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error.message || error);
    }
    /**
     * @description Getting Report Tooltip Status
     * @date 2021-02-23
     * @param {string} fboId
     * @returns {*}  
     * @memberof TrainingTooltipService
     */
    public getReportTooltipStatus(fboId: string) {
        const url = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/tooltip-status'
         return this.httpClient.get<TooltipResponse>(url);
     }
 
     /**
      * @description Updating tooltip status by name
      * @date 2021-02-23
      * @param {string} fboId
      * @param {string} slugName
      * @returns {*}  
      * @memberof TrainingTooltipService
      */
     public updateReportTooltipStatus(fboId: string, slugName: string) {
         const url = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/slug/' + slugName + '/tooltip-status';
            return this.httpClient.post<any>(url, {});
     }
}
