import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'distributerIdFormat' })
export class DistributerIdFormat implements PipeTransform {
    /**
   * Pipes which converts the FboId in the Application to xxx-xxx-xxx-xxx format
   * @param  {string} distributerId
   * @returns string
   */
    transform(distributerId: string): string {
        if (distributerId) {
            distributerId = distributerId.replace(/\s/g, '');
            let formatedId = '';
            let i = 0;
            for (i; i < (Math.floor(distributerId.length / 2) - 2); i++) {
                formatedId = formatedId + distributerId.substr(i * 3, 3) + '-';
            }
            formatedId = formatedId + distributerId.substr(i * 3);
            if (formatedId.substr(formatedId.length - 1) === '-') {
                formatedId = formatedId.substr(0, (formatedId.length - 1));
            }

            return formatedId;
        }
    }

}
