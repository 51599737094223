import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SpinnerService {

    constructor() {
    }
    private listeners: { [id: string]: Subject<any> } = {};


    listenloader(sectionName: string): Observable<boolean> {
        if (sectionName) {
            return this.getListener(sectionName);
        }
    }
    getListener(sectionName: string): Observable<boolean> {
        if (!this.listeners[sectionName]) {
            this.listeners[sectionName] = new Subject<any>();
        }
        return this.listeners[sectionName];
    }

    private show(sectionName: string, show: boolean, timeout?: number): void {
        if (sectionName && this.listeners[sectionName]) {
            this.listeners[sectionName].next(show);
        }

    }


  private hide(sectionName: string, show: boolean): void {
    if (sectionName  && this.listeners[sectionName]) {
      this.listeners[sectionName].next(show);
    }
  }

  public start(sectionName: string, timeout?: number): void {
    this.show(sectionName, true, timeout);
  }

  public stop(sectionName: string): void {
    this.hide(sectionName, false);
  }

  public unsubscribeListener(sectionName: string) {
    if (sectionName && this.listeners[sectionName]) {
      this.listeners[sectionName].unsubscribe();
      this.listeners[sectionName] = null;
    }
  }

  public unsubscribeAll(): void {
    const subscribers = Object.keys(this.listeners);
    for (let sectionName = 0; sectionName < subscribers.length; sectionName++) {
      this.listeners[sectionName].unsubscribe();
    }
  }

}
