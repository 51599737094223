export class IdTokenService {
  public static decodeJwt(jwtToken: string) {
    let jwtJson = null;
    if (jwtToken) {
      const jwtParts = jwtToken.split('.');
      if (jwtParts && jwtParts.length === 3) {
        const jwtBody = jwtParts[1];
        if (jwtBody) {
          jwtJson = JSON.parse(atob(jwtBody));
        }
      }
    }
    return jwtJson;
  }

  public static validateJwtExp(jwtToken: string) {
    let valid = false;
    const jwtJson = this.decodeJwt(jwtToken);
    if (jwtJson && jwtJson.exp) {
      const presentEpochTime = Math.round((new Date()).getTime() / 1000);
      if (jwtJson.exp > presentEpochTime) {
        valid = true;
      }
    }
    return valid;
  }
}
