import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageConstants } from '@core/constants/images.constant';
import { Constants } from '@core/constants/sso.constants';
import { MessageService } from '@core/services/message-service.service';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { CookieService } from 'utils/cookie.service';
import { VerifyEmailConstants } from '../constants/verify-email.constants';
import { AccountService } from '../services/account.service';
import SSO = Constants.SSO;

@Component({
    selector: 'app-verify-email',
    templateUrl: '../templates/template1/views/verify-email.component.html',
    styleUrls: [
      '../templates/template1/themes/verify-email.component.less'
    ]
  })
  export class VerifyEmailComponent implements OnInit , OnDestroy{
    userName: string;
    eventSubscription: any;
    success = false;
    failure = false;
    expiryCode: string;
    validationMessage: string;
    loginId: string;
    topNav: boolean;
    expiryCodeAvailable: boolean;
    cdnURL = environment.cdnURL;
    imagePath = ImageConstants.IMAGE_PATH;
    desktopLogo = ImageConstants.DESKTOP_HEADER_LOGO;
    baseUrl = environment.uiTemplateBaseUrl;
    footerImage = ImageConstants.FOOTER_IMAGE;
    privacyLink = environment.cdnURL + ImageConstants.PRIVACY_LINK;
    corporateURL = environment.corporateURL;
    toggleStatus: boolean;
    customerCareEmailId = environment.customerCareEmailId;
    isSuccess: boolean;
    toggleStatusSubscription: Subscription;
    currentYear = new Date().getFullYear();

    constructor(
        private aroute: ActivatedRoute,
        private accountService: AccountService,
        private messageService: MessageService
      ) {
        this.toggleStatusSubscription = this.messageService.getToggleStatus().subscribe(message => {
          this.toggleStatus = message.text;
        });
      }
      ngOnInit() {
        this.aroute.queryParams.subscribe(params => {
          this.loginId = params[VerifyEmailConstants.NAME];
          this.expiryCode = params[VerifyEmailConstants.CODE];
          if (this.expiryCode !== undefined) {
            this.validateExpiryCode();
          } else {
            this.expiryCodeAvailable = true;
          }
        });
      }

    /**
     * @description validate Email expiration code
     * @date 2020-01-02
     * @memberof VerifyEmailComponent
     */
    validateExpiryCode(): void {
      this.accountService.validateUserEmailExpiryCode(this.loginId, this.expiryCode).subscribe(res => {
            if (res && res.validations && res.validations.length > 0) {
              this.expiryCodeAvailable = false;
              this.isSuccess = res.success;
              this.accountService.updateSocialsalesEmailId(this.loginId).subscribe(result => {
              });
              this.validationMessage = 'common.' + res.validations[0].message;
            } else {
              this.expiryCodeAvailable = true;
            }
          }, error => {
            this.expiryCodeAvailable = true;
          });
      }

    /**
     * @description
     * @date 2020-01-02
     * @memberof VerifyEmailComponent
     */
    redirectToLogin(): void {
        localStorage.removeItem('appflp360.state');
        const state = SSO.getNonce();
        const idNonce = SSO.getNonce();
        CookieService.setCookie(SSO.NONCE, idNonce, null, environment.cookiePath, environment.cookieDomain);
        localStorage.setItem('appflp360.state', JSON.stringify(state));
        window.location.href =environment.ssoApiURL + '/auth?client_id=' + environment.ssoClientID +
          '&redirect_uri=' + Constants.SSO.CURRENT_ORGIN + '/login&scope=openid&response_type=code&state=' + state + '&nonce='
          + idNonce;
      }

  /**
   * @description toggle Side navigation
   * @date 2020-01-03
   * @memberof VerifyEmailComponent
   */
  toggleSideNav() {
        this.toggleStatus = false;
        this.messageService.sendToggleStatus(this.toggleStatus);
      }

    ngOnDestroy() {
      this.toggleStatusSubscription?.unsubscribe();
    }
  }
