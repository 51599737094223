import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { VerifyEmailComponent } from './components/verify-email.component';

export const routes: Routes = [
    {
        path: '',
        component: VerifyEmailComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class VerifyEmailRoutingModule { }