import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersonalInformationDetails } from '../models/personal-information-details';
import { Flp360UserModel } from '../models/flp360UserModel';
import { Observable } from 'rxjs';
import { ApiUrlConstants } from '@core/constants/api.constants';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})

export class PersonalInformationService {

  constructor(private httpClient: HttpClient) { }


  /**
   * getting flp360 user details from flp360user table
   *
   * @param {string} fboId
   * @returns
   * @memberof PersonalInformationService
   */
  getFlp360UserDetails(fboId: string) {
    return this.httpClient.get<Flp360UserModel>(
      ApiUrlConstants.ACCOUNT_BASE + 'flp360/user/' + fboId
    );
  }

  /**
   * updating personal information in flp360user table
   *
   * @param {string} fboId
   * @param {PersonalInformationDetails} personalInformation
   * @returns
   * @memberof PersonalInformationService
   */
  updatePersonalInformation(fboId: string, personalInformation: PersonalInformationDetails) {
    const updatePersonalInfoUrl = ApiUrlConstants.ACCOUNT_BASE + 'flp360/user/' + fboId + '/update';
    return this.httpClient.patch(updatePersonalInfoUrl, personalInformation);
  }

  /**
   * updating the fbolevel in flp360user table
   *
   * @param {string} fboId
   * @param {string} fboLevel
   * @memberof PersonalInformationService
   */
  updateFboLevel(fboId: string, memberTitle: string): Observable<any> {
    const body = {};
    return this.httpClient.put(ApiUrlConstants.ACCOUNT_BASE + 'flp360/update/fboLevel/fboId/' + fboId + '/memberTitle/' + memberTitle, body);
  }

  updateFlp360UserDetails(fboId: string, flp360UserDetails: any): Observable<any> {
    return this.httpClient.put(ApiUrlConstants.ACCOUNT_BASE + 'flp360/' + fboId + '/update' , flp360UserDetails);
  }

  getStoreCountryInformation(countryCode: any) {
    let getConfigurationUrl = environment.apiUrl + "/admin/V1/store-countries/" + countryCode;

    return this.httpClient.get(getConfigurationUrl);
  }
}
