export class CultureCodeConstants {
  public static ENGLISH = {
    id: 1,
    language: 'English',
    cultureCode: 'en-US',
    languageCode: 'en',
    languageDirection: 'ltr'
  };
  public static SPANISH = {
    id: 2,
    language: 'Español',
    cultureCode: 'es-ES',
    languageCode: 'es',
    languageDirection: 'ltr'
  };
  public static FRENCH = {
    id: 3,
    language: 'Français',
    cultureCode: 'fr-FR',
    languageCode: 'fr',
    languageDirection: 'ltr'
  };
  public static DUTCH = {
    id: 4,
    language: 'Nederlands',
    cultureCode: 'nl-NL',
    languageCode: 'nl',
    languageDirection: 'ltr'
  };
  public static CHINESE = {
    id: 5,
    language: '中文',
    cultureCode: 'zh-CN',
    languageCode: 'zh',
    languageDirection: 'ltr'
  };
  public static GREEK = {
    id: 6,
    language: 'Ελληνικά',
    cultureCode: 'el-GR',
    languageCode: 'el',
    languageDirection: 'ltr'
  };
  public static MALAYSIA = {
    id: 7,
    language: 'Melayu',
    cultureCode: 'ms-MY',
    languageCode: 'ms',
    languageDirection: 'ltr'
  };
  public static POLISH = {
    id: 8,
    language: 'Polskie',
    cultureCode: 'pl-PL',
    languageCode: 'pl',
    languageDirection: 'ltr'
  };
  public static ITALIAN = {
    id: 9,
    language: 'Italiano',
    cultureCode: 'it-IT',
    languageCode: 'it',
    languageDirection: 'ltr'
  };
  public static SLOVAK = {
    id: 10,
    language: 'Slovenský',
    cultureCode: 'sk-SK',
    languageCode: 'sk',
    languageDirection: 'ltr'
  };
  public static HUNGARIAN = {
    id: 11,
    language: 'Magyar',
    cultureCode: 'hu-HU',
    languageCode: 'hu',
    languageDirection: 'ltr'
  };
  public static GERMAN = {
    id: 12,
    language: 'Deutsch',
    cultureCode: 'de-DE',
    languageCode: 'de',
    languageDirection: 'ltr'
  };
  public static PORTUGUESE = {
    id: 13,
    language: 'Português',
    cultureCode: 'pt-PT',
    languageCode: 'pt',
    languageDirection: 'ltr'
  };
  public static ROMANIAN = {
    id: 14,
    language: 'Română',
    cultureCode: 'ro-RO',
    languageCode: 'ro',
    languageDirection: 'ltr'
  };
  public static DANISH = {
    id: 15,
    language: 'Dansk',
    cultureCode: 'da-DK',
    languageCode: 'da',
    languageDirection: 'ltr'
  };
  public static TURKISH = {
    id: 16,
    language: 'Türk',
    cultureCode: 'tr-TR',
    languageCode: 'tr',
    languageDirection: 'ltr'
  };
  public static SWEDISH = {
    id: 17,
    language: 'Svenska',
    cultureCode: 'sv-SE',
    languageCode: 'sv',
    languageDirection: 'ltr'
  };
  public static BULGARIAN = {
    id: 18,
    language: 'български',
    cultureCode: 'bg-BG',
    languageCode: 'bg',
    languageDirection: 'ltr'
  };
  public static RUSSIAN = {
    id: 19,
    language: 'русский язык',
    cultureCode: 'ru-RU',
    languageCode: 'ru',
    languageDirection: 'ltr'
  };
  public static NORWEGIAN = {
    id: 20,
    language: 'norsk',
    cultureCode: 'nn-NO',
    languageCode: 'nn',
    languageDirection: 'ltr'
  };
  public static FINNISH = {
    id: 21,
    language: 'Suomalainen',
    cultureCode: 'fi-FI',
    languageCode: 'fi',
    languageDirection: 'ltr'
  };
  public static LANGUAGE_CONSTANTS = {
    USA: 'en-US',
    FRA: 'fr-FR',
    NLD: 'nl-NL',
    ESP: 'es-ES',
    CHN: 'zh-ZH',
    GRC: 'el-GR',
    MYS: 'ms-MY',
    POL: 'pl-PL',
    SVK: 'sk-SK',
    ITA: 'it-IT',
    HUN: 'hu-HU',
    DEU: 'de-DE',
    PRT: 'pt-PT',
    TUR: 'tr-TR',
    ROM: 'ro-RO',
    DNK: 'da-DK',
    RUS: 'ru-RU',
    FEU: 'fr-EU',
    SWE: 'sv-SE',
    BGR: 'bg-BG',
    GBR: 'en-GB',
    LMA: 'es-LM',
    NOR: 'nn-NO',
    FIN: 'fi-FI'
  };

  public static LANGUAGE_LIST = [CultureCodeConstants.ENGLISH, CultureCodeConstants.SPANISH, CultureCodeConstants.FRENCH,
  CultureCodeConstants.DUTCH, CultureCodeConstants.CHINESE, CultureCodeConstants.GREEK, CultureCodeConstants.MALAYSIA,
  CultureCodeConstants.POLISH, CultureCodeConstants.ITALIAN, CultureCodeConstants.SLOVAK, CultureCodeConstants.HUNGARIAN,
  CultureCodeConstants.GERMAN, CultureCodeConstants.PORTUGUESE, CultureCodeConstants.ROMANIAN, CultureCodeConstants.DANISH,
  CultureCodeConstants.TURKISH, CultureCodeConstants.SWEDISH, CultureCodeConstants.BULGARIAN, CultureCodeConstants.RUSSIAN];

public static UPDATED_LANGUAGE_LIST = [CultureCodeConstants.ENGLISH, CultureCodeConstants.SPANISH, CultureCodeConstants.FRENCH,
  CultureCodeConstants.DUTCH, CultureCodeConstants.CHINESE, CultureCodeConstants.GREEK, CultureCodeConstants.MALAYSIA,
  CultureCodeConstants.POLISH, CultureCodeConstants.ITALIAN, CultureCodeConstants.SLOVAK, CultureCodeConstants.HUNGARIAN,
  CultureCodeConstants.GERMAN, CultureCodeConstants.PORTUGUESE, CultureCodeConstants.ROMANIAN, CultureCodeConstants.DANISH,
  CultureCodeConstants.TURKISH, CultureCodeConstants.SWEDISH, CultureCodeConstants.BULGARIAN, CultureCodeConstants.RUSSIAN,CultureCodeConstants.NORWEGIAN,CultureCodeConstants.FINNISH];
}
