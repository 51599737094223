import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class IFrameAuthGuard implements CanActivate {
    
    constructor(private router: Router) {}

    canActivate(): boolean {
        if (window && window.self !== window.top) {
            return true;
        } else {
            this.router.navigate(['enrollment']);
            return false;
        }
    }
}
