import { AbstractControl } from '@angular/forms';

export function EmailValidator(control: AbstractControl) {
    const pattern = new RegExp(
        /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/g
    );
    const validEmail = pattern.test(control.value);
    if (validEmail || control.value === '') {
        return null;
    }
    return { validEmail: false };
}


export function FboIdValidator(control: AbstractControl) {
    const pattern = new RegExp('^[0-9,*]{12}$');
    const validFboId = pattern.test(control.value);
    if (validFboId || control.value === '') {
        return null;
    }
    return { validFboId: false };
}
