import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({ providedIn: 'root' })
export class CacheService {
    constructor(private localStorageService: LocalStorageService) { }

    /**
     * get the value from storage for pair of key
     * @param  {string} cacheKey
     */
    public get(cacheKey: string): any {
        if (cacheKey) {
            return this.localStorageService.get(cacheKey);
        }
    }


    /**
     * save the value into storage with pair of key
     * @param  {string} cacheKey
     */
    public set(cacheKey: string, cacheValue: any): void {
        if (cacheKey && cacheValue) {
            this.localStorageService.set(cacheKey, cacheValue);
        }
    }

    /**
     * remove the value form storage
     * @param  {string} cacheKey
     */
    public remove(cacheKey: string): void {
        if (cacheKey) {
            this.localStorageService.remove(cacheKey);
        }
    }


    /** * remove the value form storage */
    public removeAll(): void {
        this.localStorageService.clearAll();
    }
}
