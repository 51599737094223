import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'maskDistributorId'
})
export class MaskDistributorIdPipe implements PipeTransform {
  /**
   * pipe which mask all the FBOId's First and Last three digits
   * @param  {string} value
   * @returns string
   */
  transform(value: string): string {
    if(environment && environment.isDemoAccount && value){
      let id: string= value
      id =id.split('-').join('');
      if(id.length<12){
        let digit = Array(13-id.length).join('0');
        id = digit + id; 
      }
      return (Array(4).join('*')).concat(id.slice(3,id.length-3)).concat(Array(4).join('*'));
    }else{
      return value;
    }
  }

}
