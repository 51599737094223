import { SupportTicketFormComponent } from './../modules/support-ticket/components/support-ticket-form.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from '@core/directives/click-outside.directive';
import { ImgSrcDirective } from '@core/directives/img-src.directive';
import { TranslateKey } from '@core/pipes/translate-key.pipe';
import { FourCoreHealthCheckComponent } from '@modules/reports/components/four-core-health-check.component';
import { TranslateDirective, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AppLoaderComponent } from 'app/layouts/components/loader.component';
import { SpinnerComponent } from 'app/layouts/components/spinner.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SafeHtmlPipe } from '@core/pipes/safe-html.pipe';
import { DistributerIdFormat } from '@core/pipes/distributer-id.pipe';
import { TitleCasePipe } from '@core/pipes/title-case.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { AppTrainingInfoComponent } from 'app/layouts/components/app-training-info.component';
import { SafeUrlPipe } from '@core/pipes/safe-url.pipe';
import { AppGuidedTourComponent } from 'app/layouts/components/app-guided-tour.component';
import { AppTrainingTooltipComponent } from 'app/layouts/components/app-training-tooltip.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SupportTicketSuccessComponent } from '@modules/support-ticket/components/support-ticket-success.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMaskModule } from 'ngx-mask';
import { ComingSoonComponent } from '@modules/dummy-pages/components/coming-soon.component';
import { AfterIfDirective } from '@core/directives/after-if.directive';
import { MaskDistributorIdPipe } from '@core/pipes/mask-distributor-id.pipe';

@NgModule({
  declarations: [ClickOutsideDirective, ImgSrcDirective, AppLoaderComponent, DistributerIdFormat,
    FourCoreHealthCheckComponent, SpinnerComponent, TranslateKey, SafeHtmlPipe, SafeUrlPipe, TitleCasePipe,
    AppGuidedTourComponent, AppTrainingTooltipComponent, SupportTicketFormComponent, SupportTicketSuccessComponent,
    ComingSoonComponent, AfterIfDirective, MaskDistributorIdPipe],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FileUploadModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    CarouselModule,
    NgxMaskModule.forRoot(),
    PopoverModule.forRoot()

  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    TranslateDirective,
    ModalModule,
    PopoverModule,
    BsDropdownModule,
    CarouselModule,
    ClickOutsideDirective,
    ImgSrcDirective,
    AfterIfDirective,
    AppLoaderComponent,
    FourCoreHealthCheckComponent,
    SpinnerComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    TranslateKey,
    DistributerIdFormat,
    TitleCasePipe,
    TooltipModule,
    AppTrainingTooltipComponent,
    AppGuidedTourComponent,
    SupportTicketFormComponent,
    SupportTicketSuccessComponent,
    ComingSoonComponent,
    MaskDistributorIdPipe
  ],
  providers: [TranslatePipe, TranslateKey, DistributerIdFormat, TitleCasePipe,MaskDistributorIdPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
