import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'translateKey'
})
export class TranslateKey implements PipeTransform {

    /**
     * Pipes which converts the translateText in the Application to json labels
     * @param  {Array<any>} items
     * @param  {predicate} filterOptions
     * @returns Array<any>
     */
    transform(translateText: string, param?: any): any {
        if (translateText) {
            if (param) {
                translateText.match(/(\|\|(.*?)\|\|)/g)
                    .map((x, y) => {
                        translateText = translateText.replace(x, param[x.substr(2, x.length - 4)])
                    })
            }
            if (translateText.match(/(\.)(?=\w)/)) {
                return translateText.split(/(\.)(?=\w)/).slice(2).join('');
            } else {
                return translateText;
            }
        }
    }
    transformText(translateText: string, param?: any): any {
        if (translateText) {
            if (param) {
                translateText.match(/(\|\|(.*?)\|\|)/g)
                    .map((x, y) => {
                        translateText = translateText.replace(x, param[x.substr(2, x.length - 4)])
                    })
                    return translateText;
            }
            else {
                return translateText;
            }
        }
    }
}
