import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '@core/services/message-service.service';

@Component({
  selector: 'app-support-ticket-success',
  templateUrl: '../templates/template1/views/support-ticket-success.component.html',
  styleUrls: ['../templates/template1/themes/support-ticket-success.component.less']
})
export class SupportTicketSuccessComponent implements OnInit {
  @Input() fromHelp;
  constructor(private router: Router,
    private messageService: MessageService) {
  }
  ngOnInit() {

  }
  /**
   * @description Routing to the success form if not from help
   * if from help, sending the boolean value through message service
   * @date 10/08/2021
   * @memberof SupportTicketSuccessComponent
   */
  routeToSupportForm() {
    if (!this.fromHelp) {
      this.router.navigate(['/support-ticket']);
    } else {
      this.messageService.sendIsSupportFormFromHelp(false);
    }
  }
}
