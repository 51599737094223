export class CacheKeyConstants {
    public static countryCode = 'countryCode';
    public static languageCode = 'languageCode';
    public static homeCountryCode = 'homeCountryCode';
    public static storeConfiguration = 'storeConfiguration';
    public static userInfo = 'userInfo';
    public static selectedLanguage = 'selectedLanguage';
    public static PORTAL_ID = '3';
    public static SITES = 'sites';
    public static COUNTRY_LIST = 'countryList';
    public static FLP360FBOID = 'flp360StatusId';
    public static HOME_COUNTRY_NAME = 'homeCountryName';
    public static BROWSER_NAME = 'browser';
    public static PLAN_TYPE = 'planType';
    public static SELECTED_COUNTRY = 'selectedCountry';
    public static SUBSCRIPTION_REACTIVATED_STATUS = 'subscriptionReactivationStatus';
    public static MONTH_LIST = 'monthList';
    public static IMPERSONATION = 'impersonation';
    public static EAGLE_MANAGER_COUNTRY_NAME = 'eagleManagerCountryDisplayName';
    public static MEMBER_SESSION_ID = 'memberSessionId';
    public static NON_TITAN_USER_JOIN_DATE = 'nonTitanUserJoinDate';
    public static ANNOUNCEMENT_VERSION = 'announcementVersion';
    public static REPORT_TOOLTIP_STATUS = 'reportsTooltipStatus';
    public static IMPERSONATED_USER = 'impersonatedUser';
    public static CHAIRMANBONUS_COUNTRY = 'chairmanBonusCountryDisplayName';
    public static DownlineFbosonRecap = 'DownlineFbosonRecap';
    public static PREVIOUS_URL='previousUrl';
    public static EARNING_FILTERS='earningFilters';
    // public static UserDetails = 'userDetails';
    /**
     * @description Get the original data from hexadecimal for numbers
     * @date 25/05/2021
     * @static
     * @param {*} data
     * @returns {*}
     * @memberof CacheKeyConstants
     */
    public static getDecodedDataForNumber(data) {
        let fboId = parseInt(data, 16).toString();
        fboId = ("000000000000" + fboId).slice(-12);
        return fboId;
    }
    /**
     * @description Get the original data from hexadecimal for string
     * @date 25/05/2021
     * @static
     * @param {*} data
     * @returns {*}
     * @memberof CacheKeyConstants
     */
    public static getDecodedDataForString(data) {
        if (data) {
        return unescape(data.replace(/\\/g, "%"))
        } else {
            return data;
        }
    }

    public static getEncodedData(data) {
        return Number(data).toString(16);
    }
}
