  import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { Constants } from '@core/constants/sso.constants';
import { MemberLevel } from '@core/Enums/memberlevel.enum';
import { MemberType } from '@core/Enums/membertype.enum';
import { DeviceInformationRequestModel } from '@core/models/device-information-request-model';
import { UserSession } from '@core/models/usersession.interface';
import { MessageService } from '@core/services/message-service.service';
import { SpinnerService } from '@core/services/spinner.service';
import { SsoService } from '@core/services/sso.service';
import { TrainingService } from '@core/services/training.service';
import { environment } from '@env/environment';
import { AccountConstants } from '@modules/account/constants/account.constants';
import { AppsConstants } from '@modules/account/constants/apps.constants';
import { PersonalInformationService } from '@modules/account/services/personal-information.service';
import { EnrollmentService } from '@modules/enrollment/services/enrollment.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import IdTokenVerifier from 'idtoken-verifier';
import { Subscription } from 'rxjs';
import { CookieService } from 'utils/cookie.service';
import { CacheService } from './core';
import SSO = Constants.SSO;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'flp360';
  nonTitanUserData: any;
  subscription: Subscription;
  memberType: MemberType;
  flp360UserModel = {
    firstName: '',
    lastName: '',
    email: '',
    homeCountry: '',
    fboLevel: '',
    fboId: ''
  };
  memberLevel: MemberLevel;
  isDemoAccount = environment.isDemoAccount;
  demoCountry = environment.demoCountry
  constructor(private translateService: TranslateService, private localStorageService: LocalStorageService,
    private ssoService: SsoService,
    private messageService: MessageService, private spinnerService: SpinnerService,
    private personalInformationService: PersonalInformationService,
    private cacheService: CacheService, private trainingService: TrainingService, private enrollmentService: EnrollmentService,
    private titleService: Title) {
      this.setTitle();
  }

  ngOnInit() {
    this.validateIfUserLoggedIn();
    this.isDemoAccount = (this.isDemoAccount !== null && this.isDemoAccount === true) ? true : false;
    this.demoCountry = this.demoCountry.length === 0 ?
    AccountConstants.DEMO_COUNTRY : this.demoCountry;
    this.localStorageService.set(CacheKeyConstants.BROWSER_NAME, this.getBrowserName());
  }

  /**
  * To Set the title for browser tabs
  */
  setTitle(): void {
    const envName = environment && environment.envName;
    let element = document.createElement('div');
    element.innerHTML = AppsConstants.TITLE + ((envName && envName !== AppsConstants.PROD_ENV) ? ' &raquo; ' + envName : '');
    const title = element.innerText;
    this.titleService.setTitle(title);
  }

  getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  /**
   * This will validate whether current route can be bypassed
   */
  canByPassThisRoute() {
    return (window.location.pathname.includes('/external-reports/incentives') ? true : false);
  }

  /**
   * Validate the user if user already logged in
   */
  validateIfUserLoggedIn(): void {
    const code: string = this.getParamValueFromUrl('code', window.location.href);
    if (window.location.pathname.includes('/login') && code) {
      this.ssoService.processCodeToken()
        .subscribe(res => {
          if (res) {
            this.ssoService.saveTokens(res);
            this.introspectRequest();
          }
        }, error1 => {
          this.ssoService.ssoRedirect(this.ssoService.getSSOLoginUrl());
        });
    } else if (window.location.pathname.includes('/enrollment') || window.location.pathname.includes('/verifyemail')
      || window.location.pathname.includes('/support-ticket')
      || this.canByPassThisRoute()
    ) { } else {
      // check if login Required else redirect to SSO
      if (this.ssoService.checkIfLoginRequired()) {
        this.ssoService.checkAndFetchUserSessionDetails()
          .subscribe(userSessionResponse => {
            if (userSessionResponse && userSessionResponse.accessToken) {
              this.storeTokensLocalStorage(userSessionResponse);
              this.introspectRequest();
            } else {
              // Remove data
              this.ssoService.ssoRedirect(this.ssoService.getSSOLoginUrl());
            }
          }, error1 => {
            this.ssoService.ssoRedirect(this.ssoService.getSSOLoginUrl());
          });
      } else if (window.location.href.includes('isImpersonation')) {
        CookieService.deleteCookie(SSO.NONCE);
        localStorage.removeItem('appflp360.state');
        window.location.href=this.ssoService.getSSOLoginUrl();
      } else {
        this.introspectRequest();
        // check and get token and introspect
      }
    }
  }

  public introspectRequest() {
    setTimeout(() => {
      this.spinnerService.start('userInfo');
    }, 0);
    this.ssoService.getConfigDataforUniqueClientAssertion().then((response) => {
      const accessToken = this.localStorageService.get(environment.accessToken);
      this.ssoService.processIntrospect(accessToken, response.apiConfiguration)
        .subscribe((responseJwt: any) => {
          if (responseJwt) {
            // Save tokens in account service
            this.setLoggedInUserData(responseJwt);
          }
        }, error => {
          // Refresh token from access token
          this.ssoService.processRefreshToAccessToken(response.apiConfiguration)
            .subscribe(refreshResponse => {
              if (refreshResponse) {
                this.ssoService.saveTokens(refreshResponse);
                this.introspectRequest();
              }
            }, refreshTokenError => {
              this.ssoService.logOutSettings();
              const redirectedUrl = this.ssoService.getSSOLogoutUrl(
                window.location.origin
              );
              this.ssoService.ssoRedirect(redirectedUrl);
            });
        });
    });
  }

  public setDeviceInformation(userDetails): void {
    this.trainingService.getIPAddress().subscribe(
      (res: any) => {
        let deviceInformationRequestModel: DeviceInformationRequestModel;
        if(environment?.enableAlertsAndActions){
           deviceInformationRequestModel = {
            browserType: this.localStorageService.get(CacheKeyConstants.BROWSER_NAME),
            sessionId: this.localStorageService.get(CacheKeyConstants.MEMBER_SESSION_ID),
            ipAddress: res.ip,
            deviceType: "WEB"
          };
        }else{
          deviceInformationRequestModel = {
            browserType: this.localStorageService.get(CacheKeyConstants.BROWSER_NAME),
            sessionId: this.localStorageService.get(CacheKeyConstants.MEMBER_SESSION_ID),
            ipAddress: res.ip
          };
        }
        this.trainingService.updateDeviceInformation(deviceInformationRequestModel, userDetails.distributorId)
          .subscribe(
            (response: any) => {
            }, (error) => {
            });
      });
  }

  public encodeUserData(data: string): string {
    let dataArray = [];
    for (var i = 0; i < data.length; i++) {
      dataArray[i] = ("00" + data.charCodeAt(i).toString(16).slice(-4));
    }
    return "\\u" + dataArray.join("\\u");
  }

  public setLoggedInUserData(data): void {
    const verifier = new IdTokenVerifier();
    const payload = verifier.decode(data).payload;
    if (this.isDemoAccount) {
      payload.userinfo.homeCountryCode = this.demoCountry;
    }
    this.localStorageService.set('session_state', payload.session_state);
    this.localStorageService.set(SSO.MEMBER_ID, payload.username);
    //FLP360-3689: Added cookie to made availble FLP360 distributor id to get it from Ecommerce Portal
    CookieService.setCookie(SSO.FLP360_MEMBER_ID,payload?.username,null, environment.cookiePath, environment.cookieDomain);
    if (payload.userinfo) {
      const userDetails = Object.assign({}, payload.userinfo);
      userDetails.distributorId = CacheKeyConstants.getEncodedData(userDetails.distributorId);
      userDetails.userName = CacheKeyConstants.getEncodedData(userDetails.userName);
      if (userDetails.phone) {
        userDetails.phone = this.encodeUserData(userDetails.phone);
      }
      if (userDetails.email) {
        userDetails.email = this.encodeUserData(userDetails.email);
      }
      if (userDetails.dateOfBirth) {
        userDetails.dateOfBirth = this.encodeUserData(userDetails.dateOfBirth);
      }
      if (this.isDemoAccount) {
        userDetails.email = CacheKeyConstants.getDecodedDataForString(userDetails.email);

        var arr = userDetails.email.split("@");
        userDetails.email = this.maskEmail(arr[0]) + "@Email.com";

        if (userDetails.firstName) {
          var first1 = userDetails.firstName.substring(0, 1);
          var mask = Array(userDetails.firstName.length).join("*")
            .concat(userDetails.firstName.slice(userDetails.firstName.length, userDetails.firstName.length));
          userDetails.firstName = first1 + mask;
        }
        if (userDetails.lastName) {
          var last1 = userDetails.lastName.substring(0, 1);
          var mask = Array(userDetails.lastName.length).join("*")
            .concat(userDetails.lastName.slice(userDetails.lastName.length, userDetails.lastName.length));
          userDetails.lastName = last1 + mask;
        }
      }
      this.localStorageService.set('userInfo', JSON.stringify(userDetails));
      this.setDeviceInformation(payload.userinfo);
      sessionStorage.setItem('fboID', userDetails.userName);
      this.personalInformationService.getFlp360UserDetails(payload.userinfo.userName)
        .subscribe(res => {
          if (this.isDemoAccount) {
            res.homeCountryCode = this.demoCountry
          }
          this.translateService.use(res.preferredLanguage);
          if (res && res.flp360StatusId) {
            this.cacheService.set(CacheKeyConstants.FLP360FBOID, res.flp360StatusId);
          }
          this.cacheService.set(CacheKeyConstants.selectedLanguage, res.preferredLanguage ? res.preferredLanguage : 'en-US');
          this.cacheService.set(CacheKeyConstants.ANNOUNCEMENT_VERSION, res.announcementVersion);
          this.enrollmentService.getFlp360Eligible(res.firstName
            , res.lastName, res.fboId).subscribe(tdmUserDetails => {
              setTimeout(() => {
                this.spinnerService.stop('userInfo');
              }, 0);
              this.localStorageService.set('isFlp360Eligible', tdmUserDetails.flp360Eligible);
              this.localStorageService.set('memberLevel',tdmUserDetails.fboLevel);
              if (this.isDemoAccount) {
                tdmUserDetails.homeCountry = this.demoCountry;
              }
              this.nonTitanUserData = tdmUserDetails;
              if (this.isDemoAccount) {
                payload.userinfo.homeCountryCode = this.demoCountry;
              }
              this.updateLevelDetails(payload, res);
              if (this.localStorageService.get(SSO.USER_INFO)) {
                this.messageService.sendIntrospectStatus(true);
              } else {
                this.messageService.sendIntrospectStatus(false);
              }
            }, error => {
              setTimeout(() => {
                this.spinnerService.stop('userInfo');
                if (this.localStorageService.get(SSO.USER_INFO)) {
                  this.messageService.sendIntrospectStatus(true);
                } else {
                  this.messageService.sendIntrospectStatus(false);
                }
              }, 0);
            });

        });
      if (this.isDemoAccount) {
        userDetails.homeCountryCode = this.demoCountry;
      }
      this.messageService.sendHomeCountrycode(userDetails.homeCountryCode);
      const homeCountry = payload.userinfo.memberAllowedCountries.filter(x => x.homeCountry != null && x.homeCountry === true)[0];
      if (homeCountry) {
        this.localStorageService.set(CacheKeyConstants.homeCountryCode, homeCountry.isoCodeThree);
        this.localStorageService.set(CacheKeyConstants.selectedLanguage, this.localStorageService.get(CacheKeyConstants.selectedLanguage) ?
          this.localStorageService.get(CacheKeyConstants.selectedLanguage) : 'en-US');

        this.personalInformationService.getStoreCountryInformation(homeCountry.isoCodeThree).
          subscribe(res => {
            this.localStorageService.set(CacheKeyConstants.storeConfiguration, JSON.stringify(res));
          })
      }
    }

    // save tokens to account service
    const isNewToken: string = this.localStorageService.get(SSO.IS_NEW_TOKEN);
    if (isNewToken && isNewToken === 'true') {
      const accessToken: string = this.localStorageService.get(environment.accessToken);
      const refreshToken: string = this.localStorageService.get(environment.refreshToken);
      const guid = CookieService.getCookieValue(SSO.MEMBER_SESSIONID);
      this.ssoService.saveUserAccessToken(this.ssoService.constructUserSession(accessToken, refreshToken, guid));
    }
  }

  maskEmail(str): string {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }
  updateLevelDetails(payload: any, res: any) {
    if (!payload.userinfo.memberTitleId && this.nonTitanUserData) {
      this.messageService.sendNonTitanUserTitle(this.nonTitanUserData.fboLevel);
      sessionStorage.setItem(CacheKeyConstants.NON_TITAN_USER_JOIN_DATE, this.nonTitanUserData.enrolmentDate);
      this.localStorageService.set(CacheKeyConstants.homeCountryCode, this.nonTitanUserData.defaultCountry);
      const changedProperties = [AppsConstants.FIRSTNAME, AppsConstants.LASTNAME, AppsConstants.EMAIL, AppsConstants.HOME_COUNTRY,
      AppsConstants.FBOLEVEL];
      for (let i = 0; i < changedProperties.length; i++) {
        switch (changedProperties[i]) {
          case AppsConstants.FIRSTNAME:
            if (res.firstName === null && this.nonTitanUserData.firstName !== null) {
              this.flp360UserModel.firstName = this.nonTitanUserData.firstName;
            }
            else if (this.nonTitanUserData.firstName != null && res.firstName.toLowerCase() !== this.nonTitanUserData.firstName.toLowerCase()) {
              this.flp360UserModel.firstName = this.nonTitanUserData.firstName;
            }
            break;
          case AppsConstants.LASTNAME:
            if (res.lastName === null && this.nonTitanUserData.lastName !== null) {
              this.flp360UserModel.lastName = this.nonTitanUserData.lastName;
            }
            else if (this.nonTitanUserData.lastName !== null && res.lastName.toLowerCase() !== this.nonTitanUserData.lastName.toLowerCase()) {
              this.flp360UserModel.lastName = this.nonTitanUserData.lastName;
            }
            break;
          case AppsConstants.EMAIL:
            if (res.email === null && this.nonTitanUserData.email !== null) {
              this.flp360UserModel.email = this.nonTitanUserData.email;
            }
            else if (this.nonTitanUserData.email !== null && res.email.toLowerCase() !== this.nonTitanUserData.email.toLowerCase()) {
              this.flp360UserModel.email = this.nonTitanUserData.email;
            }
            break;
          case AppsConstants.HOME_COUNTRY:
            if (res.homeCountryCode === null && this.nonTitanUserData.homeCountry !== null) {
              this.flp360UserModel.homeCountry = this.nonTitanUserData.homeCountry;
            }
            else if (this.nonTitanUserData.homeCountry !== null && res.homeCountryCode.toLowerCase() !== this.nonTitanUserData.homeCountry.toLowerCase()) {
              this.flp360UserModel.homeCountry = this.nonTitanUserData.homeCountry;
            }
            break;
          case AppsConstants.FBOLEVEL:
            if (res.fboLevel === null && this.nonTitanUserData.fboLevel !== null) {
              this.flp360UserModel.fboLevel = this.nonTitanUserData.fboLevel;
            } else if (this.nonTitanUserData.fboLevel !== null && res.fboLevel.toLowerCase() !== this.nonTitanUserData.fboLevel.toLowerCase()) {
              this.flp360UserModel.fboLevel = this.nonTitanUserData.fboLevel;
            }
            break;
        }
      }
      this.deleteEmptyValueAndUpdateData(this.flp360UserModel, res.fboId, res.email);

    } else {
      if (res.fboLevel == null && payload.userinfo.memberTitle !== null) {
        this.updateFboLevel(payload.userinfo.distributorId, payload.userinfo.memberTitle);
      } else if (payload.userinfo.memberTitle !== null && res.fboLevel.toLowerCase() !== payload.userinfo.memberTitle.toLowerCase()) {
        this.updateFboLevel(payload.userinfo.distributorId, payload.userinfo.memberTitle);
      }
    }
  }
  public storeTokensLocalStorage(userSession: UserSession): void {
    this.localStorageService.set(environment.accessToken, userSession.accessToken);
    this.localStorageService.set(environment.refreshToken, userSession.refreshToken);
    this.localStorageService.set(SSO.LOCAL_MEMBER_SESSION_ID, userSession.guid);
    this.localStorageService.set(SSO.IS_NEW_TOKEN, 'false');
  }
  deleteEmptyValueAndUpdateData(flp360UserData, distributorId, emailId) {
    for (const prop in flp360UserData) {
      if (flp360UserData[prop] === null || flp360UserData[prop] === undefined || flp360UserData[prop] === '') {
        delete flp360UserData[prop];
      }
    }
    if (flp360UserData !== null) {
      flp360UserData.fboId = distributorId;
      if (!flp360UserData.email) {
        flp360UserData.email = emailId;
      }
      this.personalInformationService.updateFlp360UserDetails(distributorId, flp360UserData).subscribe(data => {
      });
    }
  }
  // TODO have to keep in common
  getParamValueFromUrl(searchParam: string, urlString: string) {
    urlString=decodeURIComponent(encodeURIComponent(urlString));
    const match = RegExp('[?&]' + searchParam + '=([^&]*)').exec(urlString);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

  updateFboLevel(fboId: string, memberTitle: string) {
    this.personalInformationService.updateFboLevel(fboId, memberTitle).subscribe((data) => {
    }, error => { this.handleError(error); });
  }

  handleError(error) {
    console.log(error);
  }
}
