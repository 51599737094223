<header>
  <nav class="navbar navbar-expand-lg navbar-light">
    <!--  Use flexbox utility classes to change how the child elements are justified  -->
    <div class="navbar-collapse row header-tab d-flex" id="navbarToggle">
      <div class="first-column pr-0 order-2 order-sm-1 mr-auto mr-sm-0 col-2 col-sm-3 col-lg-2">
        <div [ngClass]="browserName !== 'ie' ? 'row' : ''">
          <div [ngClass]="browserName !== 'ie' ? 'col' : ''">
            <div [ngClass]="browserName !== 'ie' ? 'row' : ''">
              <div class=" col-12">
                <div class="enrolment-btn pt-2 ">
                  <button class="btn btn-primary text-uppercase btn-signup-content"
                    [ngClass]="browserName !== 'ie' ? 'col-12' : ''"
                    (click)="login()">{{'enrollment.Log in to FLP360 Account' | translate}}</button>
                  <button class="btn btn-primary text-uppercase signup-btn mt-2 btn-signup-content"
                    [ngClass]="browserName !== 'ie' ? 'col-12' : ''" (click)="navigateToEnrollmentForm()"
                    [class.d-none]="menuStatus==1">{{'enrollment.Sign up for FLP360' | translate}}</button>
                </div>
              </div>
            </div>
            <div [ngClass]="browserName !== 'ie' ? 'row' : ''">
              <div [ngClass]="browserName !== 'ie' ? 'col-12' : ''">
                <div class="header-breadcrumbs mt-2 route-navigation pr-0">
                  <div>
                    <a class="flp-title" (click)="navigatetoFLP360()">{{'enrollment.FLP360' | translate}}</a>
                    <span> > {{'supportTicket.Support Request' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-auto mx-auto mx-sm-0 justify-content-center justify-content-sm-left px-1 d-inline language-dropdown text-sm-left text-center order-3 order-sm-2 ml-sm-2 mb-sm-auto">
        <div class="form-group m-0">
          <label class="control-label" (click)="showLanguageList()"
            (appClickOutside)="isLanguageListVisible = false">{{selectedLanguageString}}
            <span class="fa" [ngClass]="{'fa-caret-up':isLanguageListVisible,'fa-caret-down':!isLanguageListVisible}">
            </span>
          </label>
          <div class="dropdown form-control"
            [ngClass]="{'d-block':isLanguageListVisible,'d-none':!isLanguageListVisible}">
            <ul class="list-unstyled" id="selected-option">
              <li class="pl-2" *ngFor="let lang of languageList" value="{{lang.cultureCode}}"
                (click)="changeLanguage(lang.cultureCode)" [class.active]="selectedLanguage === lang.cultureCode">
                {{ lang.language }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="px-1 second-column text-center justify-content-center col-12 col-sm-auto order-1 order-sm-3 mx-auto">
        <div class="mr-sm-5 pr-sm-5">
          <a class="navbar-brand text-center p-0 mr-0" href="#">
            <img src="{{cdnURL}}/{{imagePath}}/{{desktopLogo}}" height="60" alt="FLP360-Logo" />
          </a>
        </div>
      </div>
      <div
        class="col-4 col-sm-auto justify-content-end mx-0 px-0 text-center text-sm-right pt-2 pl-0 third-column order-4 order-sm-4">
        <div class="px-2">

          <a href="#">
            <img src="{{cdnURL}}/{{logoPath}}/{{foreverLivingLogo}}" height="20" alt="foreverliving" />
          </a>
          <div class="pt-2 pointer third-column-text text-center" (click)="navigateToCorporate()"
            [class.d-none]="menuStatus==1"><u>
              {{'enrollment.Take me to ForeverLiving.com' | translate}}</u></div>
        </div>
      </div>
    </div>
  </nav>
</header>