import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titleCase' })
export class TitleCasePipe implements PipeTransform {
    /**
   * Pipes which converts input in an application to have the first letter in uppercase and the remaining letters in lowercase
   * @param  {any} value
   * @returns any
   */
    public transform(input: string): string {
        if (!input) {
            return '';
        } else {
            return input.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));
        }
    }

}
