import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { ImageConstants } from '@core/constants/images.constant';
import { Member } from '@core/models/member';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../core/services/message-service.service';
import { TrainingTooltipService } from '../services/training-tooltip.service';


@Component({
  selector: 'app-header',
  templateUrl: '../templates/template1/views/app-header.component.html',
  styleUrls: [
    '../templates/template1/themes/app-header.component.less'
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  selectedLanguage: any;
  toggleStatus = false;
  @Output() toggleFromHeader = new EventEmitter();
  isCollapsed = true;
  baseUrl = environment.uiTemplateBaseUrl;
  desktopHeaderImage = ImageConstants.DESKTOP_HEADER_IMAGE;
  desktopHeaderLogo = ImageConstants.DESKTOP_HEADER_LOGO;
  mobileHeaderImage = ImageConstants.MOBILE_HEADER_IMAGE;
  mobileHeaderLogo = ImageConstants.MOBILE_HEADER_LOGO;
  showToggler: any;
  innerWidth: any;
  isRTL: boolean;
  operatingCountryId: number;
  userDetails: Member;
  distributorId: string;
  guestToken: string;
  userHomeCountryCode: Subscription;
  toggleStatusSubscription: Subscription;
  languageOrientationSubscription: Subscription;

  constructor(private messageService: MessageService, 
              private trainingTooltipService: TrainingTooltipService,
              private localStorageService: LocalStorageService) {
    this.toggleStatusSubscription = this.messageService.getToggleStatus().subscribe(message => {
      this.toggleStatus = message.text;
    });
    this.languageOrientationSubscription = this.messageService.getLanguageOrientation().subscribe(message => {
      this.isRTL = message.text;
    });
    this.userHomeCountryCode = this.messageService.getHomeCountryCode().subscribe(message => {
      this.userDetails = JSON.parse(this.localStorageService.get(CacheKeyConstants.userInfo));
      this.userDetails.userName = CacheKeyConstants.getDecodedDataForNumber(this.userDetails.userName);
      this.getTooltipStatus();
    });
  }

  ngOnInit() { 
  }
  ngOnDestroy() {
    if (this.userHomeCountryCode) {
    this.userHomeCountryCode.unsubscribe();
    }
    this.toggleStatusSubscription?.unsubscribe();
    this.languageOrientationSubscription?.unsubscribe();
  }
 
  changeToggleStatus() {
    this.toggleStatus = !this.toggleStatus;
    this.messageService.sendToggleStatus(this.toggleStatus);
  }
  getTooltipStatus() {
    if (!this.localStorageService.get(CacheKeyConstants.REPORT_TOOLTIP_STATUS)) {
    this.trainingTooltipService.getReportTooltipStatus(this.userDetails.userName).subscribe(
      response => {
        this.localStorageService.set(CacheKeyConstants.REPORT_TOOLTIP_STATUS, response.reportsTooltipStatus);
      }
    ), error => {

    }
  }
}
}
