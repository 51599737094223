import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUrlConstants } from '@core/constants/api.constants';
import { TrainingSettings } from '@core/models/training-settings.interface';
import { catchError } from 'rxjs/operators';
import { TrainingRequestModal } from '@core/models/training-request-modal.interface';
import { DeviceInformationRequestModel } from '@core/models/device-information-request-model';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class TrainingService {
    constructor(
        private httpClient: HttpClient
    ) { }

    /**
     * @description
     * @date 2020-04-15
     * @param {number} fboId
     * @param {*} trainingRequestModal
     * @returns {Observable<any>}
     * @memberof TrainingService
     */
    updateTrainingSettings(fboId: string, trainingRequestModal: TrainingRequestModal): Observable<TrainingSettings> {
        const url = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/training-preference';
        return this.httpClient.post<TrainingSettings>(url, trainingRequestModal).pipe(catchError(this.handleErrorObservable));
    }

    /**
     * @description
     * @date 2020-04-15
     * @param {number} fboId
     * @param {string} sessionId
     * @returns {Observable<TrainingSettings>}
     * @memberof TrainingService
     */
    getTrainingSettings(fboId: string, sessionId: string, slugName: string): Observable<TrainingSettings> {
        const url = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/session/' + sessionId + '/training-site/' + slugName;
        return this.httpClient.get<TrainingSettings>(url).pipe(catchError(this.handleErrorObservable));
    }

    /* To handle the obervable error response
    * @param  {Response|any} error
    */
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error.message || error);
    }
    /**
     * @param {DeviceInformationRequestModel} deviceInformationRequestModel
     * @param {string} fboId
     * @returns  {Observable<any>}
     * @memberof TrainingService
     */
    updateDeviceInformation(deviceInformationRequestModel: DeviceInformationRequestModel, fboId: string) {
        const url = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/' + fboId + '/tracking-info';
        return this.httpClient.post(url, deviceInformationRequestModel).pipe(catchError(this.handleErrorObservable));
    }
    /**
     * @returns {Observable<any>}
     * @memberof TrainingService
     */
    getIPAddress() {
        const url = 'https://api.ipify.org/?format=json';
        return this.httpClient.get(url).pipe(catchError(this.handleErrorObservable));
    }
    /**
 * @description API Call For the Volume From the TDM
 * @param {string} distributorId
 * @param {string} countryCode
 * @memberof HomeComponent
 */
  getMyVolumeMonthlyCC(distributorId: string, countryCode: string) {
    if(distributorId && countryCode){
        let monthlyCCFlag = 'myBusiness';
       
      const url = ApiUrlConstants.REPORTS_BASE + monthlyCCFlag +'/fboId/' + distributorId + '/country/' + countryCode + '/widget/monthlyCC';
      return this.httpClient.get(decodeURIComponent(encodeURIComponent(url)));
    }
  }
}
