import { environment } from '@env/environment';

export class AccountConstants {
    public static NOT_MATCHED = 'notMatched';
    public static TYPE_MATCH = 'typeMatch';
    public static PNG = 'png';
    public static JPEG = 'jpeg';
    public static JPG = 'jpg';
    public static PROTOCOL_SPLIT = 'protocol/';
    public static CONTENT_TYPE = 'application/json; charset=utf-8';
    public static CHARGEBEE_PORTALSESSION_API = environment.baseUrl + '/api/chargebee/portal-session/customer/';
    public static CHARGEBEE_CUSTOMER_API = environment.baseUrl + '/api/chargebee/distributor/';
    public static RESET_URL = 'login-actions/reset-credentials?client_id=FLP360-App';
    public static FAQ_URL = '/content/v1/portals/3/countries/';
    public static ADA_URL = '/content/v1/portals/3/countries/';
    public static DEMO_COUNTRY = 'LCA';

}
