<app-guided-tour *ngIf="showTourPopup" [selectedTour]="selectedTour" (openTrainingTour)="expandTrainingTools()"(closeTrainingTour)="closeTrainingTourPopup()" (closeGuidedTourPopup)="showTourPopup = false;">
</app-guided-tour>
<div class="container-fluid navbar-container" [ngClass]="{'direction-rtl': isRTL, 'direction-ltr': !isRTL}">
  <input type="checkbox" id="sidebartoggler">
  <div class="page-wrapper" [ngClass]="!isBackgroundImageShown ? 'bgcolor' : ''">
    <label class='toggle' for="sidebartoggler"><span id="sideNavToggleIcon"
        [ngClass]="{'fa-angle-left': !isRTL, 'fa-angle-right': isRTL}" class="fa"
        (click)=toggleFunction()></span></label>
    <div class="page-content slide-transition" [ngClass]="{'slide-component': toggleStatus, 'no-slide': !toggleStatus}"
      (click)="toggleSideNav()" [class.slide-component]="toggleStatus">
      <router-outlet>
        <app-spinner [spinnerName]="'userInfo'"></app-spinner>
        <app-background [backgroundColor] = "isBackgroundImageShown"></app-background>
      </router-outlet>
    </div>
    <div class="sidebar navbar-collapse" id="basicExampleNav"
      [ngClass]="{'slide-component': toggleStatus, 'no-slide': !toggleStatus}">
      <div class="sidebar-content">
        <div *ngIf="userDetails" class="px-2 welcome-msg">
          <div id='name'>{{userDetails?.firstName + ' ' +userDetails?.lastName |titlecase}}</div>
          <div id="title">{{userMemberTitle}}</div>
          <div id="distributorId">{{userDetails?.distributorId | distributerIdFormat | maskDistributorId}}</div>
        </div>
        <nav class="navigation" [class.d-none]="!showNavigation">
          <ul class="mainmenu" *ngIf="isNav">
            <li class="mainmenu-item" ><a (click)="goToDashboard()" (click)="toggleFunction()"
                [class.active]="menuActive=='/dashboard'"><img alt="" [imgSrc]="dashboardImage" [takeFromLocal]="true"
                  width="17" height="15" style="margin-right: 13px" />{{'sideNav.My Dashboard'|translate}}</a></li>
            <li class="mainmenu-item" id="reportsarrow" ><a (click)="openDashboard()"><img alt="" [imgSrc]="reportsImage"
                  [takeFromLocal]="true" width="20" height="20"
                  style="margin-right: 10px" />{{'sideNav.Reports'|translate}}<em class="float-right fa icon-20"
                  [ngClass]="{'fa-angle-up':openDashboardItems ,'fa-angle-down':!openDashboardItems}"></em></a>
              <ul class="submenu" [class.close-submenu]="!openDashboardItems" (click)="toggleFunction()">
                 <li ><a (click)="openReportsTourPopup('reportsTour')"
                    [class.active]="menuActive=='reportsTour'">{{'sideNav.Take the Reports Tour'|translate|translateKey}}</a>
                </li>
                <li><a id={{volume}} (click)="loadReport($event)"
                    [class.active]="menuActive=='volume'">{{'sideNav.Volume'|translate}}</a></li>
                <li><a id={{order}} (click)="loadReport($event)"
                    [class.active]="menuActive=='orders'">{{'sideNav.Orders'|translate}}</a></li>
                <li><a id={{earnings}} (click)="loadReport($event)"
                    [class.active]="menuActive=='earnings'">{{'sideNav.Earnings'|translate}}</a></li>
                <li><a id={{incentives}} (click)="loadReport($event)"
                    [class.active]="menuActive=='incentives'">{{'sideNav.Incentives'|translate}}</a></li>
                <li><a id={{focusGroup}} (click)="loadReport($event)"
                    [class.active]="menuActive=='focusGroup'">{{'sideNav.Focus Group'|translate|translateKey}}</a></li>
                <li><a id={{downline}} (click)="loadReport($event)"
                    [class.active]="menuActive=='downline'">{{'sideNav.Downline'|translate}}</a></li>
                
                <li><a id={{downlineManagers}} (click)="loadReport($event)"
                  [class.active]="menuActive=='downlineManagers'">{{'sideNav.Downline Managers'|translate}}</a></li>
                <li><a id={{retailCustomer}} (click)="loadReport($event)"
                    [class.active]="menuActive=='retail-customers'">{{'sideNav.Retail Customers'|translate}}</a></li>
<!-- Changes for added four core - FLP360-1781   -->
                                <li >
                                  <a id="contentHeader" (click)="openFourCoreContents()"><em class="float-right fa icon-20 font-14"
                                      [ngClass]="{'fa-angle-up':openFourCoreContentList,'fa-angle-down':!openFourCoreContentList}"></em>{{'sideNav.4Core'|translate}}</a>
                                  <ul class="submenu" [class.close-submenu]="!openFourCoreContentList">
                                      <li ><a id={{fourCoreSummary}} class="inner-iframe-indention" (click)="loadReport($event)"
                                        [class.active]="menuActive=='four-core-summary'">{{'sideNav.Summary'|translate}}</a></li>
                                        <li ><a id={{fourCoreRrecruitment}} class="inner-iframe-indention" (click)="loadReport($event)"
                                          [class.active]="menuActive=='recruitment'">{{'sideNav.Recruitment'|translate}}</a></li>
                                          <li ><a id={{fourCoreRetention}} class="inner-iframe-indention" (click)="loadReport($event)"
                                            [class.active]="menuActive=='retention'">{{'sideNav.Retention'|translate}}</a></li>
                                            <li ><a id={{fourCoreProductivity}} class="inner-iframe-indention" (click)="loadReport($event)"
                                              [class.active]="menuActive=='productivity'">{{'sideNav.Productivity'|translate}}</a></li>
                                      </ul>
                                    </li>
                                <li ><a id={{fourCoreLevelMoveUp}} (click)="loadReport($event)"
                                  [class.active]="menuActive=='four-core-level-move-up'">{{'sideNav.Level Move-Ups'|translate}}</a></li>
<!--   -->
                <li><a id={{advancedReports}} (click)="loadReport($event)"
                    [class.active]="menuActive=='advanced-reports'">{{'sideNav.Advanced Reports'|translate}}</a>
                </li>
              </ul>
            </li>

            <li class="mainmenu-item" >
              <a (click)="openTrainingTools()" id="trainingtoolsarrow">
                <img alt="" [imgSrc]="trainingToolsImage" [takeFromLocal]="true" width="18" height="25"
                style="margin-right: 12px; margin-top: -3px; transform: scaleX(-1)" />
               {{'sideNav.Training Tools'|translate | translateKey}}<em
                class="float-right fa icon-20 "
                [ngClass]="{'fa-angle-up':openTrainingToolsItems,'fa-angle-down':!openTrainingToolsItems}"></em></a>
                <ul class="submenu" [class.close-submenu]="!openTrainingToolsItems" >
                    <li ><a id={{businesstrainingUrl}}  (click)="iframeLoader($event)"
                      [class.active]="menuActive=='businesstraining'">{{'sideNav.Business Training'|translate| translateKey}}</a>
                  </li>
                  <li><a id={{producttrainingUrl}} (click)="iframeLoader($event)"
                      [class.active]="menuActive=='producttraining'">{{'sideNav.Product Training'|translate | translateKey}}</a>
                  </li>
                    </ul>
            </li>

            <li class="mainmenu-item" id="toolsarrow">
              <a (click)="openTools()"><img alt="" [imgSrc]="toolsImage" [takeFromLocal]="true" width="18" height="15"
                  style="margin-right: 12px; margin-top: -3px" />{{'sideNav.Tools'|translate}}<em
                  class="float-right fa icon-20"
                  [ngClass]="{'fa-angle-up':openToolsItems,'fa-angle-down':!openToolsItems}"></em></a>
              <ul class="submenu" [class.close-submenu]="!openToolsItems" (click)="toggleFunction()">
                <li ><a (click)="openToolsTourPopup('toolsTour')"
                    [class.active]="menuActive=='toolsTour'">{{'sideNav.Take the Tools Tour'|translate|translateKey}}</a>
                </li>
                <li>
                  <a id="shareablecontentarrow" (click)="openContents()"><em class="float-right fa icon-20 font-14"
                      [ngClass]="{'fa-angle-up':openContentItems,'fa-angle-down':!openContentItems}"></em>{{'sideNav.Shareable Content' | translate| translateKey}}</a>
                  <ul class="submenu" [class.close-submenu]="!openContentItems">
                    <li> <a id={{mediaLibraryUrl}} (click)="iframeLoader($event)" class="iframe-indention"
                      [class.active]="menuActive=='medialibrary'"> {{'sideNav.Resource Library'|translate| translateKey}}</a></li>
                    <li> <a id="{{contentStreamUrl}}" (click)="iframeLoader($event)" class="iframe-indention"
                        [class.active]="menuActive=='contentstream'">{{'sideNav.Social Stream' | translate | translateKey}} </a></li>
                    <li id="landingPages"> <a id={{landingPagesUrl}} (click)="iframeLoader($event)"
                        [class.active]="menuActive=='socialpages'"
                        class="iframe-indention">{{'sideNav.Landing Pages'|translate}}</a>
                      <!-- <ul class="list-submenu">
                        <li><a id={{accountWebsiteUrl}} class="inner-iframe-indention" (click)="iframeLoader($event)"
                            [class.active]="menuActive=='my-website-settings'">{{'sideNav.Landing Page Settings'|translate}}</a>
                        </li>
                      </ul> -->
                    </li>
                  </ul>
                </li>
                <li><a id={{linkBuilderUrl}} (click)="iframeLoader($event)"
                    [class.active]="menuActive=='linkbuilder'">{{'sideNav.Favorites'|translate| translateKey}}</a>
                </li>
                <li><a id={{socialQueueUrl}} (click)="iframeLoader($event)"
                  [class.active]="menuActive=='socialqueue'">{{'sideNav.Social Post Scheduler'|translate| translateKey}}</a>
              </li>
                <li id="contacts"><a id={{contactsUrl}} (click)="iframeLoader($event)"
                    [class.active]="menuActive=='contacts'">{{'sideNav.My Contacts'|translate| translateKey}}</a>
                </li>
                <!-- <li><a id={{callToActionUrl}} (click)="iframeLoader($event)"
                    [class.active]="menuActive=='call-to-action'">{{'sideNav.Call To Actions'|translate}}</a>
                </li> -->
                <li><a id={{activityFeedUrl}} (click)="iframeLoader($event)"
                  [class.active]="menuActive=='activityfeed'">{{'sideNav.Activity Feed'|translate}}</a>
              </li>
              </ul>
            </li>



            <li class="mainmenu-item" ><a (click)="goToApps()" (click)="toggleFunction()"
                [class.active]="menuActive=='/apps/FLP360'"><img alt="" [imgSrc]="appsImage" [takeFromLocal]="true"
                  width="15" height="18"
                  style="margin-right: 15px; margin-top: -3px" />{{'sideNav.FLP360 Apps'|translate}}</a></li>
            <li class="mainmenu-item"><a (click)="openMyAccount()"><img alt="" [imgSrc]="accountImage"
                  [takeFromLocal]="true" width="17" height="16"
                  style="margin-right: 13px; margin-top: -4px" />{{'sideNav.My Account'|translate}}<em
                  class="float-right fa icon-20"
                  [ngClass]="{'fa-angle-up':openMyAccountSettings,'fa-angle-down':!openMyAccountSettings}"></em></a>
              <ul class="submenu" [class.close-submenu]="!openMyAccountSettings" (click)="toggleFunction()">
                <li><a id={{subscriptionAndBilling}} (click)="loadSettings($event)"
                    [class.active]="menuActive=='subscription-and-billing'">{{'sideNav.Subscription & Billing'|translate}}</a>
                </li>
                <li><a id={{personalInformation}} (click)="loadSettings($event)"
                    [class.active]="menuActive=='personal-information'">{{'sideNav.Personal Information'|translate}}</a>
                </li>
                <li><a id={{locationsAndLanguages}} (click)="loadSettings($event)"
                    [class.active]="menuActive=='location-and-language'">{{'sideNav.Location & Language'|translate}}</a>
                </li>
                <li><a id={{contentChannelUrl}} (click)="iframeLoader($event)"
                    [class.active]="menuActive=='content-channel'">{{'sideNav.Content Channels'|translate}}</a>
                </li>
                <li><a id={{trackingSettingsUrl}} (click)="loadSettings($event)"
                    [class.active]="menuActive=='online-tracking-settings'">{{'sideNav.Online Tracking Settings'|translate}}
                  </a>
                </li>
              </ul>
            </li>
            <li class="mainmenu-item" id="helparrow"><a (click)="openHelp()"><img alt="" [imgSrc]="helpImage"
                  [takeFromLocal]="true" width="16" height="16"
                  style="margin-right: 14px; margin-top: -3px" />{{'sideNav.Help'|translate}}<em
                  class="float-right fa icon-20"
                  [ngClass]="{'fa-angle-up':openHelpMenu,'fa-angle-down':!openHelpMenu}"></em></a>
              <ul class="submenu" [class.close-submenu]="!openHelpMenu" (click)="toggleFunction()">
                <!-- <li><a id={{pdfGuides}} (click)="loadHelp($event)"
                    [class.active]="menuActive=='pdf-guides'">{{'sideNav.PDF Guides'|translate}}</a></li>
                <li><a id={{videoTutorials}} (click)="loadHelp($event)"
                    [class.active]="menuActive=='video-tutorials'">{{'sideNav.Video Tutorials'|translate}}</a></li> -->
                    <li><a id={{resources}} (click)="iframeLoader($event)"
                      [class.active]="menuActive=='resources'">{{'sideNav.Resources'|translate}}</a></li>
                    <li><a id={{faq}} (click)="loadHelp($event)"
                    [class.active]="menuActive=='faq'">{{'sideNav.FAQ'|translate}}</a></li>
                <li><a id={{contactUs}} (click)="loadHelp($event)" [class.active]="menuActive=='contactUs'">{{'sideNav.Contact
                  Us'|translate}}</a></li>
              </ul>
            </li>
            <li class="mainmenu-item"><a (click)="logout()"><img alt="" [imgSrc]="logoutImage" [takeFromLocal]="true"
                  width="16" height="16"
                  style="margin-right: 14px; margin-top:-3px" />{{'settings.Logout'| translate}}</a></li>
          </ul>
        </nav>
        <nav class="navigation" *ngIf="showNavigation == false" aria-label="logout">
          <ul class="mainmenu">
            <li class="mainmenu-item"><a (click)="logout()">{{'settings.Logout'| translate}}</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

