<div class="container-fluid flp360-header slide-transition" [ngClass]="{'direction-rtl': isRTL}"
  [class.slide-component]="toggleStatus">
  <div class="row">
    <div class="flp360 w-100 d-none d-md-block d-lg-block d-xl-block">
      <img  [imgSrc]=desktopHeaderImage [takeFromLocal]="true" width = "100%" height = "100%" alt="flp360-banner" class="flp360-banner responsive">
    </div>
    <div class="flp360 w-100 d-lg-none d-xl-none d-md-none d-sm-block d-xs-block">
      <img  [imgSrc]=mobileHeaderImage [takeFromLocal]="true" alt="flp360-banner" class="flp360-banner responsive" width = "100%" height = "100%">
    </div>
    <div class="flp360-collapse-bar" [ngClass]="{'direction-rtl': isRTL}">
      <button (click)="changeToggleStatus()"><span><i class="fa fa-bars" aria-hidden="true"></i></span></button>
    </div>
  </div>
</div>
