<main class="pb-150"  [ngClass] = "fromHelp ? 'main-help' :  ''">
    <div class="content-center welcome-page w-50 pt-3">
        <div class="mb-3 welcome-page-title">{{'supportTicket.Success!'|translate}}</div>
        <div class="text-center welcome-page-content">
            <div class="trail-msg">{{ "supportTicket.Your support request has been submitted"| translate}}</div>
        </div>
        <div class="text-center mt-5">
            <button class="btn btn-primary btn-color" (click) = "routeToSupportForm()" >{{'supportTicket.Submit Another Request' | translate | uppercase}}</button>
        </div>
    </div>
</main>