export class EnrollmentConstants {
    public static VALID = 'VALID';
    public static INVALID_CARD = 'Invalid card';
    public static PASSWORD = 'password';
    public static CONFIRM_PASSWORD = 'confirmPassword';
    public static CARD_FIELD = 'card-field';
    public static ERROR_CODE = 'Error Code: 404';
    public static DEFAULT_CULTURE_CODE = 'en-US';
    public static ON = 'on';
    public static YEAR = 'yearly';
    public static MONTHLY = 'monthly';
    public static PLAN_TYPE = 'planType';
    public static VALUE = 'value';
    public static ACTIVE = 'active';
    public static FLPI = 'FLPI';
    public static SOCIAL_SALES_GRANT_TYPE = 'client_credentials';
    public static PDF_LINK = '/portals/flp360/template1/pdf/Flp360TermsOfUse.pdf';
    public static TEMPORARY_FILES = 'temporaryFiles';
    public static LOCAL_PDF_LINK = 'assets/pdf';
    public static TERMINATED = 'TERMINATED';
    public static FIRSTNAME = 'firstName';
    public static LASTNAME = 'lastName';
    public static DISTRIBUTORID = 'distributorId';
    public static EMAILID = 'emailId';
    public static HIDE_MENU_STATUS = 'hideMenu';
    public static NOT_SAME = 'notSame';
    public static PAYMENT_SERVICE_KEY = '1|application/json';
    public static LIVE_CHAT_BOT_LINK = '/flpi/js/chat-bots.js';
    /*Spinner Constant*/
    public static SIGNUP_SPINNER = 'signUpSpinner';

    /*Routing for Enrollment Pages*/
    public static PERSONAL_INFO = 'personalinfo';
    public static REPORTS = 'reports';
    public static TOOLS = 'tools';
    public static BASE_URL = 'enrollment';
    public static PERSONAL_INFO_SUMMARY = 'summary';
    public static DEFAULT_EMAIL_VERIFIED_VALUE = false;
}
