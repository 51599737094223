export enum MemberLevel {
    Distributor = 'Distributor',
    GUEST = 'Guest',
    FPC = 'FPC',
    Novus = 'Novus',
    FBO = 'FBO',
    RETAIL = 'Retail',
    RETAILCUSTOMER = 'Retail Customer',
    Assistant_Supervisor = 'Assistant Supervisor',
    Supervisor = 'Supervisor',
    Assistant_Manager = 'Assistant Manager',
    Manager = 'Manager',
    Unrecognized_Manager = 'Unrecognized Manager',
    Recognized_Manager = 'Recognized Manager',
    Senior_Manager = 'Senior Manager',
    Soaring_Manager = 'Soaring Manager',
    Sapphire_Manager = 'Sapphire Manager',
    Diamond_Sapphire_Manager = 'Diamond Sapphire Manager',
    Diamond_Manager = 'Diamond Manager',
    Double_Diamond_Manager = 'Double Diamond Manager',
    Triple_Diamond_Manager = 'Triple Diamond Manager',
    Diamond_Centurion_Manager = 'Diamond Centurion Manager',
    PreferredCustomer = 'Preferred Customer',
}


export enum MemberType {
    FBO = 'FBO',
    RETAIL = 'RETAIL',
    GUEST = 'Guest',
    FPC = 'FPC',
    RETAILCUSTOMER = 'RETAIL CUSTOMER',
    Distributor = 'DISTRIBUTOR',
    PREFERREDCUSTOMER = 'Preferred Customer',
    PREFERRED_CUSTOMER = 'PREFERRED_CUSTOMER',
    PERSONAL = 'PERSONAL',
}